import { createRouter, createWebHistory } from "vue-router";

import Page from "@/components/TestTaking/Page.vue";

import Review from "@/components/TestTaking/Review.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/tests/:testId/pages/:pageId",
            name: "test.show.page",
            component: Page,
        },
        {
            path: "/tests/:testId",
            name: "test.show",
            component: Page,
        },
        {
            path: "/preview/tests/:testId/pages/:pageId",
            name: "test.preview.page",
            components: {
                default: Page,
            },
        },
        {
            path: "/preview/tests/:testId",
            name: "test.preview",
            component: Page,
        },
        {
            path: "/samples/resources/:resourceId",
            name: "sample-test.resource",
            component: Page,
        },
        {
            path: "/samples/resources/:resourceId/tests/:testId",
            name: "sample-test.test",
            component: Page,
        },
        {
            path: "/samples/resources/:resourceId/tests/:testId/pages/:pageId",
            name: "sample-test.page",
            component: Page,
        },

        {
            path: "/sample/:testId/page/:pageId",
            name: "test.sample.page",
            component: Page,
        },
        {
            path: "/sample/:testId",
            name: "test.sample",
            component: Page,
        },
        {
            path: "/submissions/:submissionId/pages/:pageId",
            name: "submission.page",
            component: Page,
        },
        {
            path: "/submissions/:submissionId",
            name: "submission",
            component: Page,
        },
        {
            path: "/tests/:testId/review",
            name: "test.review",
            component: Review,
        },
    ],
});

export default router;
