<script>
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";

export default {
    components: { QuestionBlock },
    mixins: [Question],

    data() {
        return {
            answer: {},
            selectRegex: new RegExp(/select-\d+/),
        };
    },

    props: ["type", "content", "options"],

    computed: {
        defaultAnswer() {
            return {};
        },

        answerState() {
            return (name, bypassValueCheck = false) => {
                if (!this._graded || bypassValueCheck) {
                    return "";
                }

                const submittedAnswer = this._submittedAnswerValue(this.clientId);
                const correctAnswer = this._correctAnswer(this.clientId);

                if (correctAnswer === null || correctAnswer === undefined) {
                    return "";
                }

                if (submittedAnswer && correctAnswer && correctAnswer[name] === submittedAnswer[name]) {
                    return "correct";
                }

                return "incorrect";
            };
        },

        buildContent() {
            return this.content
                .replaceAll(
                    new RegExp(
                        `<select name="([^">]+)" [^<>]+>\\s*(?:<option[^><]*>[^><]*</option>)*\\s*</select>`,
                        "g",
                    ),
                    "---$1---",
                )
                .split("---");
        },
    },

    methods: {
        selectAnswer(name) {
            return (event) => {
                const { value } = event.target;

                this.answer[name] = value;

                this.saveAnswer();
            };
        },

        getCorrectAnswer(name) {
            if (!this._graded) {
                return "";
            }

            const correctAnswer = this._correctAnswer(this.clientId);
            if (correctAnswer === null || correctAnswer === undefined) {
                return "";
            }
            return correctAnswer[name];
        },
    },

    created() {
        this.answer = { ...this.defaultAnswer };
    },
};
</script>

<template>
    <QuestionBlock
        :question-number="questionNumber"
        :block-id="clientId"
        @reset-answer="resetAnswer"
    >
        <p>
            <template
                v-for="segment in buildContent"
                :key="segment"
            >
                <template v-if="selectRegex.test(segment)">
                    <select
                        :name="segment"
                        class="max-w-full rounded border border-gray-600 px-3"
                        :class="answerState(segment)"
                        :value="answer[segment]"
                        @input="
                            (event) => {
                                selectAnswer(segment)(event);
                            }
                        "
                        :disabled="graded"
                    >
                        <option
                            value=""
                            selected
                            disabled
                        />

                        <option
                            v-for="option in options[segment]"
                            :key="option"
                        >
                            {{ option }}
                        </option>
                    </select>
                </template>

                <template v-else>
                    <span v-html="segment" />
                </template>
            </template>
        </p>

        <!-- This can probably be removed -->
        <slot
            :selected-value="selectAnswer"
            :label-click="labelClick"
            :get-correct-answer="getCorrectAnswer"
            :answer="answer"
            :graded="_graded"
            :answer-state="answerState"
        />
    </QuestionBlock>
</template>
