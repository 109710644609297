import _ from "lodash";

export default {
    addSavedAnswer(state, payload) {
        if (!state.submission) {
            return;
        }

        const index = _.findIndex(state.submission.answers, { id: payload.answer.id });

        if (index > -1) {
            this.state.submission.answers[index] = payload.answer;
        } else {
            state.submission.answers.push(payload.answer);
        }
    },

    deleteSavedAnswer(state, payload) {
        if (!state.submission) {
            return;
        }

        const newList = _.filter(state.submission.answers, (answer) => answer.id !== payload.answer.id);

        this.state.submission.answers = newList;
    },

    navigating(state, value) {
        this.state.navigating = value;
    },

    setAnswerScore(state, payload) {
        const { answerId, newScore } = payload;

        const index = _.findIndex(state.submission.answers, { id: answerId });

        this.state.submission.answers[index] = {
            ...state.submission.answers[index],
            score: newScore,
            teacher_feedback_provided: payload.teacher_feedback_provided,
        };
    },

    setCorrectAnswers(state, value) {
        this.state.correctAnswers = value;
    },

    setGraded(state, value) {
        this.state.sidebarOpen = value;
        this.state.graded = value;
    },

    setPreview(state, payload) {
        state.preview = payload;
    },

    setReseting(state, payload) {
        const { clientId, reseting } = payload;

        this.state.questionsReseting[clientId] = reseting;
    },

    setSample(state, payload) {
        state.sample = payload;
    },

    setSaving(state, payload) {
        const { clientId, saving } = payload;

        this.state.saving = saving;
        // app.set(state.questionsSaving, clientId, saving);
    },

    setSecret(state, payload) {
        state.secret = payload;
    },

    setUser(state, payload) {
        state.user = payload;
    },

    toggleSidebar(state, payload) {
        state.sidebarOpen = !state.sidebarOpen;
    },

    updatePageNumber(state, payload) {
        state.page.order += payload;
    },

    updatePage(state, payload) {
        this.state.page = payload;
    },

    updatePages(state, payload) {
        if (!state.preview && !state.graded && _.findIndex(payload, { order: "review" }) === -1) {
            payload.push({
                id: null,
                order: _.last(payload).order + 1,
                isReview: true,
                questions: [],
            });
        }

        this.state.pages = payload;
    },

    updateSubmission(state, payload) {
        this.state.submission = payload;
    },

    setResource(state, payload) {
        this.state.resource = payload;
    },
};
