<!-- Usage: <c-text v-model="fname" placeholder="Enter first name..." /> -->

<script>
export default {
    name: "CText",
    props: {
        id: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        placeholder: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        initialError: {
            type: String,
            default: "",
        },
        inputClass: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            error: "",
        };
    },
    computed: {},
    mounted() {
        this.error = this.initialError;
    },
    methods: {
        isValid(value) {
            this.error = "";
            if (this.required) {
                if (value.length <= 0) {
                    this.error = "Field is required";
                    return false;
                }
            }
            return true;
        },
        onInput(event) {
            this.isValid(event.target.value);
            this.$emit("update:modelValue", event.target.value);
        },
        onChange(event) {
            // Supports .lazy
            this.isValid(event.target.value);
            this.$emit("change", event.target.value);
        },
        onBlur(event) {
            this.isValid(event.target.value);
            this.$emit("blur", event.target.value);
        },
    },
};
</script>

<template>
    <label>
        {{ label }}
        <input
            :id="id"
            class="w-full rounded border px-3 py-2 leading-tight focus:shadow focus:outline-none focus:ring"
            :class="error ? inputClass + ' border-red-700' : inputClass + ' border-gray-300'"
            :type="type"
            :value="modelValue"
            :placeholder="placeholder"
            @input="onInput"
            @change="onChange"
            @blur="onBlur"
            :required="required"
        />
        <!--        <span class="text-xs text-red-700 h-4" role="alert">{{ error }}</span>-->
    </label>
</template>

<style scoped></style>
