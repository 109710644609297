<script lang="ts" setup>
import { inject, ref } from "vue";
import { computed } from "~/vue";
import { useStore } from "~/vuex";

const props = defineProps({
    index: {
        type: Number,
        required: true,
    },
    style: {
        type: Object,
        default: () => ({}),
    },
});

const store = useStore();
const graded = computed(() => store.getters.graded);

const { answerState } = inject("answer");
const getCorrectAnswer = inject("getCorrectAnswer");

// When user click next/prev page, it failed to get the wrapper. It works when you do refresh page.
// So to fix this issue, wait a second before getting the correct answer.
const correctAnswer = ref("");
setTimeout(() => {
    correctAnswer.value = getCorrectAnswer(`${+props.index + 1}`);
}, 2000);
</script>

<template>
    <div
        class="hotspot-target"
        :class="answerState(`${+index + 1}`)"
        :style="{
            left: `${style.left}px`,
            top: `${style.top}px`,
            height: `${style.height}px`,
            width: `${style.width}px`,
            minWidth: `${style.width}px`,
            minHeight: `${style.height - 10}px`,
        }"
        :data-index="+index + 1"
    >
        <slot />

        <div
            class="absolute whitespace-nowrap"
            style="bottom: -40px"
        >
            <div
                v-if="graded && answerState(`${+index + 1}`) === 'incorrect'"
                class="correct-target incorrect should-be-selected"
            >
                {{ correctAnswer }}
            </div>
        </div>
    </div>
</template>
