<script lang="ts" setup>
import { computed } from "vue";
import type { PropType } from "~/vue";

const props = defineProps({
    mediaAlt: {
        type: String,
        default: "",
    },

    mediaId: {
        type: [String, Number],
        default: "",
    },

    mediaSizeSlug: {
        type: String,
        default: "",
    },

    mediaUrl: {
        type: String,
        default: "",
    },
});

const imageClasses = computed(() => ({
    [`wp-image-${props.mediaId}`]: props.mediaId,
    [`size-${props.mediaSizeSlug}`]: props.mediaSizeSlug,
}));
</script>

<template>
    <img
        :src="mediaUrl"
        :alt="mediaAlt"
        :class="imageClasses"
    />
</template>
