<script lang="ts" setup>
import { onMounted, ref } from "vue";

const props = defineProps({
    tabs: {
        type: Object,
        required: true,
    },
    initialActiveTab: {
        type: String,
        default: "",
    },
});

const emit = defineEmits(["change-tab"]);

const activeTab = ref("");

function clickTab(val: string) {
    activeTab.value = val;

    emit("change-tab", val);
}

onMounted(() => {
    if (props.initialActiveTab) {
        activeTab.value = props.initialActiveTab;
    } else if (window.location.hash) {
        activeTab.value = window.location.hash.substr(1);
    } else {
        activeTab.value = Object.keys(props.tabs)[0];
    }
});
</script>

<template>
    <div class="pb-8">
        <ul class="list-none overflow-auto">
            <li
                v-for="(val, key) in tabs"
                :key="`tab-${key}`"
                @click="clickTab(key)"
                class="float-left cursor-pointer border-b-8 px-4 text-xl hover:border-primary hover:text-primary"
                :class="
                    activeTab === key ? 'border-primary-lighter text-primary-lighter' : 'border-gray-300 text-gray-500'
                "
            >
                {{ val }}
            </li>
        </ul>

        <hr class="-mt-2 h-2 w-full bg-gray-300" />

        <div class="pt-4">
            <div
                v-for="(val, key) in tabs"
                :key="`content-${key}`"
                :class="activeTab === key ? '' : 'hidden'"
            >
                <slot
                    :name="key"
                    :active-tab="activeTab"
                ></slot>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
