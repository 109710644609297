<script setup>
import useButtonType from "@/composables/useButtonType";

const { buttonType } = useButtonType();
</script>

<template>
    <Component
        :is="buttonType"
        class="inline-flex items-center justify-center gap-x-2 rounded-md border-2 border-red-700 bg-red-700 px-4 py-2 text-center text-base font-bold text-white hover:border-red-900 hover:bg-red-900 focus:outline-none disabled:border-gray-500 disabled:bg-gray-500"
    >
        <span
            class="flex h-4 w-4 fill-white"
            v-if="$slots.icon"
        >
            <slot name="icon" />
        </span>

        <span v-if="$slots.default">
            <slot />
        </span>
    </Component>
</template>
