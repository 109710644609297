<!--Usage: <c-select placeholder="Select..." :options="topics" v-model="topic" /> -->

<script>
import debounce from "lodash/debounce";
import { FontAwesomeIcon } from "~/@fortawesome/vue-fontawesome";

export default {
    name: "CSelect",
    components: { FontAwesomeIcon },
    emits: ["update:modelValue", "change", "input"],
    props: {
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        options: {
            type: [Object, Array], // as PropType<unknown | unknown[]>, //For after we switch to TS support
            required: true,
        },
        modelValue: {
            type: [String, Array], // as PropType<string | string[] | unknown[]>, //For after we switch to TS support
            required: false,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
        openDown: {
            type: Boolean,
            default: true,
        },
        dropDownHeightClass: {
            type: String,
            default: "max-h-40 md:max-h-72",
        },
        enableTypeAhead: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            open: false,
            multipleSelect: false,
            error: "",
            dropDownOptions: {
                type: [Array, Object], // as PropType<unknown | unknown[]>, // For after we switch to TS support
                required: true,
            },
            typeAhead: "",
        };
    },
    mounted() {
        if (this.modelValue !== null && (Array.isArray(this.modelValue) || typeof this.modelValue === "object")) {
            this.multipleSelect = true;
        }
        this.dropDownOptions = this.options;
    },
    methods: {
        getSaveValue(object, value) {
            // Array: The value is being saved
            // Object: The key is being saved
            if (typeof this.options === "object") {
                return Object.keys(object).find((key) => object[key] === value);
            }

            return value;
        },
        doSelect(event, key) {
            // Auto close if not multiple select
            if (!this.multipleSelect) {
                this.open = false;
            }

            if (this.multipleSelect) {
                if (key) {
                    if (this.modelValue && this.modelValue.includes(key)) {
                        this.modelValue.splice(this.modelValue.indexOf(key), 1);
                    } else {
                        this.modelValue.push(key);
                        // this.$emit('input', [...key]);
                    }

                    event.target.classList.toggle("bg-blue-400");
                    event.target.classList.toggle("text-white");
                } else {
                    this.modelValue.splice(0, this.modelValue.length);
                }
            } else {
                this.$emit("update:modelValue", key);
            }

            this.$emit("change");
        },

        validateInput() {
            this.error = "";
            if (this.required && !this.modelValue) {
                this.error = "Field is required";
            }
        },

        debounceFilterOptions: debounce(function (ele) {
            // Convert object to a key/value array
            const asArray = Object.entries(this.options);
            // Check if it matches value or key
            const filtered = asArray.filter(([key, value]) => {
                let found = value.toLowerCase().match(ele.value.toLowerCase()) != null;
                if (!found) {
                    found = key.toLowerCase().match(ele.value.toLowerCase()) != null;
                }
                return found;
            });
            // Convert array to object
            this.dropDownOptions = Object.fromEntries(filtered);
        }, 500),

        toggleOpen() {
            if (this.enableTypeAhead) {
                if (!this.open) {
                    this.open = true;
                }
            } else {
                this.open = !this.open;
            }
        },

        handleBlur(e) {
            const { currentTarget } = e;
            // Give browser time to focus the next element
            requestAnimationFrame(() => {
                // Check if the new focused element is a child of the original container
                if (!currentTarget.contains(document.activeElement)) {
                    this.open = false;
                }
            });
        },
    },
    computed: {
        selected() {
            let val = "";
            if (this.modelValue) {
                // Multiple select
                if (this.multipleSelect) {
                    if (this.modelValue.length > 0) {
                        val = this.options[this.modelValue[0]]; // Show the first value
                    } else {
                        val = "";
                    }
                } // If options is type object, then get the value of that key
                else {
                    val = this.modelValue;
                    if (typeof this.options === "object" && !Array.isArray(this.options)) {
                        val = this.options[this.modelValue];
                    }
                }
            }

            // Update typeAhead value
            if (this.enableTypeAhead) {
                this.typeAhead = val;
            }

            return val;
        },
    },
};
</script>

<template>
    <div
        :tabindex="tabindex"
        @blur="
            handleBlur($event);
            validateInput();
            dropDownOptions = options;
        "
        class="relative w-full text-left focus-within:opacity-100"
    >
        <label v-show="label">{{ label }}</label>
        <!-- Add a hidden input to simulate html form validation -->
        <input
            v-if="required"
            type="text"
            :value="value"
            class="absolute left-0 -z-10 h-10 w-full bg-red-400"
            :class="label ? 'top-6' : 'top-0'"
            required
        />

        <div
            @click="toggleOpen"
            class="w-full cursor-pointer select-none rounded border border-gray-300 px-3 py-2 leading-tight leading-tight"
            :class="
                open
                    ? 'rounded-b-none border-b-0 border-gray-400 shadow outline-none ring'
                    : error
                    ? 'border-red-700'
                    : ''
            "
        >
            <FontAwesomeIcon
                icon="angle-down"
                class="float-right cursor-pointer"
            />

            <input
                v-if="enableTypeAhead && open"
                type="text"
                v-model="typeAhead"
                @click="$event.target.select()"
                @input="debounceFilterOptions($event.target)"
                class="inline w-[calc(100%-15px)] border-red-800 ring-0 focus:outline-none focus:ring-0"
            />

            <span
                v-else-if="selected"
                v-html="selected"
            ></span>

            <span v-else-if="placeholder">{{ placeholder }}</span>

            <span v-else>&nbsp;</span>
        </div>

        <div
            v-show="open"
            class="absolute left-0 right-0 z-10 mt-1 overflow-auto bg-gray-200 ring"
            :class="dropDownHeightClass + ' ' + (!openDown ? 'bottom-10 rounded-t' : 'rounded-b')"
        >
            <div
                v-show="placeholder"
                class="cursor-pointer select-none px-3 py-2 font-light italic leading-tight text-gray-400 hover:bg-primary-lighter hover:text-white"
                @click="doSelect('')"
            >
                {{ placeholder }}
            </div>

            <div
                v-for="(option, key) of dropDownOptions"
                :key="key"
                @click="doSelect($event, key)"
                class="cursor-pointer select-none px-3 py-2 font-light leading-tight hover:bg-primary-lighter hover:text-white"
                :class="
                    multipleSelect && modelValue && modelValue.includes(getSaveValue(dropDownOptions, option))
                        ? 'bg-blue-400 text-white'
                        : ''
                "
                v-html="option"
            ></div>
        </div>
    </div>
</template>

<style scoped></style>
