<script lang="ts" setup>
import DropTarget from "@/components/TestTaking/Blocks/Answers/DropTarget.vue";
import HotspotBlock from "@/components/TestTaking/Blocks/Answers/HotspotBlock.vue";
import MultipleSelectAnswer from "@/components/TestTaking/Blocks/Answers/MultipleSelectAnswer.vue";
import ShortEssay from "@/components/TestTaking/Blocks/Answers/ShortEssay.vue";
import hasBackgroundColor from "@/components/TestTaking/Blocks/Composables/hasBackgroundColor";
import ColumnBlock from "@/components/TestTaking/Blocks/Core/ColumnBlock.vue";
import ColumnsBlock from "@/components/TestTaking/Blocks/Core/ColumnsBlock.vue";
import CoverBlock from "@/components/TestTaking/Blocks/Core/CoverBlock.vue";
import EmbedBlock from "@/components/TestTaking/Blocks/Core/EmbedBlock.vue";
import GalleryBlock from "@/components/TestTaking/Blocks/Core/GalleryBlock.vue";
import GroupBlock from "@/components/TestTaking/Blocks/Core/GroupBlock.vue";
import ImageAlignedBlock from "@/components/TestTaking/Blocks/Core/ImageAlignedBlock.vue";
import ImageBlock from "@/components/TestTaking/Blocks/Core/ImageBlock.vue";
import ListBlock from "@/components/TestTaking/Blocks/Core/ListBlock.vue";
import MediaText from "@/components/TestTaking/Blocks/Core/MediaText.vue";
import ParagraphBlock from "@/components/TestTaking/Blocks/Core/ParagraphBlock.vue";
import PullquoteBlock from "@/components/TestTaking/Blocks/Core/PullquoteBlock.vue";
import QuoteBlock from "@/components/TestTaking/Blocks/Core/QuoteBlock.vue";
import TableBlock from "@/components/TestTaking/Blocks/Core/TableBlock.vue";
import VerseBlock from "@/components/TestTaking/Blocks/Core/VerseBlock.vue";
import ComingSoon from "@/components/TestTaking/Blocks/Custom/ComingSoon.vue";
import ContentHighlight from "@/components/TestTaking/Blocks/Design/ContentHighlight.vue";
import ScrollingContainer from "@/components/TestTaking/Blocks/Design/ScrollingContainer.vue";
import SeparatorBlock from "@/components/TestTaking/Blocks/Design/SeparatorBlock.vue";
import DragTarget from "@/components/TestTaking/Blocks/Partials/DragTarget.vue";
import HotspotTarget from "@/components/TestTaking/Blocks/Partials/HotspotTarget.vue";
import QuestionBody from "@/components/TestTaking/Blocks/Partials/QuestionBody.vue";
import TableGridCell from "@/components/TestTaking/Blocks/Partials/TableGridCell.vue";
import BarGraph from "@/components/TestTaking/Blocks/Questions/BarGraph.vue";
import DragAndDropHotspot from "@/components/TestTaking/Blocks/Questions/DragAndDropHotspot.vue";
import DragAndDropTable from "@/components/TestTaking/Blocks/Questions/DragAndDropTable.vue";
import EquationAnswer from "@/components/TestTaking/Blocks/Questions/EquationAnswer.vue";
import FillInTheBlank from "@/components/TestTaking/Blocks/Questions/FillInTheBlank.vue";
import HotText from "@/components/TestTaking/Blocks/Questions/HotText.vue";
import MultipleChoice from "@/components/TestTaking/Blocks/Questions/MultipleChoice.vue";
import NumberLine from "@/components/TestTaking/Blocks/Questions/NumberLine.vue";
import PopupBlank from "@/components/TestTaking/Blocks/Questions/PopupBlank.vue";
import ShortAnswer from "@/components/TestTaking/Blocks/Questions/ShortAnswer.vue";
import TableGrid from "@/components/TestTaking/Blocks/Questions/TableGrid.vue";
import EquationBlock from "@/components/TestTaking/Blocks/Text/EquationBlock.vue";
import PoemBlock from "@/components/TestTaking/Blocks/Text/PoemBlock.vue";
import { computed, defineComponent } from "vue";

defineComponent({
    name: "ContentRender",
});

const props = defineProps({
    block: {
        type: Object,
        required: true,
    },

    questionComponent: {
        type: String,
        default: null,
    },
});

const emit = defineEmits(["changeSaving"]);

const blockComponent = computed(() => {
    switch (props.block.name) {
        case "answers/drop-target":
            return DropTarget;
        case "answers/hotspot":
            return HotspotBlock;
        case "answers/short-essay":
            return ShortEssay;
        case "answers/multiple-select-answer":
            return MultipleSelectAnswer;
        case "core/column":
            return ColumnBlock;
        case "core/columns":
            return ColumnsBlock;
        case "core/cover":
            return CoverBlock;
        case "core/embed":
            return EmbedBlock;
        case "core/gallery":
            return GalleryBlock;
        case "core/group":
            return GroupBlock;
        case "core/image":
            if (["left", "right", "center"].includes(props.block.attributes.align)) {
                return ImageAlignedBlock;
            }

            return ImageBlock;
        case "core/list":
            return ListBlock;
        case "core/media-text":
            return MediaText;
        case "core/paragraph":
            return ParagraphBlock;
        case "core/pullquote":
            return PullquoteBlock;
        case "core/quote":
            return QuoteBlock;
        case "core/separator":
            return SeparatorBlock;
        case "core/table":
            return TableBlock;
        case "core/verse":
            return VerseBlock;
        case "design/content-highlight":
            return ContentHighlight;
        case "design/scrolling-container":
            return ScrollingContainer;
        case "partials/drag-target":
            return DragTarget;
        case "partials/hotspot-target":
            return HotspotTarget;
        case "partials/question-body":
            return QuestionBody;
        case "questions/bar-graph":
            return BarGraph;
        case "questions/drag-and-drop-hotspot":
            return DragAndDropHotspot;
        case "questions/drag-and-drop-table":
            return DragAndDropTable;
        case "questions/fill-in-the-blank":
            return FillInTheBlank;
        case "questions/equation-answer":
            return EquationAnswer;
        case "questions/hot-text":
            return HotText;
        case "questions/multiple-choice":
            return MultipleChoice;
        case "questions/number-line":
            return NumberLine;
        case "questions/popup-blank":
            return PopupBlank;
        case "questions/short-answer":
            return ShortAnswer;
        case "questions/table-grid":
            return TableGrid;
        case "table-grid/cell":
            return TableGridCell;
        case "text/equation-editor":
            return EquationBlock;
        case "text/poem":
            return PoemBlock;
        // Disable the spacer block
        case "core/spacer":
            return null;
        default:
            return ComingSoon;
    }
});

const changeSaving = (value: any) => {
    emit("changeSaving", value);
};

const { hasBackground, backgroundStyle, backgroundClass } = hasBackgroundColor(props.block.attributes);
</script>

<template>
    <Component
        :is="blockComponent"
        :name="block.name"
        v-bind="block.attributes"
        :class="{
            [backgroundClass]: backgroundClass,
            'mb-100p': block.name.includes('questions/'),
        }"
        :has-background="hasBackground"
        :style="backgroundStyle"
    >
        <ContentRender
            v-for="innerBlock in block.innerBlocks"
            :key="`cr-${innerBlock.clientId}`"
            :block="innerBlock"
        />
    </Component>
</template>

<style scoped>
:deep(math-field) {
    @apply bg-transparent;
}

:deep(.equation-answer-math math-field) {
    @apply bg-white;
}
</style>
