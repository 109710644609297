export default function (element, location, options) {
    let scope_Base = element.querySelector(".noUi-base");
    options = {
        ...options,
        ort: 0,
        dir: 0,
    };

    function baseSize() {
        var rect = scope_Base.getBoundingClientRect();
        var alt = "offset" + ["Width", "Height"][options.ort];
        return options.ort === 0 ? rect.width || scope_Base[alt] : rect.height || scope_Base[alt];
    }

    function calcPointToPercentage(calcPoint) {
        const location = calcPoint - offset(scope_Base, options.ort);
        let proposal = (location / baseSize()) * 100;

        // Clamp proposal between 0% and 100%
        // Out-of-bound coordinates may occur when .noUi-base pseudo-elements
        // are used (e.g. contained handles feature)
        proposal = limit(proposal);

        return Math.round(options.dir ? 100 - proposal : proposal);
    }

    function getPageOffset(doc) {
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = (doc.compatMode || "") === "CSS1Compat";
        var x = supportPageOffset
            ? window.pageXOffset
            : isCSS1Compat
              ? doc.documentElement.scrollLeft
              : doc.body.scrollLeft;
        var y = supportPageOffset
            ? window.pageYOffset
            : isCSS1Compat
              ? doc.documentElement.scrollTop
              : doc.body.scrollTop;

        return {
            x: x,
            y: y,
        };
    }

    function limit(a) {
        return Math.max(Math.min(a, 100), 0);
    }

    function offset(elem, orientation) {
        var rect = elem.getBoundingClientRect();
        var doc = elem.ownerDocument;
        var docElem = doc.documentElement;
        var pageOffset = getPageOffset(doc);

        // getBoundingClientRect contains left scroll in Chrome on Android.
        // I haven't found a feature detection that proves this. Worst case
        // scenario on mis-match: the 'tap' feature on horizontal sliders breaks.
        if (/webkit.*Chrome.*Mobile/i.test(navigator.userAgent)) {
            pageOffset.x = 0;
        }

        return orientation
            ? rect.top + pageOffset.y - docElem.clientTop
            : rect.left + pageOffset.x - docElem.clientLeft;
    }

    return calcPointToPercentage(location);
}
