import axios from "axios";
import router from "@/router";

export default {
    changePage({ commit, dispatch, getters }, payload) {
        commit("navigating", true);
        const params = { test: getters.page.test_id, page: getters.page.order + payload };
        let routeName = "test.page.";

        if (getters.isPreview) {
            routeName += "preview";
        } else if (getters.graded) {
            routeName += "submission";
        } else {
            routeName += "show";
        }

        return axios
            .get(route(routeName, params))
            .then(({ data }) => {
                // Vue.nextTick(() => {
                //     setTimeout(() => {
                //         window.initializeTinymce();
                //     }, 100)
                // });

                commit("updatePage", data);

                dispatch("updateRoute", data);

                return data;
            })
            .catch((errors) => {
                console.error(errors);
            })
            .finally(() => {
                commit("navigating", false);
            });
    },

    changeScore({ getters, commit }, payload) {
        const { questionId, answerId, newScore } = payload;
        const { submission } = getters;

        const routeParams = { submission: getters.submission.id };
        if (answerId) {
            routeParams.answer = answerId;
        }

        const url = route("grade.submission.answer", routeParams);
        const params = {
            newScore,
            questionId,
        };

        return axios
            .patch(url, params)
            .then(({ data }) => {
                commit("setAnswerScore", { ...payload, ...data });
            })
            .catch((errors) => {
                console.error(errors);
            });
    },

    createSubmission({ getters, commit }) {
        if (!getters.isPreview) {
            return axios
                .get(route("test.submission", { test: getters.testId }))
                .then(({ data }) => {
                    commit("updateSubmission", data);

                    return data;
                })
                .catch(({ response }) => {
                    console.error("Failed to get or create submission due to invalid code or test!");
                    if (response?.status === 400 && response?.data.message) {
                        alert(response?.data.message);
                        window.location.href = route("student-logout");
                    }

                    return { error: response?.data.message };
                });
        }
    },

    getCorrectAnswers({ commit, getters, state }) {
        axios
            .get(route("test.correct-answers", { test: getters.testId, secret: state.secret }))
            .then(({ data }) => {
                commit("setCorrectAnswers", data);
            })
            .catch((errors) => {
                console.error(errors);
            });
    },

    goToReview({ commit, getters }) {
        commit("navigating", true);
        NProgress.start();

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 100);
        })
            .then(() => {
                commit("updatePageNumber", getters.numberOfPages - getters.pageNumber + 1);
                router.push({ name: "test.review", params: router.currentRoute.params }).catch(() => {});
            })
            .finally(() => {
                NProgress.done();
                commit("navigating", false);
            });
    },

    navigateToPage({ dispatch, getters }, payload) {
        if (payload !== getters.pageNumber) {
            const page = _.find(getters.pages, { order: payload });

            if (page && _.get(page, "isReview", false)) {
                return dispatch("goToReview");
            }

            return dispatch("changePage", payload - getters.pageNumber);
        }
        return new Promise((resolve) => {
            resolve();
        });
    },

    resetAnswer({ getters, commit }, payload) {
        const { clientId } = payload;
        const answer = getters.submittedAnswer(clientId);

        commit("setReseting", { clientId, reseting: true });

        if (getters.isPreview || answer === undefined) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    commit("setReseting", { clientId, reseting: false });
                    resolve(payload);
                }, 1000);
            });
        }
        const url = route("submission.answer.reset", { submission: getters.submission.id, answer: answer.id });

        return axios
            .delete(url, payload)
            .then((response) => {
                const { data } = response;

                commit("deleteSavedAnswer", { answer });
                commit("setReseting", { clientId, reseting: false });

                return response;
            })
            .catch((errors) => {
                // If it returns a 422 status, it means the test has been submitted.
                if (errors.status === 422) {
                    alert(
                        "Reset answer failed. Please make sure you have not submitted the test. For more information, please contact your instructor.",
                    );
                    window.location.reload(); // Force refresh page to redirect
                    return null;
                }

                return errors;
            });
    },

    saveAnswer({ getters, commit }, payload) {
        const { clientId } = payload;

        if (getters.isPreview) {
            commit("setSaving", { clientId, saving: true });

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    commit("setSaving", { clientId, saving: false });
                    resolve(payload);
                }, 1000);
            });
        }

        if (getters.testSubmitted) {
            alert("Sorry, you can no longer make changes to your answers.");
            window.location.reload(); // Force refresh page to redirect
            return null;
        }
        // Submission is created when student started the test.
        // If for some reason it's not created, we can't save the answer.
        if (getters.submission === null || !getters.submission.id) {
            alert("Failed to save answer. Please refresh the page and try again.");
            return null;
        }

        commit("setSaving", { clientId, saving: true });
        const url = route("submission.answer", { submission: getters.submission.id });

        return axios
            .post(url, payload)
            .then((response) => {
                const { data } = response;

                // Check for server error
                if (data.error && data.message) {
                    alert(`Failed to save answer. ${data.message}`);
                    location.reload(); // Force refresh page to redirect
                    return response;
                }

                commit("addSavedAnswer", { answer: data });
                commit("setSaving", { clientId, saving: false });

                return response;
            })
            .catch((errors) => {
                // If it returns a 422 status, it means the test has been submitted.
                if (errors.status === 422) {
                    alert(
                        "Save answer failed. Please make sure you have not submitted the test. For more information, please contact your instructor.",
                    );
                    window.location.reload(); // Force refresh page to redirect
                    return null;
                }

                return errors;
            });
    },

    updateLastCompletedPage({ state }, payload) {
        state.lastCompletedPage = payload;
    },

    updateRoute({ getters, commit }, payload) {
        let url = `/tests/${payload.test_id}`;

        if (getters.isSample) {
            url = `/samples/resources/${payload.resource_id}/tests/${payload.test_id}`;
            // route += 'preview';
        } else if (getters.isPreview) {
            url = `/preview${url}`;
            // route += 'preview';
        } else if (getters.graded) {
            url = `/submissions/${getters.submission.id}`;
            // route += 'show';
        }

        // route += '.page';

        url += `/pages/${payload.order}`;
        // console.log(route, route(route, {test: data.test_id, page: data.order}))

        // this.$router.push(route(route, {test: data.test_id, page: data.order}));
        router.push(url).catch((error) => {
            console.error(error);
        });
    },
};
