<script>
import Loading from "vue-loading-overlay";

import "vue-loading-overlay/dist/css/index.css";
import Swal from "sweetalert2";
import BtnDanger from "@/components/Global/BtnDanger.vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import ModalWindow from "@/components/Global/ModalWindow.vue";
import CToggleSwitch from "../Form/CToggleSwitch.vue";

export default {
    components: { BtnPrimary, BtnDanger, CToggleSwitch, Loading, ModalWindow },
    data() {
        return {
            processing: false,
            showCourseList: false,
            courses: [],
            selectedCourses: [],
            showStudentList: false,
            students: [],
            publishedStatus: false,
        };
    },
    props: {
        user: {},
        test: {},
    },
    methods: {
        /**
         *  Sign in the user upon button click.
         */
        handleAuthClick() {
            if (this.user.google_classroom_enabled) {
                this.listCourses();
            } else {
                const url = `${route("auth.google-classroom")}?redirect=${window.location.href}&listCourses=1`;
                window.location.href = url;
            }
        },

        notifyUserOfError(errors, message) {
            console.log(errors.response);
            if (errors.response) {
                if (errors.response.status === 400) {
                    message = errors.response.data.message;
                }

                Swal.fire({
                    title: "Error!",
                    html: message,
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Close",
                });
            }
        },
        askToSelectCourses() {
            this.selectedCourses = [];
            // If there is more than one Google courses, ask teacher to select a course.
            this.showCourseList = this.courses.length > 1;
            // Else start sync
            if (this.courses.length === 1) {
                this.selectedCourses.push(this.courses[0].id);
                this.syncClassroom();
            }
        },
        listCourses() {
            if (this.courses.length > 0) {
                this.askToSelectCourses();
            } // Get courses
            else {
                this.processing = true;
                const url = route("teacher.google-classroom.courses");
                axios
                    .get(url)
                    .then((response) => {
                        this.courses = response.data.courses;

                        if (!this.courses || this.courses.length <= 0) {
                            Swal.fire({
                                title: "No Courses Found",
                                html: "Please make sure your Google Classroom has at least one course.",
                                icon: "error",
                                showCancelButton: false,
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Close",
                            });
                            return;
                        }

                        this.askToSelectCourses();
                    })
                    .catch(({ response }) => {
                        const message = response.data.message ?? "Failed to list Google courses.";
                        Swal.fire({
                            title: "Error!",
                            html: message,
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Close",
                        });
                    })
                    .finally(() => {
                        this.processing = false;
                    });
            }
        },

        async syncClassroom() {
            if (this.selectedCourses.length > 0) {
                this.processing = true;

                const url = route("teacher.google-classroom.sync", { resource: this.test.resource_id });
                axios
                    .post(url, {
                        selectedCourses: this.selectedCourses,
                        test: this.test,
                        publishedStatus: this.publishedStatus,
                    })
                    .then((response) => {
                        let html = `You have assigned <b>${this.test.title}</b> to the following classes:`;
                        html += '<br><ul class="mt-4 text-left text-base">';
                        this.selectedCourses.forEach((courseId) => {
                            const course = this.getCourse(courseId);
                            html += `<li class="py-1"><b>${course.name}</b>
                                    <span class="font-light text-gray-500">${course.section ?? ""}</span></li>`;
                        });
                        html += "</ul>";

                        Swal.fire({
                            title: "Success!",
                            html,
                            icon: "success",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Close",
                        });
                    })
                    .catch((errors) => {
                        this.notifyUserOfError(errors, "Google sync failed.");
                    })
                    .finally(() => {
                        this.processing = false;
                        this.showCourseList = false;
                    });
            }
        },

        getCourse(courseId) {
            const index = this.courses.findIndex((x) => x.id == courseId);
            return this.courses[index];
        },
    },
};
</script>

<template>
    <div>
        <Loading
            v-model:active="processing"
            :can-cancel="false"
            :is-full-page="true"
        />

        <BtnPrimary
            class="my-0 inline-block rounded-full border-0 bg-green-600 py-1 text-sm font-[300] hover:bg-green-800"
            @click.prevent="handleAuthClick"
        >
            Assign
        </BtnPrimary>

        <!-- Course List -->
        <ModalWindow
            v-model:show="showCourseList"
            header-classes="modal-header px-5 pt-5 text-center"
            footer-classes="modal-footer border0-t px-5 pb-5 text-center"
        >
            <template #header>
                <h5 class="text-2xl uppercase">Google Classroom</h5>
            </template>

            <div class="-mt-2 mb-1">
                <span class="mr-1">Set status as published: </span>

                <CToggleSwitch
                    v-model="publishedStatus"
                    :input-value="true"
                />

                <p class="font-light text-gray-500">
                    Published status will enable this assignment and emails will be send out to students.
                </p>
            </div>

            <p class="mb-1 mt-6">Select which class(es) you would like to assign this to:</p>

            <div class="pb-4 text-left md:grid md:grid-cols-2">
                <div
                    v-for="c in courses"
                    :key="c.id"
                >
                    <label class="block cursor-pointer p-2 hover:bg-gray-200">
                        <input
                            type="checkbox"
                            v-model="selectedCourses"
                            :value="c.id"
                        />

                        <span class="ml-1">{{ c.name }}</span>

                        <span class="block pl-2 font-light text-gray-500 md:ml-0 md:inline">{{ c.section }}</span>
                    </label>
                </div>
            </div>

            <template #footer>
                <BtnPrimary
                    @click="syncClassroom"
                    :disabled="processing || selectedCourses.length <= 0"
                >
                    Assign to Class
                </BtnPrimary>

                <BtnDanger
                    class="ml-2"
                    @click="showCourseList = false"
                >
                    Cancel
                </BtnDanger>
            </template>
        </ModalWindow>

        <!-- Student List -->
        <ModalWindow
            v-model:show="showStudentList"
            modal-width="w-full lg:w1/2 2xl:w-5/12"
            header-classes="modal-header px-5 pt-5 text-center"
            footer-classes="modal-footer border0-t px-5 pb-5 text-center"
        >
            <template #header>
                <h5 class="text-2xl uppercase">Student List</h5>
            </template>

            <div class="pb-4">
                Coming soon...
                <!--                <label v-for="s in students" class="block p-2 border border-gray-400 border-b-0 last:border-b-->
                <!--                    cursor-pointer even:bg-gray-100 hover:bg-gray-200">-->
                <!--                    <input type="radio" v-model="selectedCourses" :value="s.id"/>-->
                <!--                    <span class="ml-1">{{ c.descriptionHeading }}</span>-->
                <!--                </label>-->
            </div>

            <template #footer>
                <BtnPrimary
                    @click="syncClassroom"
                    :disabled="processing"
                >
                    Update
                </BtnPrimary>

                <BtnDanger
                    class="ml-2"
                    @click="showStudentList = false"
                >
                    Cancel
                </BtnDanger>
            </template>
        </ModalWindow>
    </div>
</template>
