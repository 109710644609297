<script lang="ts" setup>
import trimEnd from "lodash/trimEnd";
import { useAttrs } from "vue";
import { computed } from "~/vue";

const props = defineProps({
    align: {
        type: String,
        default: "",
    },
    citation: {
        type: String,
        default: "",
    },
    className: {
        type: String,
        default: "",
    },
    value: {
        type: String,
        required: true,
    },
});

const attrs = useAttrs();

const quoteContent = computed(() => trimEnd(props.value, "</p>").replaceAll("<p>", "").split("</p>"));

/*
 * The pullquote and quote blocks have the same internal structure so to reduce duplication
 * we are using this class in the pull quote. So the class prop will only be passed in from
 * the pullquote at which point we don't want to add the default WP class.
 */
const classNames = computed(() => ({
    [attrs.class]: attrs.class,
    "wp-block-quote": !attrs.class,
    [props.className]: props.className,
    [`has-text-align-${props.align}`]: props.align,
}));
</script>

<template>
    <blockquote :class="classNames">
        <p
            v-for="line in quoteContent"
            :key="line"
            v-html="line"
        />

        <cite
            v-if="citation"
            v-html="citation"
        />
    </blockquote>
</template>
