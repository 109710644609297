<script>
import AddCodeBtnModal from "@/components/Teacher/AddCodeBtnModal.vue";
import ResourceCode from "@/components/Teacher/ResourceCode.vue";

import PaginationDefault from "@/components/Global/paginationDefault.vue";
import { FontAwesomeIcon } from "~/@fortawesome/vue-fontawesome";
import Swal from "sweetalert2";
import CToggleSwitch from "@/components/Form/CToggleSwitch.vue";

export default {
    components: { CToggleSwitch, FontAwesomeIcon, AddCodeBtnModal, ResourceCode, PaginationDefault },
    data() {
        return {
            resourceCodes: [],
            sort: "-class_name",
            asc: true,
            page: 1,
            loading: false,
            paginationData: {},
            includeArchived: false,
        };
    },

    props: {
        codes: {},

        resourceId: {},
    },

    methods: {
        updateCode(value) {
            const index = _.findIndex(this.resourceCodes, { code: value.code });

            this.resourceCodes.splice(index, 1, value);
        },
        fetchCodes() {
            this.loading = true;
            const params = {
                page: this.page ?? 1,
                sort: this.sort,
                includeArchived: this.includeArchived ? 1 : 0,
            };
            const paramsStr = new URLSearchParams(params).toString();

            axios
                .get(`${`${route("teacher.resource.fetch-codes", [this.resourceId])}?${paramsStr}`}`)
                .then((response) => {
                    this.paginationData = response.data;
                    this.resourceCodes = response.data.data;
                    // Update route URL. It appears we can't just do a push to update the query params.
                    // It will complain about no match route. So we have to add a new route and then replace it.
                    this.$router.addRoute({
                        name: "resource.test.codes",
                        path: this.$route.path,
                    });
                    this.$router.replace({
                        name: "resource.test.codes",
                        query: params,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        sortBy(key) {
            this.sort = this.sort === key ? `-${key}` : key;
            this.fetchCodes();
        },
        goToPage(page) {
            if (page > 0) {
                this.page = page;
                this.fetchCodes();
            }
        },
        confirmToggleArchive(code) {
            const studentName = code.student_name ?? "Student";
            let msg = `${studentName} will be archived`;
            if (code.archived_at) {
                msg = `${studentName} will be unarchived`;
            }

            msg += ".";

            Swal.fire({
                title: "Are you sure?",
                html: msg,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!",
            }).then((result) => {
                if (result.value) {
                    const url = route("teacher.resource.toggle-archived", [code.code]);
                    const postData = new FormData();
                    postData.append("code", code.code);
                    postData.append("resourceId", code.resource_id ?? "");
                    postData.append("includeArchived", this.includeArchived ? 1 : 0);

                    this.loading = true;
                    axios
                        .post(url, postData)
                        .then(({ data }) => {
                            // If archiving and this is the last code in the page, go back a page
                            if (!code.archived_at && this.resourceCodes.length === 1) {
                                this.paginationData = null;
                                this.resourceCodes = [];
                                window.location.href = `/`;
                            } else {
                                this.fetchCodes();
                            }
                        })
                        .catch((errors) => {
                            console.error(errors);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
    },

    created() {
        if (this.$router.currentRoute.value.query.page) {
            this.page = this.$router.currentRoute.value.query.page;
        }
        if (this.$router.currentRoute.value.query.sort) {
            this.sort = this.$router.currentRoute.value.query.sort;
        }
        this.fetchCodes();
    },
};
</script>

<template>
    <div>
        <div class="relative mb-4">
            <AddCodeBtnModal
                :resource-id="resourceId"
                @created="fetchCodes"
            ></AddCodeBtnModal>

            <div class="absolute right-0 top-4 font-bold">
                <CToggleSwitch
                    v-model="includeArchived"
                    @change="fetchCodes"
                    :input-value="includeArchived"
                />
                Include Archived
            </div>
        </div>

        <div class="relative">
            <div class="mb-4 w-full w-full rounded-lg bg-white leading-normal shadow-lg">
                <div class="hidden w-full border border-b-0 bg-gray-200 first:rounded-t-lg lg:block">
                    <div class="flex items-center">
                        <div
                            @click="sortBy('code')"
                            class="w-[12%] flex-initial cursor-pointer px-2 py-3 hover:bg-gray-300"
                            :class="sort.includes('code') ? 'bg-gray-300' : ''"
                        >
                            <FontAwesomeIcon
                                :icon="['fas', 'sort']"
                                class="mr-1"
                            />
                            Code
                        </div>

                        <div
                            @click="sortBy('class_name')"
                            class="w-[22%] flex-initial cursor-pointer px-2 py-3 hover:bg-gray-300"
                            :class="sort.includes('class_name') ? 'bg-gray-300' : ''"
                        >
                            <FontAwesomeIcon
                                :icon="['fas', 'sort']"
                                class="mr-1"
                            />
                            Class
                        </div>

                        <div
                            @click="sortBy('student_name')"
                            class="w-[24%] flex-initial cursor-pointer px-2 py-3 hover:bg-gray-300"
                            :class="sort.includes('student_name') ? 'bg-gray-300' : ''"
                        >
                            <FontAwesomeIcon
                                :icon="['fas', 'sort']"
                                class="mr-1"
                            />
                            Student Name
                        </div>

                        <div class="w-[30%] flex-initial px-2 py-3">Student Email / Number</div>

                        <div class="flex w-[12%] flex-initial px-2 py-3 text-center">Actions</div>
                    </div>
                </div>

                <ResourceCode
                    :code="code"
                    v-for="code in resourceCodes"
                    :key="code.code"
                    :dummy="dummy"
                    @update="updateCode"
                    @toggle-archive="confirmToggleArchive"
                />
            </div>

            <div
                v-show="loading"
                class="absolute top-0 flex h-full w-full items-center justify-center bg-gray-200 text-center text-2xl opacity-50"
            >
                <FontAwesomeIcon
                    icon="sync"
                    spin
                    class="mr-2"
                />
                Loading
            </div>

            <PaginationDefault
                v-if="paginationData && paginationData.links && paginationData.links.length > 3"
                :data="paginationData"
                @go-to-page-clicked="goToPage"
            ></PaginationDefault>
        </div>
    </div>
</template>
