<script lang="ts" setup>
import classnames from "classnames";
import { computed, inject } from "vue";

const props = defineProps({
    centerCell: {
        type: Boolean,
        default: false,
    },
    column: {
        type: Number,
        required: true,
    },
    isHeader: {
        type: Boolean,
        default: false,
    },
    numberOfColumns: {
        type: Number,
        required: true,
    },
    row: {
        type: Number,
        required: true,
    },
});

const { answerState } = inject("answer");

const gradingClasses = computed(() => answerState(`${props.row}-${props.column}`, true));

const cellClasses = computed(() =>
    classnames({
        [gradingClasses.value]: gradingClasses.value,
        header: props.isHeader,
        "flex justify-center center-cell": props.centerCell,
    }),
);
</script>

<template>
    <div
        class="cell"
        :class="cellClasses"
        :style="{ width: `${100 / numberOfColumns}%` }"
    >
        <div
            class="cell-wrapper"
            :data-index="`${row}-${column}`"
        >
            <slot />
        </div>
    </div>
</template>
