<script>
import BtnPrimary from "@/components/Global/BtnPrimary.vue";

export default {
    components: { BtnPrimary },
    props: {
        user: null,
    },
    data() {
        return {
            processing: false,
            responseMsg: "",
        };
    },
    methods: {
        syncRoster() {
            this.processing = true;
            axios
                .post(route("teacher.classlink-roster.sync"))
                .then(({ data }) => {
                    console.log("data:", data);
                    if (data.successful) {
                        this.responseMsg = "Roster Successfully Synced!";
                    } else {
                        this.responseMsg = `<div class="text-red-800">${data.message}</div>`;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    const msg = error.response.data.message ?? "Failed to sync roster! Please try again later.";
                    this.responseMsg = `<div class="text-red-800">${msg}</div>`;
                })
                .finally(() => {
                    this.processing = false;
                    setTimeout(() => {
                        this.responseMsg = "";
                    }, "7000");
                });
        },
    },
};
</script>

<template>
    <div>
        <p
            v-if="responseMsg"
            v-html="responseMsg"
            class="mb-4 rounded border border-yellow-300 bg-yellow-100 p-2"
        ></p>

        <div class="mb-6">
            Sync your ClassLink Roster with us. It's easy! Just click the button below and we'll take care of the rest.
        </div>

        <div>
            <p v-if="!user.classlink_access_token">
                <b>This account has not been linked to a ClassLink account yet.</b>
                Please login using ClassLink SSO.
            </p>

            <p class="text-center">
                <BtnPrimary
                    :disabled="!user.classlink_access_token || processing"
                    type="button"
                    @click="syncRoster"
                    class="mx-auto mt-8 w-full rounded-[0.25rem] text-lg xl:w-auto xl:px-12"
                >
                    Sync ClassLink Roster
                </BtnPrimary>
            </p>
        </div>
    </div>
</template>

<style scoped></style>
