import { mapActions, mapGetters } from "vuex";
import _, { debounce as _debounce } from "lodash";

export default {
    data() {
        return {};
    },

    props: ["questionNumber", "clientID", "name"],

    computed: {
        ...mapGetters({
            _correctAnswer: "correctAnswer",
            _graded: "graded",
            _question: "question",
            _scoreReceived: "scoreReceived",
            _submittedAnswer: "submittedAnswer",
            _submittedAnswers: "submittedAnswers",
            _submittedAnswerValue: "submittedAnswerValue",
        }),

        answerState() {
            return (value, bypassValueCheck = false) => {
                if (!this._graded || (/\d+-\d+/.test(value) && !bypassValueCheck)) {
                    return "";
                }

                const submittedAnswer = this._submittedAnswerValue(this.clientId);
                const correctAnswer = this._correctAnswer(this.clientId);

                if (!correctAnswer) {
                    return "";
                }

                // No submitted answer
                if (!submittedAnswer) {
                    if (this.valueIsCorrect(value, correctAnswer)) {
                        return "incorrect should-be-selected";
                    }
                    return "";
                }

                if (Array.isArray(correctAnswer)) {
                    if (submittedAnswer.indexOf(value) > -1 && this.valueIsCorrect(value, correctAnswer)) {
                        return "correct";
                    }
                    if (submittedAnswer.indexOf(value) === -1 && !this.valueIsCorrect(value, correctAnswer)) {
                        return "";
                    }
                    if (submittedAnswer.indexOf(value) > -1 && !this.valueIsCorrect(value, correctAnswer)) {
                        return "incorrect";
                    }
                } else {
                    if (value == submittedAnswer && this.valueIsCorrect(value, correctAnswer)) {
                        return "correct";
                    }
                    if (value == submittedAnswer && !this.valueIsCorrect(value, correctAnswer)) {
                        return "incorrect";
                    }
                    if (value != submittedAnswer && !this.valueIsCorrect(value, correctAnswer)) {
                        return "";
                    }
                }

                return "incorrect should-be-selected";
            };
        },

        clientId() {
            return this.clientID;
        },

        defaultAnswer() {
            return null;
        },

        submittedAnswer() {
            const question = this._question(this.clientId);

            if (!question) {
                console.log("Question not found!");
                alert(`Question not found! Please contact CouresWave for assistance. Client ID ${this.clientId}`);
            } else {
                return _.find(this._submittedAnswers, { question_id: question.id });
            }
        },

        submittedAnswerId() {
            const submittedAnswer = this._submittedAnswer(this.clientId);

            return submittedAnswer ? submittedAnswer.id : 0;
        },
    },

    methods: {
        ...mapActions({
            _resetAnswer: "resetAnswer",
            _saveAnswer: "saveAnswer",
        }),

        getWrapper() {
            if (!this.$refs || !this.$refs[`question-${this.clientId}`]) {
                return null;
            }

            if (!this.$refs[`question-${this.clientId}`].__isVue) {
                return this.$refs[`question-${this.clientId}`];
            }

            return this.$refs[`question-${this.clientId}`].$el;
        },

        labelClick(id) {
            document.getElementById(id).click();
        },

        resetAnswer(event = null, skipReset = false) {
            this._resetAnswer({
                clientId: this.clientId,
                default: this.defaultAnswer,
            }).then(() => {
                let { defaultAnswer } = this;

                if (Array.isArray(defaultAnswer)) {
                    defaultAnswer = [...defaultAnswer];
                } else if (typeof defaultAnswer === "object" && defaultAnswer !== null) {
                    defaultAnswer = { ...defaultAnswer };
                }

                if (!skipReset) {
                    this.answer = defaultAnswer;

                    this.resetSuccess();
                }
            });
        },

        resetSuccess() {},

        valueIsCorrect(value, correctAnswer) {
            if (Array.isArray(correctAnswer)) {
                if (typeof correctAnswer[0] === "number") {
                    value = parseInt(value);
                }
                if (correctAnswer.includes(value)) {
                    return true;
                }
            } else if (value == correctAnswer) {
                return true;
            }
            return false;
        },

        saveAnswer: _debounce(function () {
            this._saveAnswer({
                clientId: this.clientId,
                answer: this.answer,
            });
        }, 200),

        setAnswer(newAnswer) {
            if (Array.isArray(newAnswer)) {
                newAnswer.forEach((value) => {
                    this.answer.push(value);
                });
            } else if (typeof newAnswer === "object" && newAnswer !== null) {
                Object.keys(newAnswer).forEach((key) => {
                    this.answer[key] = newAnswer[key];
                });
            } else {
                this.answer = newAnswer;
            }
        },
    },

    provide() {
        return {
            answer: {
                answer: this.answer,
                getAnswer: () => this.answer,
                graded: this._graded,
                selectAnswer: this.selectAnswer ?? this.selectedValue,
                answerState: this.answerState,
            },

            questionClientId: this.clientId,
        };
    },

    beforeMount() {
        if (this.submittedAnswer) {
            this.setAnswer(this.submittedAnswer.answer);
        } else {
            this.setAnswer(this.defaultAnswer);
        }
    },

    mounted() {
        if (this.$el.classList && !this.$el.classList.contains("mb-100p")) {
            this.$el.classList.add("mb-100p");
        }
    },
};
