<script lang="ts" setup>
import { LINK_DESTINATION_ATTACHMENT, LINK_DESTINATION_MEDIA } from "@/components/TestTaking/Blocks/Core/constants";
import ImageMedia from "@/components/TestTaking/Blocks/Core/Media/ImageMedia.vue";
import { computed } from "vue";

const props = defineProps({
    images: {
        type: Array,
        required: true,
    },
    columns: {
        type: Number,
        default: (rawProps) => Math.min(3, rawProps.images.length),
    },
    imageCrop: {},
    caption: {
        type: String,
        default: "",
    },
    linkTo: {
        type: String,
        default: null,
    },
});

const classNames = computed(() => ({
    [`columns-${props.columns}`]: true,
    "is-cropped": props.imageCrop,
}));

const getImageHref = (image) => {
    switch (props.linkTo) {
        case LINK_DESTINATION_MEDIA:
            return image.fullUrl || image.url;
        case LINK_DESTINATION_ATTACHMENT:
            return image.link;
        case "none":
        default:
            return null;
    }
};
</script>

<template>
    <figure
        class="wp-block-gallery"
        :class="classNames"
    >
        <ul class="blocks-gallery-grid">
            <li
                class="blocks-gallery-item"
                v-for="image in images"
                :key="image.url"
            >
                <figure>
                    <template v-if="getImageHref(image)">
                        <a :href="getImageHref(image)">
                            <ImageMedia
                                :media-alt="image.alt"
                                :media-id="image.id"
                                :media-url="image.url"
                                :data-id="image.id"
                                :data-full-url="image.fullUrl"
                                :data-link="image.link"
                            />
                        </a>
                    </template>

                    <template v-else>
                        <ImageMedia
                            :media-alt="image.alt"
                            :media-id="image.id"
                            :media-url="image.url"
                            :data-id="image.id"
                            :data-full-url="image.fullUrl"
                            :data-link="image.link"
                        />
                    </template>

                    <figcaption
                        class="blocks-gallery-item__caption"
                        v-if="image.caption"
                        v-html="image.caption"
                    />
                </figure>
            </li>
        </ul>

        <figcaption
            class="blocks-gallery-caption"
            v-if="caption"
            v-html="caption"
        />
    </figure>
</template>
