<script>
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";

export default {
    components: { QuestionBlock },
    mixins: [Question],

    data() {
        return {
            answer: [],
        };
    },

    computed: {
        defaultAnswer() {
            return [];
        },
    },

    methods: {
        selectedValue(event) {
            if (this._graded) {
                return;
            }

            const { checked, value } = event.target;

            if (checked) {
                this.answer.push(value);
            } else {
                const index = this.answer.indexOf(value);

                if (index > -1) {
                    this.answer.splice(index, 1);
                }
            }

            this.saveAnswer();
        },
    },
};
</script>

<template>
    <QuestionBlock
        :question-number="questionNumber"
        :block-id="clientId"
        @reset-answer="resetAnswer"
        class="table-grid"
    >
        <slot
            :selected-value="selectedValue"
            :label-click="labelClick"
            :answer="answer"
            :graded="_graded"
            :answer-state="answerState"
        />
    </QuestionBlock>
</template>
