<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
    allowResponsive: {
        type: Boolean,
        default: false,
    },

    caption: {
        type: String,
        default: "",
    },

    className: {
        type: String,
        default: "",
    },

    embed: {
        type: String,
        default: "",
    },

    previewable: {
        type: Boolean,
        default: false,
    },

    providerNameSlug: {
        type: String,
        default: "",
    },

    responsive: {
        type: Boolean,
        default: false,
    },

    type: {
        type: String,
        default: "",
    },

    url: {
        type: String,
        default: "",
    },
});

const classes = computed(() => [
    `is-type-${props.type}`,
    `is-provider-${props.providerNameSlug}`,
    `wp-block-embed-${props.providerNameSlug}`,
    props.className,
]);
</script>

<template>
    <figure
        class="wp-block-embed"
        :class="classes"
    >
        <div
            class="wp-block-embed__wrapper"
            v-html="embed"
        />

        <figcaption v-if="caption">
            {{ caption }}
        </figcaption>
    </figure>
</template>
