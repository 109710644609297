<script>
import debounce from "lodash/debounce";

export default {
    name: "PaginationDefault",
    data() {
        return {
            maxLinks: 10, // Use by Algolia to determine when to show the dotted link
            numOfExtraLink: 3, // Buffer for the dotted link
        };
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    methods: {
        getPageNum(link) {
            const urlObj = new URL(link);
            if (urlObj.searchParams.has("page")) {
                return parseInt(urlObj.searchParams.get("page"), 10);
            }

            return 1;
        },
        goToPage: debounce(function (page) {
            if (page > 0 && page <= this.lastPage && page !== this.currentPage) {
                this.$emit("goToPageClicked", page);
            }
        }, 500),
    },
    computed: {
        // NOTE: Algolia page start from zero
        currentPage() {
            return this.data?.current_page ?? this.data.page + 1 ?? 1;
        },
        firstPage() {
            return 1;
        },
        lastPage() {
            return this.data?.last_page ?? this.data.nbPages ?? 1;
        },
        nextPage() {
            if (this.currentPage + 1 > this.lastPage) return this.lastPage;

            return this.currentPage + 1;
        },
        prevPage() {
            if (this.currentPage - 1 < 1) return 1;

            return this.currentPage - 1;
        },
        algoliaTotalPages() {
            return this.data.nbPages;
        },
        algoliaIndexStartAt() {
            let startAt = 1;
            // If there are more than the maxLinks
            if (this.algoliaTotalPages > this.maxLinks) {
                startAt = this.currentPage - this.numOfExtraLink; // Current page - extra links

                // How many spots are left to reach the end
                const numOfLinksToTheRight = this.algoliaTotalPages - startAt;
                // If there are unused spots to the right, move the startAt to the left
                if (numOfLinksToTheRight < this.maxLinks) {
                    startAt -= this.maxLinks - numOfLinksToTheRight;
                }
            }

            return startAt > 0 ? startAt : 1;
        },
        algoliaIndexEndAt() {
            let endAt = this.lastPage;
            if (this.algoliaIndexStartAt > 1 || this.algoliaTotalPages > this.maxLinks) {
                endAt = this.algoliaIndexStartAt + this.maxLinks;
            }
            return endAt > this.lastPage ? this.lastPage : endAt;
        },
        links() {
            if (this.data.links && this.data.links.length > 0) return this.data.links;

            // Since Algolia doesn't return links, we need to create them
            const dottedLinkIndex = 11;
            const startAt = 1;

            const algoliaLinks = [];
            const dummyLink = "https://coursewave.test"; // Need to add dummy link to get new URL() to work
            for (let i = 1; i <= this.lastPage; i += 1) {
                // Previous link (Disabled)
                if (i === this.firstPage && this.currentPage === this.firstPage) {
                    algoliaLinks.push({
                        label: "Previous",
                        url: null,
                        active: false,
                    });
                } // Previous link
                else if (i === this.firstPage) {
                    algoliaLinks.push({
                        label: "Previous",
                        url: `${dummyLink}?page=${this.currentPage - 1}`,
                        active: true,
                    });
                }
                // Next link (Disabled)
                if (i === this.lastPage && this.currentPage === this.lastPage) {
                    algoliaLinks.push({
                        label: "Next",
                        url: null,
                        active: false,
                    });
                } // Next link
                else if (i === this.lastPage) {
                    algoliaLinks.push({
                        label: "Next",
                        url: `${dummyLink}?page=${this.currentPage + 1}`,
                        active: true,
                    });
                }

                // Dotted/number link
                // Generate previous links before the dotted link
                if (i < this.algoliaIndexStartAt && i >= this.numOfExtraLink) {
                    algoliaLinks.push({
                        label: "...",
                        url: null,
                        active: false,
                    });
                    i = this.algoliaIndexStartAt;
                } // Jump toward the end - X of extra links
                else if (i > this.algoliaIndexEndAt && i <= this.lastPage - this.numOfExtraLink) {
                    algoliaLinks.push({
                        label: "...",
                        url: null,
                        active: false,
                    });
                    i = this.lastPage - this.numOfExtraLink;
                } else {
                    algoliaLinks.push({
                        label: i,
                        url: `${dummyLink}?page=${i}`,
                        active: i === this.currentPage,
                    });
                }
            }
            return algoliaLinks;
        },
    },
};
</script>

<template>
    <div v-if="data && links.length > 3">
        <div class="flex items-center border-t border-gray-200 bg-white px-0 py-4">
            <div class="flex flex-1 justify-between sm:hidden">
                <span
                    @click="goToPage(prevPage)"
                    :class="currentPage === firstPage ? 'disabled' : ''"
                    class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-primary hover:bg-gray-50"
                >
                    Previous
                </span>

                <span
                    @click="goToPage(nextPage)"
                    :class="currentPage === lastPage ? 'disabled' : ''"
                    class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-primary hover:bg-gray-50"
                >
                    Next
                </span>
            </div>

            <div class="hidden justify-center sm:flex sm:flex-1 sm:items-center">
                <div>
                    <nav
                        class="relative z-0 inline-flex -space-x-px rounded-sm shadow-sm"
                        aria-label="Pagination"
                    >
                        <span
                            @click="goToPage(prevPage)"
                            :class="currentPage === firstPage ? 'disabled' : 'cursor-pointer'"
                            disabled="true"
                            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-primary hover:bg-gray-50"
                        >
                            <span class="sr-only">Previous</span>
                            <!-- Heroicon name: solid/chevron-left -->
                            <svg
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>

                        <span
                            v-for="(link, index) in links"
                            :key="index"
                        >
                            <span
                                v-if="
                                    link.active &&
                                    !link.label.toString().toLowerCase().includes('previous') &&
                                    !link.label.toString().toLowerCase().includes('next')
                                "
                                aria-current="page"
                                class="relative z-10 inline-flex items-center border border-primary bg-primary px-4 py-2 text-sm font-medium text-white"
                            >
                                {{ link.label }}
                            </span>

                            <span
                                v-else-if="
                                    !link.url &&
                                    !link.label.toString().toLowerCase().includes('previous') &&
                                    !link.label.toString().toLowerCase().includes('next')
                                "
                                class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-primary hover:bg-gray-50"
                            >
                                {{ link.label }}
                            </span>

                            <span
                                v-else-if="
                                    link.url &&
                                    !link.label.toString().toLowerCase().includes('previous') &&
                                    !link.label.toString().toLowerCase().includes('next')
                                "
                                @click="goToPage(getPageNum(link.url))"
                                class="relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-primary hover:bg-gray-50"
                                v-html="link.label"
                            >
                            </span>
                        </span>

                        <span
                            @click="goToPage(nextPage)"
                            :class="currentPage === lastPage ? 'disabled' : 'cursor-pointer'"
                            class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-primary hover:bg-gray-50"
                        >
                            <span class="sr-only">Next</span>
                            <!-- Heroicon name: solid/chevron-right -->
                            <svg
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
