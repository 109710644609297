<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import ImportCodesBtnModal from "@/components/Teacher/ImportCodesBtnModal.vue";
import AddCodeBtnModal from "@/components/Teacher/AddCodeBtnModal.vue";

const jobs = ref(null);
const showJobStatus = ref(false);

function refreshPage() {
    window.location.reload();
}
function toggleJobStatus() {
    showJobStatus.value = !showJobStatus.value;

    const date = new Date();
    date.setDate(date.getDate() + 1);
    document.cookie = `showJobStatus=${showJobStatus.value}; expires=${date}`;
}
function getCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

onMounted(() => {
    axios
        .get(route("teacher.resource.import-code-jobs"))
        .then((response) => {
            jobs.value = response.data.jobs;
            showJobStatus.value = getCookie("showJobStatus") === "true";
        })
        .catch((error) => {
            console.log(error);
        });
});
</script>

<template>
    <div class="">
        <div class="flex gap-x-2">
            <ImportCodesBtnModal class=""></ImportCodesBtnModal>

            <AddCodeBtnModal @created="refreshPage"></AddCodeBtnModal>
        </div>

        <div
            v-if="showJobStatus"
            class="mt-2 rounded-lg border bg-gray-200 p-2 drop-shadow-lg"
        >
            <div
                v-for="job in jobs"
                :key="job.id"
                class="my-2 rounded-lg border border-red-800 bg-yellow-200 p-2 text-center"
            >
                <div class="font-bold">Import In Progress...</div>

                <span class="text-xs">{{ job.file }}</span>
            </div>

            <div
                v-if="showJobStatus"
                class="mt-6 text-center"
            >
                <span
                    @click="toggleJobStatus"
                    class="cursor-pointer"
                    >Close</span
                >
            </div>

            <div
                v-else
                class="text-center"
            >
                <span
                    @click="toggleJobStatus"
                    class="cursor-pointer"
                    >View Imports</span
                >
            </div>
        </div>
    </div>
</template>
