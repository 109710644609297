<script lang="ts" setup>
import { computed, inject } from "vue";

const props = defineProps({
    index: {
        type: Number,
        required: true,
    },
    value: {
        type: [String, Number],
        required: true,
    },
});

const hidePlaceholder = inject("hidePlaceholder");

const showPlaceholder = computed(() => !hidePlaceholder(props.value));
</script>

<template>
    <div
        class="drop-target"
        :data-index="index"
        v-show="showPlaceholder"
    >
        Drop Here...
    </div>
</template>
