<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
    content: {
        type: String,
        required: true,
    },

    increment: {
        type: Number,
        default: 1,
    },

    offset: {
        type: Number,
        default: 0,
    },

    showLineNumbers: {
        type: Boolean,
        default: false,
    },
});

const lines = computed(() => {
    const el = document.createElement("div");
    el.innerHTML = props.content;

    const { childNodes } = el;
    const lineItems = [] as object[];
    let lineCount = 0;

    childNodes.forEach((child) => {
        const line = {
            content: child.textContent,
            showLineNumber: false,
        };

        if (line.content !== "") {
            lineCount += 1;

            if (child.textContent !== "" && (lineCount - props.offset) % props.increment === 0) {
                line.showLineNumber = true;
            }
        }

        lineItems.push(line);
    });

    return lineItems;
});

const offsetClassName = computed(() => {
    let tempOffset = props.offset;

    if (props.offset === 0 || props.offset >= props.increment) {
        tempOffset = props.increment;
    }

    return `offset-${props.increment - tempOffset}`;
});

const classes = computed(() => ({
    "line-numbers": props.showLineNumbers,
    [`increment-${props.increment}`]: props.increment > 1,
    [offsetClassName.value]: offsetClassName.value,
}));

// const shouldShowLineNumber = computed(() => (index: number) => {
//     if (lines.value[index] === "") {
//         return false;
//     }
//     if (props.showLineNumbers) {
//         return index + props.increment + props.offset;
//     }
//
//     return false;
// });
</script>

<template>
    <div
        class="wp-block-text-poem poem w-full"
        :class="classes"
    >
        <div class="w-full">
            <template
                v-for="line in lines"
                :key="line.content"
            >
                <p
                    class="line"
                    :class="{
                        'show-number': line.showLineNumber,
                    }"
                    v-if="line.content"
                >
                    {{ line.content }}
                </p>

                <p v-else>&nbsp;</p>
            </template>
        </div>
    </div>
</template>
