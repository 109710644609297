<script>
import { mapMutations } from "vuex";
import debounce from "lodash/debounce";
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";

import TinyMceRender from "@/library/tinymce.js";

export default {
    components: { QuestionBlock },
    mixins: [Question],

    data: () => ({
        answer: "",
        textEditorId: "",
    }),

    computed: {
        defaultAnswer() {
            return "";
        },
    },

    methods: {
        ...mapMutations({
            _setSaving: "setSaving",
        }),

        inputtedValue(editor) {
            return debounce((event) => {
                const content = _.trim(editor.getContent({ format: "text" }));
                if (content !== "") {
                    this.answer = editor.getContent();

                    this.saveAnswer();
                } else {
                    this.resetAnswer(event, true);
                }
            }, 200);
        },

        resetSuccess() {
            const editor = this.getEditor();
            if (editor) {
                editor.setContent("");
            }
        },

        saveEditorContent(editor) {
            return debounce((event) => {
                const content = _.trim(editor.getContent());

                if (content !== this.answer) {
                    this._setSaving({ clientId: this.clientId, saving: true });

                    this.inputtedValue(editor)(event);
                }
            }, 500);
        },

        setTextEditorId() {
            // NOTE: If short-essay component is nested in inner block, Client ID will be different.
            // Option 1: Selector by textarea ID
            let ele = document.getElementById(`essay-${this.clientId}`);
            if (ele) {
                this.textEditorId = `essay-${this.clientId}`;
            } else if (document.getElementById(`question-${this.clientId}`)) {
                // Option 2: Selector by textarea class
                ele = document.getElementById(`question-${this.clientId}`).getElementsByClassName("tinymce-editor");
                if (ele && ele.length > 0) {
                    this.textEditorId = ele[0].id;
                }
                // Option 3: Selector by textarea element
                ele = document.getElementById(`question-${this.clientId}`).getElementsByTagName("textarea");
                if (ele && ele.length > 0) {
                    this.textEditorId = ele[0].id;
                }
            }
        },
        getEditor() {
            if (tinymce !== undefined) {
                return tinymce.get(this.textEditorId);
            }

            return null;
        },
    },

    async mounted() {
        this.setTextEditorId();

        await TinyMceRender({
            selector: `#${this.textEditorId}`,
            plugins: ["lists", "charmap", "anchor", "searchreplace", "fullscreen", "wordcount"],
            toolbar: "bold italic underline | bullist numlist outdent indent | undo redo | formatselect",
            menubar: false,
            readonly: this._graded,
            promotion: false,
            skin: false,
            // eslint-disable-next-line camelcase
            content_css: false,

            setup: (editor) => {
                editor.on("input", this.saveEditorContent(editor));
                editor.on("change", this.saveEditorContent(editor));
            },
        });
    },

    beforeUnmount() {
        const editor = this.getEditor();
        if (editor) {
            editor.remove();
        }
    },

    provide() {
        return {
            inputtedValue: this.inputtedValue,
        };
    },
};
</script>

<template>
    <QuestionBlock
        :question-number="questionNumber"
        :block-id="clientId"
        @reset-answer="resetAnswer"
    >
        <slot
            :inputted-value="inputtedValue"
            :answer="answer"
            :graded="_graded"
        />
    </QuestionBlock>
</template>
