<script lang="ts" setup>
import { LabelProps, useLabelConvertor } from "@/composables/useLabelConvertor";
import { computed } from "~/vue";

const props = defineProps({
    ...LabelProps,
});

const { getLabel } = useLabelConvertor(props);

const label = computed(() => getLabel(props.index));
</script>

<template>
    <div
        class="drag-target mb-2 ml-2 flex cursor-grab items-center justify-center truncate rounded bg-default p-1 shadow-outline"
        :data-label="index + 1"
    >
        <span class="fa-stack fa-lg handle">
            <i class="fa fa-ellipsis-v" />

            <i class="fa fa-ellipsis-v" />
        </span>

        <span class="answer-label mr-2">{{ label }}</span>

        <div class="flex-1">
            <slot />
        </div>
    </div>
</template>
