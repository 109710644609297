import { getColorClassName } from "@/components/TestTaking/Blocks/Core/functions";
import { computed, toRef, useAttrs } from "vue";
import classNames from "classnames";

export default function hasBackgroundColor(attributes: object | null = null) {
    const attrs = toRef(attributes ?? useAttrs());

    const hasCustomBackground = computed(() => !!attrs.value?.style?.color?.background);

    const hasBackground = computed(() => hasCustomBackground.value || !!attrs.value?.backgroundColor);

    const backgroundStyle = computed(() => {
        if (!hasCustomBackground.value) {
            return {};
        }

        return {
            backgroundColor: attrs.value?.style?.color?.background,
        };
    });

    const backgroundClass = computed(() =>
        classNames({
            "has-background": hasBackground.value,
            [getColorClassName("background-color", attrs.value?.backgroundColor)]: attrs.value?.backgroundColor,
        }),
    );

    return {
        hasBackground,
        backgroundStyle,
        backgroundClass,
    };
}
