<script>
import CText from "@/components/Form/CText.vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import ConfirmPassword from "./ConfirmPassword.vue";

export default {
    components: { BtnPrimary, CText, ConfirmPassword },
    props: {
        user: null,
    },
    data() {
        return {
            requiresConfirmation: false,
            twoFactorEnabled: false,
            enabling: false,
            confirming: false,
            disabling: false,
            qrCode: false,
            setupKey: false,
            recoveryCodes: [],
            confirmationForm: {
                code: "",
                error: "",
            },
        };
    },
    created() {
        this.requiresConfirmation = this.user.two_factor_confirming;
        this.twoFactorEnabled = this.user.two_factor_enabled;
    },
    methods: {
        enableTwoFactorAuthentication() {
            this.enabling = true;

            axios
                .post("/user/two-factor-authentication")
                .then(() => {
                    this.twoFactorEnabled = true;
                    this.confirming = true;
                    this.showQrCode();
                    this.showSetupKey();
                    this.showRecoveryCodes();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.enabling = false;
                });
        },
        showQrCode() {
            return axios
                .get("/user/two-factor-qr-code")
                .then((response) => {
                    this.qrCode = response.data.svg;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        showSetupKey() {
            return axios.get("/user/two-factor-secret-key").then((response) => {
                this.setupKey = response.data.secretKey;
            });
        },
        showRecoveryCodes() {
            return axios.get("/user/two-factor-recovery-codes").then((response) => {
                this.recoveryCodes = response.data;
            });
        },
        confirmTwoFactorAuthentication() {
            axios
                .post("/user/confirmed-two-factor-authentication", this.confirmationForm)
                .then(() => {
                    this.confirming = false;
                    this.qrCode = null;
                    this.setupKey = null;
                })
                .catch((error) => {
                    console.log(error.response);
                    this.confirmationForm.error = error.response.data.errors.code[0];
                });
        },
        regenerateRecoveryCodes() {
            axios.post("/user/two-factor-recovery-codes").then(() => this.showRecoveryCodes());
        },
        disableTwoFactorAuthentication() {
            this.disabling = true;
            axios
                .delete("/user/two-factor-authentication")
                .then(() => {
                    this.qrCode = "";
                    this.twoFactorEnabled = false;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.disabling = false;
                    this.confirming = false;
                });
        },
    },
};
</script>

<template>
    <div class="card flex h-full flex-col items-stretch">
        <div class="flex flex-1 flex-col items-start justify-between">
            <div class="mt-3 w-full flex-grow space-y-4">
                <h3 v-if="twoFactorEnabled && !confirming" class="text-lg font-medium text-gray-900">
                    You have <b>enabled</b> two factor authentication.
                </h3>

                <h3 v-else class="text-lg font-medium text-gray-900">
                    You have <b>not enabled</b> two factor authentication.
                </h3>

                <p v-if="!qrCode" class="mt-3 max-w-xl">
                    When two factor authentication is enabled, you will be prompted for a secure, random token during
                    authentication. You may retrieve this token from your phone's Google Authenticator application.
                </p>

                <div v-if="twoFactorEnabled">
                    <div v-if="qrCode">
                        <div class="mt-4 max-w-xl text-sm text-gray-600">
                            <p v-if="confirming" class="font-semibold">
                                To finish enabling two factor authentication, scan the following QR code using your
                                phone's authenticator application or enter the setup key and provide the generated OTP
                                code.
                            </p>

                            <p v-else>
                                Two factor authentication is now enabled. Scan the following QR code using your phone's
                                authenticator application or enter the setup key.
                            </p>
                        </div>

                        <div class="mt-4" v-html="qrCode" />

                        <div class="mt-4 max-w-xl text-sm text-gray-600" v-if="setupKey">
                            <p class="font-semibold">Setup Key: <span v-html="setupKey"></span></p>
                        </div>

                        <div v-if="confirming" class="mt-4">
                            <CText
                                label="Code"
                                id="code"
                                v-model="confirmationForm.code"
                                placeholder="Enter generated OTP code here..."
                                name="code"
                                class="mt-1 block"
                                autofocus
                            />

                            <p class="text-red-700">{{ confirmationForm.error }}</p>
                        </div>
                    </div>

                    <div v-if="recoveryCodes.length > 0 && !confirming">
                        <div class="mt-4 max-w-xl text-sm text-gray-600">
                            <p class="font-semibold">
                                Store these recovery codes in a secure password manager. They can be used to recover
                                access to your account if your two factor authentication device is lost.
                            </p>
                        </div>

                        <div class="mt-4 grid max-w-xl gap-1 rounded-lg bg-gray-100 px-4 py-4 font-mono text-sm">
                            <div v-for="code in recoveryCodes" :key="code">
                                {{ code }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Confirm password modals -->
            <div class="mt-2 w-full text-center">
                <div v-if="!twoFactorEnabled">
                    <ConfirmPassword @confirmed="enableTwoFactorAuthentication">
                        <BtnPrimary
                            type="button"
                            class="mx-auto mt-8 w-full rounded-[0.25rem] text-lg xl:w-auto xl:px-12"
                            :class="{ 'opacity-25': enabling }"
                            :disabled="enabling"
                        >
                            Enable
                        </BtnPrimary>
                    </ConfirmPassword>
                </div>

                <div v-else>
                    <ConfirmPassword v-if="confirming" @confirmed="confirmTwoFactorAuthentication">
                        <BtnPrimary
                            type="button"
                            class="mx-auto mt-8 w-full rounded-[0.25rem] text-lg xl:w-auto xl:px-12"
                            :class="{ 'opacity-25': enabling }"
                            :disabled="enabling"
                        >
                            Confirm
                        </BtnPrimary>
                    </ConfirmPassword>

                    <ConfirmPassword
                        v-if="recoveryCodes.length > 0 && !confirming"
                        @confirmed="regenerateRecoveryCodes"
                    >
                        <BtnPrimary
                            type="button"
                            class="mx-auto mt-8 w-full rounded-[0.25rem] text-lg xl:w-auto xl:px-12"
                            :class="{ 'opacity-25': confirming }"
                            :disabled="confirming"
                        >
                            Regenerate Recovery Codes
                        </BtnPrimary>
                    </ConfirmPassword>

                    <ConfirmPassword v-if="recoveryCodes.length === 0 && !confirming" @confirmed="showRecoveryCodes">
                        <BtnPrimary
                            type="button"
                            class="mx-auto mt-8 w-full rounded-[0.25rem] text-lg xl:w-auto xl:px-12"
                            :class="{ 'opacity-25': confirming }"
                            :disabled="confirming"
                        >
                            Show Recovery Codes
                        </BtnPrimary>
                    </ConfirmPassword>

                    <ConfirmPassword v-if="confirming" @confirmed="disableTwoFactorAuthentication">
                        <BtnPrimary
                            type="button"
                            class="mx-auto ml-2 mt-4 w-full rounded-[0.25rem] text-lg lg:mt-8 xl:w-auto xl:px-12"
                            :class="{ 'opacity-25': disabling }"
                            :disabled="disabling"
                        >
                            Cancel
                        </BtnPrimary>
                    </ConfirmPassword>

                    <ConfirmPassword v-if="!confirming" @confirmed="disableTwoFactorAuthentication">
                        <BtnPrimary
                            type="button"
                            class="mx-auto ml-2 mt-8 w-full rounded-[0.25rem] text-lg xl:w-auto xl:px-12"
                            :class="{ 'opacity-25': disabling }"
                            :disabled="disabling"
                        >
                            Disable
                        </BtnPrimary>
                    </ConfirmPassword>
                </div>
            </div>
        </div>
    </div>
</template>
