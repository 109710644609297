<template>
    <span class="px-2 py-0 bg-blue-300 border border-blue-400 text-white font-light rounded-full text-xs m-1 inline-block"><slot></slot> </span>
</template>

<script>
export default {
    name: "Tag"
}
</script>

<style scoped>

</style>
