import NProgress from "nprogress";

import _ from "lodash";

window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";

window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;

if (window.resource_code) {
    window.axios.defaults.headers.common.Authorization = `Bearer ${window.resource_code}`;
}

/**
 * Echo exposes an expressive Api for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

import noUiSlider from "nouislider";

window.noUiSlider = noUiSlider;
// import Vue from 'vue';
//
// Vue.filter("moment", (date) => window.moment(date).format("M/D/YYYY h:mm a"));
// Vue.filter("timezone", (str) => {
//     const char = str.indexOf("?") > -1 ? "&" : "?";
//     const timezone = window.moment.tz(window.moment.tz.guess()).zoneAbbr();
//     return `${str}${char}timezone=${timezone}`;
// });

window.NProgress = NProgress;

NProgress.configure({
    showSpinner: false,
});
