<script setup>
import { computed, ref, useAttrs } from "vue";
// MathLive imports
// MathfieldElement import is required, even though it isnot used directly here.
import { MathfieldElement } from "mathlive";
import "~/mathlive/dist/mathlive-fonts.css";
import VueMathField from "~/mathlive/dist/vue-mathlive.mjs";

const props = defineProps({
    align: {
        type: String,
        default: "left",
        validator: (value) => ["left", "center", "right"].includes(value),
    },
    mathJs: {
        type: String,
        required: true,
    },
});

// making a const just so that the import shows as used.
const mfe = MathfieldElement;

const mathField = ref(null);

const justifyClass = computed(() => {
    switch (props.align) {
        case "left":
            return "justify-start";
        case "right":
            return "justify-end";
        default:
            return "justify-center";
    }
});

const options = {
    textToSpeechRules: "",
    textToSpeechRulesOptions: {
        domain: "mathspeak", // 'mathspeak' or 'chromevox' or 'clearspeak'
        style: "default", // mathspeak-default, mathspeak-brief, mathspeak-sbrief, chromevox-short, chromevox-default
        // domain: 'chromevox', // mathspeak or 'chromevox'
        // ruleset: 'chromevox-default',
        // mathspeak-default, mathspeak-brief, mathspeak-sbrief, chromevox-short, chromevox-default, chromevox-alternative
    },
    textToSpeechMarkup: "ssml", // 'ssml' or 'mac' or ''
    speechEngine: "amazon", // 'amazon' or 'local'
};

const speak = () => {
    const field = mathField.value;

    field.executeCommand(["speak", "all", { withHighlighting: true }]);
};
</script>

<template>
    <div
        class="flex"
        :class="justifyClass"
    >
        <span class="equation-block-readonly">
            <VueMathField
                read-only
                ref="mathField"
            >
                {{ mathJs }}
            </VueMathField>
        </span>

        <button
            @click="speak()"
            class="ml-2 inline-block cursor-pointer align-top"
        >
            <span class="far fa-play-circle equation-block-playbutton"></span>
        </button>
    </div>
</template>
