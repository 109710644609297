<script lang="ts" setup>
import { SOLID_COLOR_CLASS } from "@/components/TestTaking/Blocks/Core/constants";
import { getColorClassName } from "@/components/TestTaking/Blocks/Core/functions";
import QuoteBlock from "@/components/TestTaking/Blocks/Core/QuoteBlock.vue";
import { computed } from "vue";
import trimEnd from "lodash/trimEnd";

const props = defineProps({
    mainColor: {
        type: String,
        default: "",
    },
    customMainColor: {
        type: String,
        default: "",
    },
    textColor: {
        type: String,
        default: "",
    },
    customTextColor: {
        type: String,
        default: "",
    },
    value: {
        type: String,
        required: true,
    },
    citation: {
        type: String,
        default: "",
    },
    className: {
        type: String,
        default: "",
    },
});

const isSolidColorStyle = computed(() => props.className.includes(SOLID_COLOR_CLASS));

const quoteContent = computed(() => trimEnd(props.value, "</p>").replaceAll("<p>", "").split("</p>"));

const backgroundClass = computed(() => getColorClassName("background-color", props.mainColor));

const figureClasses = computed(() => ({
    [props.className]: props.className,
    "has-background": backgroundClass.value || props.customMainColor,
    [backgroundClass.value]: backgroundClass.value,
}));

const figureStyles = computed(() => {
    if (isSolidColorStyle.value) {
        return {
            backgroundColor: backgroundClass.value ? undefined : props.customMainColor,
        };
    }

    if (props.customMainColor) {
        return {
            borderColor: props.customMainColor,
        };
    }
});

const blockquoteTextColorClass = computed(() => getColorClassName("color", props.textColor));
const blockquoteClasses = computed(() => ({
    "has-text-color": props.textColor || props.customTextColor,
    [blockquoteTextColorClass.value]: (props.textColor || props.customTextColor) && blockquoteTextColorClass.value,
}));

const blockquoteStyles = computed(() =>
    blockquoteTextColorClass.value ? undefined : { color: props.customTextColor },
);
</script>

<template>
    <figure
        class="wp-block-pullquote"
        :class="figureClasses"
        :style="figureStyles"
    >
        <QuoteBlock
            :value="value"
            :citation="citation"
            :class="blockquoteClasses"
            :style="blockquoteStyles"
        />
    </figure>
</template>
