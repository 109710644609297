// eslint-disable import/prefer-default-export
import kebabCase from "lodash/kebabCase";
import { POSITION_CLASSNAMES } from "./constants";

export function backgroundImageStyles(url: string | undefined) {
    return url ? { backgroundImage: `url(${url})` } : {};
}

export function dimRatioToClass(ratio: number | undefined): string | null {
    return ratio === 0 || ratio === 50 || !ratio ? null : `has-background-dim-${10 * Math.round(ratio / 10)}`;
}

export function getGradientClass(gradientSlug: string) {
    return `has-${gradientSlug}-gradient-background`;
}

export function getColorClassName(colorContextName: string | undefined, colorSlug: string | undefined) {
    if (!colorContextName || !colorSlug) {
        return undefined;
    }

    return "has-".concat(kebabCase(colorSlug), "-").concat(colorContextName);
}

/**
 * Checks of the contentPosition is the center (default) position.
 *
 * @param {string} contentPosition The current content position.
 * @return {boolean} Whether the contentPosition is center.
 */
export function isContentPositionCenter(contentPosition: string | undefined) {
    return !contentPosition || contentPosition === "center center" || contentPosition === "center";
}

/**
 * Retrieves the className for the current contentPosition.
 * The default position (center) will not have a className.
 *
 * @param {string} contentPosition The current content position.
 * @return {string} The className assigned to the contentPosition.
 */
export function getPositionClassName(contentPosition: string | undefined) {
    /*
     * Only render a className if the contentPosition is not center (the default).
     */
    if (isContentPositionCenter(contentPosition) || !contentPosition) return "";

    return isContentPositionCenter(contentPosition) ? "" : POSITION_CLASSNAMES[contentPosition];
}
