<script>
import Sortable from "sortablejs";
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";
import flatten from "lodash/flatten";
import get from "lodash/get";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";
import forIn from "lodash/forIn";
import reduce from "lodash/reduce";

export default {
    components: { QuestionBlock },
    mixins: [Question],

    data() {
        return {
            answer: {},
            dropTargets: {},
            hovering: "",
            sortable: null,
        };
    },

    computed: {
        defaultAnswer() {
            return {};
        },

        usedAnswers() {
            return flatten(Object.values(this.answer));
        },

        answerState() {
            return (location, bypassValueCheck = false) => {
                if (!this._graded || (/\d+-\d+/.test(location) && !bypassValueCheck)) {
                    return "";
                }

                const question = this._question(this.clientId);
                const hasAmbiguousColumns = get(question, "additional_attributes.ambiguousColumns", false);
                const hasAmbiguousRows = get(question, "additional_attributes.ambiguousRows", false);
                const submittedAnswer = this._submittedAnswerValue(this.clientId);
                const correctAnswer = this._correctAnswer(this.clientId);

                if (
                    correctAnswer === null ||
                    submittedAnswer === null ||
                    correctAnswer === undefined ||
                    submittedAnswer === undefined
                ) {
                    return "";
                }

                if (Object.keys(correctAnswer).indexOf(location) === -1) {
                    return "";
                }

                if (hasAmbiguousColumns && hasAmbiguousRows) {
                    if (Object.values(correctAnswer).indexOf(submittedAnswer[location]) > -1) {
                        return "correct";
                    }
                    return "incorrect";
                }
                if (hasAmbiguousColumns || hasAmbiguousRows) {
                    const answerGroups = reduce(
                        correctAnswer,
                        (carry, answer, loc) => {
                            const [row, column] = loc.split("-");
                            const key = hasAmbiguousColumns ? column : row;

                            if (!Array.isArray(carry[key])) {
                                carry[key] = [];
                            }

                            carry[key].push(answer);

                            return carry;
                        },
                        {},
                    );

                    const [row, column] = location.split("-");

                    if (answerGroups[hasAmbiguousColumns ? column : row].indexOf(submittedAnswer[location]) > -1) {
                        return "correct";
                    }
                    return "incorrect";
                }

                return correctAnswer[location] === submittedAnswer[location] ? "correct" : "incorrect";
            };
        },
    },

    methods: {
        selectedValue(location, label) {
            if (this._graded) {
                return;
            }

            this.answer[location] = label;

            this.dropTargets[location].options.disabled = true;

            this.saveAnswer();
        },

        hidePlaceholder(location) {
            return this.hovering === location || (this.answer[location] && this.answer[location].length > 0);
        },

        isUsed(label) {
            return this.usedAnswers.indexOf(label) > -1;
        },

        resetSuccess() {
            if (!this.$refs.questionWrapper) {
                return;
            }

            const dragTargets = this.$refs.questionWrapper.querySelector(".drag-targets");
            const dropTargets = this.$refs.questionWrapper.querySelector(".drop-targets");

            const previousAnswers = dropTargets.querySelectorAll(".cell-wrapper .drag-target");

            each(previousAnswers, (answer) => {
                const label = get(answer, "dataset.label");

                dragTargets.querySelector(`[data-label="${label}"]`).style.display = null;

                const location = get(answer, "parentNode.dataset.index");
                this.dropTargets[location].options.disabled = false;

                answer.remove();
            });
        },
    },

    provide() {
        return {
            hidePlaceholder: this.hidePlaceholder,
        };
    },

    mounted() {
        if (!this.$refs.questionWrapper) {
            return;
        }

        this.sortable = new Sortable(this.$refs.questionWrapper.querySelector(".drag-targets"), {
            group: {
                pull: "clone",
            },
            sort: false,

            // Element dragging ended
            onEnd: (/** Event */ evt) => {
                this.hovering = "";
            },
        });

        const dropTargets = this.$refs.questionWrapper.querySelectorAll(
            ".drop-targets .cell:not(.header) .cell-wrapper",
        );

        dropTargets.forEach((el) => {
            const location = get(el.dataset, "index");

            this.dropTargets[location] = new Sortable(el, {
                sort: false,
                group: {
                    name: location,
                    put: true,
                },

                // Element is dropped into the list from another list
                onAdd: (/** Event */ evt) => {
                    // Get label and remove non-alphanumeric chars from label
                    const label = get(evt, "item.dataset.label")?.replace(/\W/g, "");

                    // Hide the element in the original list
                    const original = evt.from.querySelector(`[data-label="${label}"]`);
                    if (original && original.style) {
                        original.style.display = "none";
                    }

                    this.selectedValue(location, label);
                },

                // Called when dragging element changes position
                onChange: (/** Event */ evt) => {
                    this.hovering = location;
                },
            });
        });

        if (!isEmpty(this.answer)) {
            setTimeout(() => {
                // When user navigates back to the question, no need to drop the answers again. Plus, ref is not available at this point.
                if (!this.$refs.questionWrapper) {
                    return;
                }

                const dragTargets2 = this.$refs.questionWrapper.querySelector(".drag-targets");
                const dropTargets2 = this.$refs.questionWrapper.querySelector(".drop-targets");

                forIn(this.answer, (label, location) => {
                    const dragTarget = dragTargets2.querySelector(`[data-label="${label}"]`);
                    const dropTarget = dropTargets2.querySelector(`[data-index="${location}"]`);

                    if (dragTarget && dropTarget) {
                        const clone = dragTarget.cloneNode(true);
                        dragTarget.style.display = "none";

                        dropTarget.appendChild(clone);
                        this.dropTargets[location].options.disabled = true;
                    }
                });
            }, 100);
        }
    },
};
</script>

<template>
    <div ref="questionWrapper">
        <QuestionBlock
            :question-number="questionNumber"
            :block-id="clientId"
            :ref="`question-${clientId}`"
            class="drag-and-drop-table-grid table-grid"
            @reset-answer="resetAnswer"
        >
            <slot
                :selected-value="selectedValue"
                :label-click="labelClick"
                :answer="answer"
                :graded="_graded"
                :answer-state="answerState"
                :is-used="isUsed"
                :hide-placeholder="hidePlaceholder"
            />
        </QuestionBlock>
    </div>
</template>
