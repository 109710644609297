<script>
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";

export default {
    components: { QuestionBlock },
    mixins: [Question],

    data() {
        return {
            answer: null,
            labelHovered: null,
            valueHovered: null,
        };
    },

    props: ["type", "labels", "start", "end", "step", "randomizeLabels"],

    computed: {
        defaultAnswer() {
            const labels = this.getLabels;
            const answer = {};

            labels.forEach((label, index) => {
                answer[this.getLabelKey(label)] = { value: parseFloat(this.start), order: index };
            });

            return answer;
        },

        numberOfLabels() {
            return this.labels.length;
        },

        numberOfRows() {
            return Math.ceil((this.getEnd - parseFloat(this.start)) / parseFloat(this.step));
        },

        getTopBorder() {
            return (value, needsExtraTop) => {
                if (needsExtraTop) {
                    return this.isRowHovered(value) ? "border-t-2" : "border-t";
                }

                return this.isRowHovered(value) ? "border-t" : "border-t-0";
            };
        },

        getDisplayValue() {
            return (yIndex) => this.getEnd - yIndex * parseFloat(this.step) + parseFloat(this.step);
        },

        getEnd() {
            return parseFloat(this.end) + ((parseFloat(this.end) - parseFloat(this.start)) % parseFloat(this.step));
        },

        getLabels() {
            if (this.randomizeLabels && !this._graded) {
                if (_.get(this, "submittedAnswer.answer")) {
                    console.log(this.submittedAnswer.answer);
                    const labels = Array(Object.keys(this.submittedAnswer.answer).length);

                    _.forEach(this.labels, (label, index) => {
                        const value = _.get(this.submittedAnswer, `answer.${this.getLabelKey(label)}`);
                        labels[parseInt(_.get(value, "order", index))] = label;
                    });

                    return labels;
                }

                return _.shuffle(this.labels);
            }

            return this.labels;
        },

        /*
         * Determine if the box is hovered or if the box is in the same column and less than the currently hovered box
         * @param label - the column that the box belongs to
         * @param value - the value of the box
         */
        isBoxHovered() {
            return (label, value) => {
                if (this.labelHovered === null || this.valueHovered === null) {
                    return false;
                }

                return this.labelHovered === label && this.valueHovered >= value;
            };
        },

        /*
         * Check if a box is selected
         * @param label - the column that the box belongs to
         * @param value - the value of the box
         * @param useCorrectAnswer - use the correct answer value or the user provided value. Defaults to false.
         */
        isBoxSelected() {
            return (label, value, useCorrectAnswer = false) => {
                const labelKey = this.getLabelKey(label);
                let answer = _.get(this.answer, `${labelKey}.value`);

                if (this._graded && useCorrectAnswer) {
                    answer = _.get(this._correctAnswer(this.clientId), labelKey);
                }

                return answer >= value;
            };
        },

        isRowHovered() {
            return (value) => this.valueHovered === value;
        },

        getBoxColor() {
            return (label, value, useCorrectAnswer = false) => {
                if (
                    this.isBoxSelected(label, value, useCorrectAnswer) &&
                    this.valueHovered !== null &&
                    this.labelHovered === label &&
                    this.valueHovered < value
                ) {
                    return "bg-primary-lighter opacity-75";
                }
                if (this.isBoxSelected(label, value, useCorrectAnswer) || this.isBoxHovered(label, value)) {
                    return "bg-primary-lighter";
                }

                return this._graded ? "bg-transparent" : "bg-primary-lighter opacity-25";
            };
        },

        getBorderColor() {
            return (label, value, useCorrectAnswer = false) => {
                if (this.isBoxSelected(label, value, useCorrectAnswer) || this.isBoxHovered(label, value)) {
                    return "border-primary-lighter";
                }

                return "border-gray-500";
            };
        },

        getGradingClass() {
            return (label, value) => {
                if (!this._graded) return "";
                if (!this._correctAnswer(this.clientId)) return "";

                const answer = this.answer[this.getLabelKey(label)].value;
                const isAnswerCorrect = this.isAnswerCorrect(label);

                if (value <= answer && isAnswerCorrect) {
                    return "correct";
                }
                if (value <= answer && !isAnswerCorrect) {
                    return "incorrect";
                }

                return "";
            };
        },

        isAnswerCorrect() {
            return (label) => {
                if (!this._correctAnswer(this.clientId)) return false;

                const labelKey = this.getLabelKey(label);
                const answer = this.answer[labelKey].value;
                const correctAnswer = parseFloat(this._correctAnswer(this.clientId)[labelKey]);

                return answer === correctAnswer;
            };
        },
    },

    methods: {
        getLabelKey(label) {
            return (label ?? "").replaceAll(" ", "_").toLowerCase();
        },

        selectedValue(event, label, value) {
            if (this._graded) return;

            const labelKey = this.getLabelKey(label);

            this.answer[labelKey].value = value;

            this.saveAnswer();
        },

        setHovering(label, value) {
            if (this._graded) {
                return;
            }

            this.labelHovered = label;
            this.valueHovered = value;
        },
    },

    created() {
        this.answer = { ...this.defaultAnswer };
    },
};
</script>

<template>
    <QuestionBlock
        :question-number="questionNumber"
        :block-id="clientId"
        @reset-answer="resetAnswer"
        class="wp-block-bar-graph bar-graph"
    >
        <slot
            :selected-value="selectedValue"
            :label-click="labelClick"
            :answer="answer"
            :graded="_graded"
            :answer-state="answerState"
        ></slot>

        <div
            class="mt-4 grid w-full"
            :class="[`grid-label-cols-${numberOfLabels + 1}`]"
        >
            <template v-for="yIndex in numberOfRows">
                <div
                    class="relative -mt-4 mb-4 mr-3 text-right"
                    :class="{ 'font-bold': isRowHovered(getDisplayValue(yIndex)) }"
                >
                    {{ getDisplayValue(yIndex) }}

                    <div
                        class="absolute -right-3 top-1/2 w-2 border border-gray-500"
                        :class="{ 'mt-1p': yIndex === 1 }"
                    ></div>
                </div>

                <div
                    class="flex justify-center"
                    :class="{ 'col-start-2 border-l-2 border-gray-500': index === 0 }"
                    v-for="(label, index) in getLabels"
                >
                    <div
                        class="h-full flex-1 border-b border-gray-500"
                        :class="[getTopBorder(getDisplayValue(yIndex), yIndex === 1)]"
                    ></div>

                    <div
                        class="h-full w-16 border"
                        :class="[
                            getBorderColor(label, getDisplayValue(yIndex)),
                            getTopBorder(getDisplayValue(yIndex), yIndex === 1),
                            !_graded ? 'cursor-pointer' : 'border-l-0 border-r-0',
                            getGradingClass(label, getDisplayValue(yIndex)),
                        ]"
                        @mouseenter="setHovering(label, getDisplayValue(yIndex))"
                        @mouseleave="setHovering(null, null)"
                        @click="selectedValue($event, label, getDisplayValue(yIndex))"
                    >
                        <div
                            class="h-full w-full"
                            :class="[
                                getBoxColor(label, getDisplayValue(yIndex)),
                                getGradingClass(label, getDisplayValue(yIndex)),
                            ]"
                        ></div>
                    </div>

                    <div
                        class="h-full w-4 border-b border-gray-500"
                        :class="[getTopBorder(getDisplayValue(yIndex), yIndex === 1)]"
                        v-if="_graded && !isAnswerCorrect(label)"
                    ></div>

                    <div
                        class="h-full w-16 border border-l-0 border-r-0"
                        :class="[
                            getBorderColor(label, getDisplayValue(yIndex), true),
                            getTopBorder(getDisplayValue(yIndex), yIndex === 1),
                        ]"
                        v-if="_graded && !isAnswerCorrect(label)"
                    >
                        <div
                            class="h-full w-full"
                            :class="[getBoxColor(label, getDisplayValue(yIndex), true)]"
                        ></div>
                    </div>

                    <div
                        class="h-full flex-1 border-b border-gray-500"
                        :class="[getTopBorder(getDisplayValue(yIndex), yIndex === 1)]"
                    ></div>
                </div>
            </template>

            <div :class="`flex w-full justify-between col-span-${labels.length} relative col-start-2 h-4`">
                <div class="absolute h-1p w-full border border-gray-500"></div>

                <div
                    class="mt-1p w-1p border border-gray-500"
                    v-for="label in getLabels"
                ></div>

                <div class="mt-1p w-1p border border-gray-500"></div>
            </div>

            <div
                :class="`flex justify-center ${index === 0 ? 'col-start-2' : ''}`"
                v-for="(label, index) in getLabels"
            >
                {{ label }}
            </div>
        </div>
    </QuestionBlock>
</template>
