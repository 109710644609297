<script lang="ts" setup>
import ImageMedia from "@/components/TestTaking/Blocks/Core/Media/ImageMedia.vue";
import { computed } from "vue";

const props = defineProps({
    align: {
        type: String,
        default: "",
    },
    alt: {
        type: String,
        default: "",
    },
    caption: {
        type: String,
        default: "",
    },
    height: {
        type: Number,
        default: null,
    },
    href: {
        type: String,
        default: null,
    },
    id: {
        type: Number,
        default: null,
    },
    isAlignedChildBlock: {
        type: Boolean,
        default: false,
    },
    linkClass: {
        type: String,
        default: "",
    },
    linkTarget: {
        type: String,
        default: "",
    },
    rel: {
        type: String,
        default: null,
    },
    sizeSlug: {
        type: String,
        default: "",
    },
    title: {
        type: String,
        default: "",
    },
    width: {
        type: Number,
        default: null,
    },
    url: {
        type: String,
        required: true,
    },
});

const newRel = computed(() => props.rel ?? undefined);

const classes = computed(() => ({
    "wp-block-image": !props.isAlignedChildBlock,
    [`align${props.align}`]: props.align,
    [`size-${props.sizeSlug}`]: props.sizeSlug,
    "is-resized": props.width || props.height,
}));
</script>

<template>
    <figure :class="classes">
        <template v-if="href">
            <a
                :class="linkClass"
                :href="href"
                :target="linkTarget"
                :rel="newRel"
            >
                <ImageMedia
                    :media-url="url"
                    :media-alt="alt"
                    :media-id="id"
                    :media-size-slug="sizeSlug"
                    :width="width"
                    :height="height"
                    :alt="alt"
                />
            </a>
        </template>

        <template v-else>
            <ImageMedia
                :media-url="url"
                :media-alt="alt"
                :media-id="id"
                :media-size-slug="sizeSlug"
                :width="width"
                :height="height"
                :alt="alt"
            />
        </template>

        <figcaption
            v-if="caption"
            v-html="caption"
        />
    </figure>
</template>
