<script lang="ts" setup>
import { getColorClassName } from "@/components/TestTaking/Blocks/Core/functions";
import { computed } from "vue";

const props = defineProps({
    className: {
        type: String,
        default: null,
    },

    color: {
        type: String,
        default: null,
    },

    customColor: {
        type: String,
        default: null,
    },
});

const classNames = computed(() => {
    // the hr support changing color using border-color, since border-color
    // is not yet supported in the color palette, we use background-color
    const backgroundClass = getColorClassName("background-color", props.color);
    // the dots styles uses text for the dots, to change those dots color is
    // using color, not backgroundColor
    const colorClass = getColorClassName("color", props.color);

    return {
        [props.className]: props.className,
        "has-text-color has-background": props.color || props.customColor,
        [backgroundClass]: backgroundClass,
        [colorClass]: colorClass,
    };
});
</script>

<template>
    <hr
        class="wp-block-separator"
        :class="classNames"
    />
</template>
