<script lang="ts" setup>
import { getColorClassName } from "@/components/TestTaking/Blocks/Core/functions";
import { computed } from "vue";

const props = defineProps({
    body: {
        type: Array,
        default: () => [],
    },

    caption: {
        type: String,
        default: "",
    },

    foot: {
        type: Array,
        default: () => [],
    },

    head: {
        type: Array,
        default: () => [],
    },

    hasFixedLayout: {
        type: Boolean,
        default: false,
    },

    backgroundColor: {
        type: String,
        default: "",
    },
});

const backgroundClass = computed(() => getColorClassName("background-color", props.backgroundColor));

const tableClassNames = computed(() => ({
    [backgroundClass.value]: props.backgroundColor,
    "has-fixed-layout": props.hasFixedLayout,
    "has-background": !!backgroundClass.value,
}));
</script>

<template>
    <figure class="wp-block-table">
        <table :class="tableClassNames">
            <thead v-if="head.length">
                <tr
                    v-for="(row, index) in head"
                    :key="index"
                >
                    <Component
                        v-for="({ content, tag, scope, align }, cellIndex) in row.cells"
                        :is="tag"
                        :key="cellIndex"
                        :class="{
                            [`has-text-align-${align}`]: align,
                        }"
                        :data-align="align"
                        :scope="scope"
                        v-html="content"
                    />
                </tr>
            </thead>

            <tbody v-if="body.length">
                <tr
                    v-for="(row, index) in body"
                    :key="index"
                >
                    <Component
                        v-for="({ content, tag, scope, align }, cellIndex) in row.cells"
                        :is="tag"
                        :key="cellIndex"
                        :class="{
                            [`has-text-align-${align}`]: align,
                        }"
                        :data-align="align"
                        :scope="scope"
                        v-html="content"
                    />
                </tr>
            </tbody>

            <tfoot v-if="foot.length">
                <tr
                    v-for="(row, index) in foot"
                    :key="index"
                >
                    <Component
                        v-for="({ content, tag, scope, align }, cellIndex) in row.cells"
                        :is="tag"
                        :key="cellIndex"
                        :class="{
                            [`has-text-align-${align}`]: align,
                        }"
                        :data-align="align"
                        :scope="scope"
                        v-html="content"
                    />
                </tr>
            </tfoot>
        </table>

        <figcaption
            v-if="caption"
            v-html="caption"
        />
    </figure>
</template>
