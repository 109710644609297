<script>
import Swal from "sweetalert2";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import XMark from "@/components/Icons/XMark.vue";
import BtnDanger from "@/components/Global/BtnDanger.vue";
import FloppyDisk from "@/components/Icons/FloppyDisk.vue";
import RotateIcon from "@/components/Icons/RotateIcon.vue";

export default {
    components: { RotateIcon, FloppyDisk, BtnDanger, XMark, BtnPrimary },
    data() {
        return {
            form: {
                class_name: "",
                student_name: "",
                student_number: "",
                student_email: "",
            },
            edit: false,
            emailSent: false,
            emailSending: false,
            emailSuccess: false,
            saving: false,
            dummy: "dummy.coursewave.com",
        };
    },

    props: {
        code: {},
    },

    computed: {
        isNotDummyAccount() {
            return (
                this.dummy === "" ||
                this.code.student_email === null ||
                this.code.student_email.indexOf(this.dummy) === -1
            );
        },
    },

    methods: {
        validateForm() {
            const errors = [];

            const mailFormat = /\S+@\S+\.\S+/;
            if (this.form.student_email && !this.form.student_email.match(mailFormat)) {
                errors.push("Invalid email");
            }
            if (!this.form.student_name) {
                errors.push("Student name is required");
            }

            return errors;
        },
        save() {
            const errors = this.validateForm();
            if (errors.length > 0) {
                Swal.fire({
                    title: "Invalid information",
                    html: errors.join("<br/>"),
                    icon: "info",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Ok",
                });
                return null;
            }

            this.saving = true;
            const url = `/resources/${this.code.resource_id}/codes/${this.code.code}`;
            axios
                .patch(url, this.form)
                .then(({ data }) => {
                    console.log(data);
                    const newData = JSON.parse(JSON.stringify(this.code));
                    newData.class_name = this.form.class_name;
                    newData.student_name = this.form.student_name;
                    newData.student_number = this.form.student_id;
                    newData.student_email = this.form.student_email;
                    this.$emit("update", newData);
                    this.edit = false;
                })
                .catch((errors) => {
                    console.error(errors);
                    Swal.fire({
                        title: "Error",
                        html: "Failed to save code. Please contact us for further assistance.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Ok",
                    });
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        sendResourceCodeEmail() {
            if (this.emailSent || this.emailSending) {
                return;
            }

            this.emailSending = true;

            axios
                .post(route("resource-code.email", { resourceCode: this.code.code }))
                .then(() => {
                    this.emailSent = true;

                    this.emailSuccess = true;
                })
                .catch(({ response }) => {
                    let text = "An unknown error has occurred. Please try again later.";

                    if (response.status === 400) {
                        text =
                            "This student does not have an email. Please add an email for this student and try again.";
                    }

                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text,
                    });
                })
                .finally(() => {
                    this.emailSending = false;
                });
        },

        toggleArchive() {
            this.$emit("toggle-archive", this.code);
        },
    },

    created() {
        this.form.class_name = this.code.class_name;
        this.form.student_name = this.code.student_name;
        this.form.student_id = this.code.student_number; // Student_id = users->student_id (String). Not user ID.

        if (this.isNotDummyAccount) {
            this.form.student_email = this.code.student_email;
        }
    },

    watch: {
        emailSuccess(value) {
            if (value) {
                setTimeout(() => {
                    this.emailSuccess = false;
                }, 3000);
            }
        },
    },
};
</script>

<template>
    <div
        class="hover:bg-blue group block w-full border border-b-0 px-4 py-3 first:rounded-t-lg last:rounded-b-lg last:border-b"
    >
        <div class="lg:flex lg:align-top">
            <div class="lg:w-[12%] lg:flex-auto">
                <span class="col-form-label font-bold lg:font-normal">{{ code.code }}</span>

                <p class="text-xs font-light lg:text-sm">
                    Exp:
                    {{ code.expires_on ? new Date(code.expires_on).toLocaleDateString() : "" }}
                </p>
            </div>

            <div
                class="lg:flex lg:w-[88%] lg:flex-auto"
                v-if="edit"
            >
                <div class="lg:flex lg:w-10/12 lg:flex-wrap lg:flex-nowrap">
                    <div class="w-full flex-none px-1 lg:min-h-[84px] lg:w-[30%] lg:align-top">
                        <input
                            type="text"
                            placeholder="Enter Class Name..."
                            class="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:shadow-outline focus:outline-none"
                            id="class_name"
                            name="class_name"
                            v-model="form.class_name"
                        />
                    </div>

                    <div class="w-full flex-none px-1 lg:min-h-[84px] lg:w-[33%] lg:align-top">
                        <input
                            type="text"
                            placeholder="Enter Student Name..."
                            class="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:shadow-outline focus:outline-none"
                            id="student_name"
                            name="student_name"
                            v-model="form.student_name"
                        />
                    </div>

                    <div class="w-full flex-none px-1 lg:min-h-[84px] lg:w-[37%] lg:align-top">
                        <input
                            type="email"
                            placeholder="Enter Student Email..."
                            class="mb-2 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:shadow-outline focus:outline-none"
                            id="email"
                            name="email"
                            v-model="form.student_email"
                        />

                        <input
                            type="string"
                            placeholder="Enter Student ID..."
                            class="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:shadow-outline focus:outline-none"
                            id="student_id"
                            name="student_id"
                            v-model="form.student_id"
                        />
                    </div>
                </div>

                <div class="justify-end lg:mt-0 lg:flex lg:w-2/12">
                    <div class="mt-2 text-center lg:mt-0">
                        <BtnPrimary
                            @click="save"
                            aria-label="Save changes"
                            :disabled="saving"
                            class="mr-2"
                        >
                            <template #icon>
                                <FloppyDisk v-if="!saving" />

                                <RotateIcon
                                    class="animate-spin"
                                    v-else
                                />
                            </template>
                        </BtnPrimary>

                        <BtnDanger
                            aria-label="Cancel edit"
                            @click="edit = false"
                            :disabled="saving"
                        >
                            <template #icon>
                                <XMark />
                            </template>
                        </BtnDanger>
                    </div>
                </div>
            </div>

            <div
                class="items-center lg:flex lg:w-[88%]"
                v-else
            >
                <div class="items-center lg:flex lg:w-10/12 lg:flex-wrap lg:flex-nowrap">
                    <div
                        class="w-full flex-none lg:min-h-[44px] lg:w-[30%] lg:align-top"
                        :class="!code?.class_name ? 'text-center text-gray-500' : ''"
                    >
                        <label for="class_name">
                            {{ code?.class_name ?? "--- No class ---" }}
                        </label>
                    </div>

                    <div
                        class="w-full flex-none lg:min-h-[44px] lg:w-[33%] lg:align-top"
                        :class="!code?.student_name ? 'text-center text-gray-500' : ''"
                    >
                        <label for="student_name">
                            {{ code.student_name ?? "--- No student ---" }}
                        </label>
                    </div>

                    <div class="w-full flex-none lg:min-h-[44px] lg:w-[37%]">
                        <label
                            for="student_email"
                            v-if="isNotDummyAccount"
                        >
                            {{ code.student_email }}
                        </label>

                        <p
                            class="text-xs text-gray-600 lg:text-sm"
                            v-if="code.student_number"
                        >
                            {{ code.student_number }}
                        </p>
                    </div>
                </div>

                <div class="mt-2 justify-end lg:mt-0 lg:flex lg:w-2/12">
                    <div
                        class="mr-1 rounded bg-green-300 px-4 py-2"
                        v-if="emailSuccess"
                    >
                        Email Sent
                    </div>

                    <BtnPrimary
                        @click="toggleArchive"
                        :aria-label="code.archived_at ? `Un-archive Student` : `Archive Student`"
                        data-balloon-pos="up"
                        class="mr-2"
                    >
                        <FontAwesomeIcon
                            v-if="code.archived_at"
                            :icon="['fas', 'user-plus']"
                        />

                        <FontAwesomeIcon
                            v-else
                            :icon="['fas', 'user-minus']"
                        />
                    </BtnPrimary>

                    <BtnPrimary
                        @click="sendResourceCodeEmail()"
                        :disabled="emailSent || emailSending"
                        v-if="code.email && this.isNotDummyAccount"
                        aria-label="Send login email"
                        data-balloon-pos="up"
                        class="mr-2"
                    >
                        <FontAwesomeIcon
                            :icon="this.emailSending ? 'spinner' : 'envelope'"
                            :spin="this.emailSending"
                        />
                    </BtnPrimary>

                    <BtnPrimary
                        @click="edit = true"
                        aria-label="Edit code"
                        data-balloon-pos="up"
                    >
                        <FontAwesomeIcon icon="pencil-alt" />
                    </BtnPrimary>
                </div>
            </div>
        </div>
    </div>
</template>
