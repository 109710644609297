<script setup>
import logoWhite from "@images/CourseWaveLogoWhite.svg";
import logoNoText from "@images/ABC_Logo_No_Text.svg";
import HeaderUser from "@/components/Global/HeaderUser.vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";

const routeHome = route("home");
</script>

<template>
    <header class="top-0 flex h-26 w-full items-center bg-primary p-4 text-white print:hidden">
        <div class="flex h-full w-full items-center justify-between">
            <div class="flex items-center">
                <a
                    :href="routeHome"
                    title="Home"
                    class=""
                >
                    <img
                        class="w-32 md:w-40"
                        :src="logoWhite"
                        alt="@yield('title', config('app.name'))"
                    />
                </a>

                <a
                    :href="routeHome"
                    title="Home"
                    class="ml-2 mr-1 mt-1 text-sm font-light tracking-widest md:mt-2 md:text-lg"
                    style="padding-top: 2px"
                >
                    <span class="text-lg md:text-xl">B</span>y
                </a>

                <a
                    :href="routeHome"
                    title="Home"
                    class="inline-flex"
                >
                    <img
                        class="w-8 md:w-10"
                        :src="logoNoText"
                        alt="@yield('title', config('app.name'))"
                    />
                </a>
            </div>

            <div class="inline-block max-w-fit flex-1 xl:max-w-none xl:max-w-xs">
                <slot name="center-header" />
            </div>

            <!--<div class="flex inline-flex items-center justify-end xl:w-1/4">-->
            <!--    <a :href="routeHome" class="float-right text-sm"> Dashboard </a>-->
            <!--</div>-->

            <HeaderUser v-if="$page.props.auth.user" />

            <BtnPrimary
                v-else
                type="link"
                :href="route('login')"
                class="mr-2 h-50p py-4"
                label-position="down"
            >
                <i class="fas fa-sign-in-alt fa-lg"></i>

                <span class="ml-1">Login</span>
            </BtnPrimary>
        </div>
    </header>
</template>
