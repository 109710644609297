<script>
import ModalWindow from "@/components/Global/ModalWindow.vue";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import CText from "@/components/Form/CText.vue";
import BtnDanger from "@/components/Global/BtnDanger.vue";
import BtnSecondary from "@/components/Global/BtnSecondary.vue";

export default {
    components: { BtnSecondary, BtnDanger, BtnPrimary, CText, ModalWindow },
    props: {
        title: {
            type: String,
            default: "Confirm Password",
        },
        content: {
            type: String,
            default: "For your security, please confirm your password to continue.",
        },
        button: {
            type: String,
            default: "Confirm",
        },
    },
    data() {
        return {
            confirmingPassword: false,
            passwordInput: null,
            form: {
                password: "",
                error: "",
                processing: false,
            },
        };
    },
    methods: {
        startConfirmingPassword() {
            axios.get("/user/confirmed-password-status").then((response) => {
                if (response.data.confirmed) {
                    this.$emit("confirmed");
                } else {
                    this.confirmingPassword = true;
                }
            });
        },
        confirmPassword() {
            this.form.processing = true;

            axios
                .post("/user/confirm-password", { password: this.form.password })
                .then((response) => {
                    console.log(response);
                    this.closeModal();
                    this.$emit("confirmed");
                })
                .catch((error) => {
                    console.log(error.response);
                    this.form.error = error.response.data.errors.password[0];
                })
                .finally(() => {
                    this.form.processing = false;
                });
        },
        closeModal() {
            this.confirmingPassword = false;
            this.form.password = "";
            this.form.error = "";
        },
    },
};
</script>

<template>
    <div class="lg:inline-block">
        <span @click="startConfirmingPassword">
            <slot />
        </span>

        <ModalWindow
            :show="confirmingPassword"
            @close="closeModal"
            header-classes="modal-header p-5 text-center"
            footer-classes="modal-footer border0-t px-5 pb-5 text-center"
        >
            <template #header>
                <h5 class="text-2xl uppercase">{{ title }}</h5>
            </template>

            {{ content }}
            <div class="mt-4">
                <CText
                    ref="passwordInput"
                    v-model="form.password"
                    type="password"
                    class="mt-1 block w-full"
                    placeholder="Password"
                    @keyup.enter="confirmPassword"
                    required
                />
                <!--                    <JetInputError :message="form.error" class="mt-2" />-->
                <p class="text-red-700">{{ form.error }}</p>
            </div>

            <template #footer>
                <div class="flex justify-end gap-x-2">
                    <BtnSecondary @click="closeModal"> Cancel </BtnSecondary>

                    <BtnPrimary
                        @click="confirmPassword"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                    >
                        {{ button }}
                    </BtnPrimary>
                </div>
            </template>
        </ModalWindow>
    </div>
</template>
