<!-- Usage: <c-toggle-switch v-model="publishedStatus" :input-value="true" /> -->

<script>
export default {
    name: "CToggleSwitch",
    props: {
        value: {
            type: [Array, String],
            required: true
        },
        inputValue: {
            type: [String, Boolean, Number],
            required: true
        },
    },
    emits: ['change', 'update:modelValue'],
    computed: {
        model: {
            get() {
                return this.inputValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
    },
    methods: {
        onChange(event) {
            this.$emit("change", event.target.value);
        },
    },
}
</script>

<template>
    <label class="switch">
        <input type="checkbox" :value="inputValue" v-model="model" @change="onChange"/>

        <span class="slider round font-light"></span>
    </label>
</template>

<style scoped>
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #5AA1E3;

}

input:focus + .slider {
    box-shadow: 0 0 1px #5AA1E3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
