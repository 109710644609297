<script lang="ts" setup>
import useHasBackground from "@/components/TestTaking/Blocks/Composables/HasBackground";
import { computed } from "vue";
import { useAttrs } from "~/vue";

const props = defineProps({
    align: {
        type: String,
        default: "",
    },
    content: {
        type: [String, null],
        required: true,
    },
    direction: {
        type: String,
        default: "",
    },
    dropCap: {
        type: Boolean,
        default: false,
    },
});

const classes = computed(() => ({
    "has-drop-cap": props.dropCap,
    [`has-text-align-${props.align}`]: props.align,
}));
</script>

<template>
    <p
        :class="classes"
        v-html="content"
    />
</template>
