<script setup>
import useButtonType from "@/composables/useButtonType";

const { buttonType } = useButtonType();
</script>

<template>
    <Component
        :is="buttonType"
        class="inline-flex items-center justify-center gap-x-2 rounded-md border-2 border-primary-lighter bg-transparent px-4 py-2 text-center text-base font-bold text-primary-lighter hover:border-primary hover:bg-primary-lighter hover:text-white focus:outline-none"
    >
        <span
            class="h-auto w-4"
            v-if="$slots.icon"
        >
            <slot name="icon" />
        </span>

        <slot></slot>
    </Component>
</template>

<style scoped></style>
