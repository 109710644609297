<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

const dialog = ref(null);

const show = defineModel("show");

const emit = defineEmits(["update:show", "close"]);

onMounted(() => {
    if (show.value) {
        dialog.value.showModal();
    }
});
watch(
    () => show.value,
    (newValue) => {
        if (newValue) {
            show.value = true;
            dialog.value.showModal();
        } else {
            show.value = false;
            dialog.value.close();
        }
    },
);

function handleClose() {
    show.value = false;
    emit("close");
}
</script>

<template>
    <dialog
        ref="dialog"
        @close="handleClose"
        class="flex-col gap-y-5 rounded-md px-5 py-5 shadow-lg backdrop:bg-black backdrop:opacity-25 open:flex"
    >
        <div
            class="text-gray-900"
            v-if="$slots.header"
        >
            <slot name="header"></slot>
        </div>

        <div
            class=""
            v-if="$slots.default"
        >
            <slot></slot>
        </div>

        <div
            v-if="$slots.footer"
            class="-mb-2"
        >
            <div class="-mx-5 mb-3 border-t"></div>

            <div class="flex justify-end text-right">
                <slot name="footer"></slot>
            </div>
        </div>
    </dialog>
</template>
