<script>
import Sortable from "sortablejs";
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";
import flatten from "lodash/flatten";
import get from "lodash/get";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";
import forIn from "lodash/forIn";

export default {
    components: {
        QuestionBlock,
    },
    mixins: [Question],

    data() {
        return {
            answer: {},
            dropTargets: {},
            hovering: "",
            sortable: null,
        };
    },

    computed: {
        defaultAnswer() {
            return {};
        },

        usedAnswers() {
            return flatten(Object.values(this.answer));
        },

        answerState() {
            return (location, bypassValueCheck = false) => {
                if (!this._graded || (/\d+-\d+/.test(location) && !bypassValueCheck)) {
                    return "";
                }

                const question = this._question(this.clientId);
                const submittedAnswer = this._submittedAnswerValue(this.clientId);
                const correctAnswer = this._correctAnswer(this.clientId);

                if (
                    correctAnswer === null ||
                    submittedAnswer === null ||
                    correctAnswer === undefined ||
                    submittedAnswer === undefined
                ) {
                    return "";
                }

                if (Object.keys(correctAnswer).indexOf(location) === -1) {
                    return "";
                }

                return correctAnswer[location] === submittedAnswer[location] ? "correct" : "incorrect";
            };
        },
    },

    methods: {
        selectedValue(location, label) {
            if (this._graded) {
                return;
            }

            this.answer[location] = label;

            this.dropTargets[location].options.disabled = true;

            this.saveAnswer();
        },

        hidePlaceholder(location) {
            return this.hovering === location || (this.answer[location] && this.answer[location].length > 0);
        },

        isUsed(label) {
            return this.usedAnswers.indexOf(label) > -1;
        },

        resetSuccess() {
            const dragTargets = this.$refs.blockWrapper.querySelector(".drag-targets");
            const dropTargets = this.$refs.blockWrapper.querySelector(".hotspot-map");

            const previousAnswers = dropTargets.querySelectorAll(".hotspot-target .drag-target");

            each(previousAnswers, (answer) => {
                const label = get(answer, "dataset.label");

                dragTargets.querySelector(`[data-label="${label}"]`).style.display = null;

                const location = get(answer, "parentNode.dataset.index");
                this.dropTargets[location].options.disabled = false;

                answer.remove();
            });
        },

        getCorrectAnswer(name) {
            if (!this._graded) {
                return "";
            }

            const correctAnswer = this._correctAnswer(this.clientId);
            if (correctAnswer === null || correctAnswer === undefined) {
                return "";
            }

            // When user click next/prev page, it failed to get the wrapper. Wait a second before getting the correct answer.
            // This is handle in HotspotTarget.vue
            if (!this.$refs.blockWrapper) {
                return "";
            }

            const dragTargets = this.$refs.blockWrapper.querySelector(".drag-targets");
            const dragTarget = dragTargets.querySelector(`[data-label="${correctAnswer[name]}"]`);

            if (dragTarget) {
                const clone = dragTarget.cloneNode(true);
                clone.style.display = "block";
                return clone.innerText.slice(1); // First char is the option number/letter. Remove it.
            }
            return correctAnswer[name];
        },
    },

    provide() {
        return {
            hidePlaceholder: this.hidePlaceholder,
            getCorrectAnswer: this.getCorrectAnswer,
        };
    },

    mounted() {
        // wp-block-group__inner-container only exist in older drag and drop questions.
        // If wp-block-group__inner-container exists, remove that div since it causes issues with the drag and drop.
        const elements = document.querySelectorAll(".wp-block-group__inner-container");
        if (elements.length > 0) {
            for (let i = 0; i < elements.length; i += 1) {
                const eleDragTarget = document.querySelectorAll(".drag-targets")[i];
                eleDragTarget.innerHTML = elements[i].innerHTML;
            }
        }

        this.sortable = new Sortable(this.$refs.blockWrapper.querySelector(".drag-targets"), {
            group: {
                pull: "clone",
            },
            sort: false,
            disabled: this._graded,

            // Element dragging ended
            onEnd: (/** Event */ evt) => {
                this.hovering = "";
            },
        });

        const dropTargets = this.$refs.blockWrapper.querySelectorAll(".hotspot-target");

        each(dropTargets, (el) => {
            const location = get(el.dataset, "index");

            this.dropTargets[location] = new Sortable(el, {
                sort: false,
                group: {
                    name: location,
                    put: true,
                },
                disabled: this._graded,

                // Element is dropped into the list from another list
                onAdd: (/** Event */ evt) => {
                    // Get label and remove non-alphanumeric chars from label
                    const label = get(evt, "item.dataset.label")?.replace(/\W/g, "");

                    // Hide the element in the original list
                    const original = evt.from.querySelector(`[data-label="${label}"]`);
                    if (original && original.style) {
                        original.style.display = "none";
                    }

                    this.selectedValue(location, label);
                },

                // Called when dragging element changes position
                onChange: (/** Event */ evt) => {
                    this.hovering = location;
                },
            });
        });

        if (!isEmpty(this.answer)) {
            const dragTargets = this.$refs.blockWrapper.querySelector(".drag-targets");
            const dropTargets = this.$refs.blockWrapper.querySelector(".hotspot-map");

            forIn(this.answer, (label, location) => {
                const dragTarget = dragTargets.querySelector(`[data-label="${label}"]`);
                const dropTarget = dropTargets.querySelector(`[data-index="${location}"]`);

                if (dragTarget) {
                    const clone = dragTarget.cloneNode(true);
                    dragTarget.style.display = "none";

                    dropTarget.appendChild(clone);
                    this.dropTargets[location].options.disabled = true;
                }
            });
        }
    },
};
</script>

<template>
    <div ref="blockWrapper">
        <QuestionBlock
            :question-number="questionNumber"
            :block-id="clientId"
            @reset-answer="resetAnswer"
            class="drag-and-drop-hotspot table-grid"
        >
            <slot
                :selected-value="selectedValue"
                :label-click="labelClick"
                :get-correct-answer="getCorrectAnswer"
                :answer="answer"
                :graded="_graded"
                :answer-state="answerState"
                :is-used="isUsed"
                :hide-placeholder="hidePlaceholder"
            />
        </QuestionBlock>
    </div>
</template>
