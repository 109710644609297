<template>
    <div>
        <span
            :class="classes"
            class="relative inline-flex items-center justify-center px-2 text-gray-600 border border-gray-300 copied"
            v-if="copied && this.side === 'left'">
                copied
            </span>

        <button class="px-1" @click="copySampleUrl" aria-label="Copy to clipboard" :data-balloon-pos="labelPosition">
            <font-awesome-icon icon="copy"></font-awesome-icon>

            <input type="text" ref="copy" :value="value" class="copy-field">
        </button>

        <span
            :class="classes"
            class="relative inline-flex items-center justify-center px-2 text-gray-600 border border-gray-300 copied"
            v-if="copied && this.side === 'right'">
                copied
            </span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            copied: false,
        };
    },

    props: {
        value: {
            type: String,
            default: ''
        },

        left: {
            type: Boolean,
            default: false,
        },

        right: {
            type: Boolean,
            default: false,
        },

        size: {
            type: String,
            default: 'sm',
        },

        labelPosition: {
            type: String,
            default: 'up'
        }
    },

    computed: {
        side() {
            if (this.right) {
                return 'right';
            }

            return 'left';
        },

        classes() {
            return {
                'text-sm': this.size === 'sm',
                'text-xs': this.size === 'xs',
            }
        }
    },

    methods: {
        copySampleUrl() {
            let sampleUrl = this.$refs.copy;

            sampleUrl.select();
            sampleUrl.setSelectionRange(0, 99999);
            document.execCommand('copy');

            this.copied = true;
        },
    },

    watch: {
        copied(newValue, oldValue) {
            if (newValue) {
                setTimeout(() => {
                    this.copied = false;
                }, 1000);
            }
        }
    }
};
</script>

<style>
.copy-field {
    position: absolute;
    left: -9999px
}
</style>
