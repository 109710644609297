export const COVER_MIN_HEIGHT = 50;
export const COVER_MAX_HEIGHT = 1000;
export const COVER_DEFAULT_HEIGHT = 300;
export const DEFAULT_MEDIA_SIZE_SLUG = "full";
export const DEFAULT_MEDIA_WIDTH = 50;

export const IMAGE_BACKGROUND_TYPE = "image";
export const VIDEO_BACKGROUND_TYPE = "video";

export const LINK_DESTINATION_NONE = "none";
export const LINK_DESTINATION_MEDIA = "file";
export const LINK_DESTINATION_ATTACHMENT = "post";

export const SOLID_COLOR_STYLE_NAME = "solid-color";
export const SOLID_COLOR_CLASS = `is-style-${SOLID_COLOR_STYLE_NAME}`;

export const POSITION_CLASSNAMES = {
    "top left": "is-position-top-left",
    "top center": "is-position-top-center",
    "top right": "is-position-top-right",
    "center left": "is-position-center-left",
    "center center": "is-position-center-center",
    center: "is-position-center-center",
    "center right": "is-position-center-right",
    "bottom left": "is-position-bottom-left",
    "bottom center": "is-position-bottom-center",
    "bottom right": "is-position-bottom-right",
};
