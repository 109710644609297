<script setup lang="ts">
import BtnSecondary from "@/components/Global/BtnSecondary.vue";
import Swal from "sweetalert2";
import { computed, onMounted, PropType, ref } from "vue";
import type Question from "@/types/Question";
import type User from "@/types/User";
import type Test from "@/types/Test";
import CText from "../Form/CText.vue";
import CSelect from "../Form/CSelect.vue";

const searchStudent = ref("");
const searchStandard = ref("");
const searchDok = ref("");
const standardDokLabels = ref([]);

const props = defineProps({
    resource: {
        type: Object,
        default: null,
    },
    test: {
        type: Object as PropType<Test>,
        default: null,
    },
    students: {
        type: Array as PropType<User[]>,
        default: null,
    },
    questions: {
        type: Array as PropType<Question[]>,
        default: null,
    },
    hasStandard: {
        type: Boolean,
        default: false,
    },
    hasDok: {
        type: Boolean,
        default: false,
    },
});

const filteredStudents = computed(() => {
    if (searchStudent.value) {
        const records = [];
        props.students.forEach((student) => {
            if (student.name && student.name.toLowerCase().includes(searchStudent.value.toLowerCase())) {
                records.push(student);
            }
        });
        return records;
    }
    return props.students;
});

const filteredQuestions = computed(() => {
    let records = props.questions;
    if (searchStandard.value || searchDok.value) {
        records = [];
    }

    props.questions.forEach((question) => {
        if (searchStandard.value && searchDok.value) {
            if (
                question.standard &&
                question.standard.toLowerCase().includes(searchStandard.value.toLowerCase()) &&
                question.dok &&
                question.dok.toLowerCase().includes(searchDok.value.toLowerCase())
            ) {
                records.push(question);
            }
        } else if (searchStandard.value) {
            if (question.standard && question.standard.toLowerCase().includes(searchStandard.value.toLowerCase())) {
                records.push(question);
            }
        } else if (searchDok.value) {
            if (question.dok && question.dok.toLowerCase().includes(searchDok.value.toLowerCase())) {
                records.push(question);
            }
        }
    });

    return records;
});
onMounted(() => {
    if (props.hasStandard) {
        standardDokLabels.value.push("Standard");
    }
    if (props.hasDok) {
        standardDokLabels.value.push("DOK");
    }
});

function doExport(type = "CSV") {
    if (props.hasStandard || props.hasDok) {
        Swal.fire({
            title: `Export as ${type}`,
            html: "Include standards and DOK in export?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).then((result) => {
            if (!result.dismiss || result.dismiss === "cancel") {
                const URL = route("teacher.test.all-scores-export", {
                    resource: props.resource,
                    test: props.test,
                    format: type.toLowerCase(),
                    exportStandardDok: result.isConfirmed,
                });
                window.location.href = URL;
            }
        });
    } else {
        const URL = route("teacher.test.all-scores-export", {
            resource: props.resource,
            test: props.test,
            format: type.toLowerCase(),
            exportStandardDok: 0,
        });
        window.location.href = URL;
    }
}
</script>

<template>
    <div>
        <div>
            <a :href="route('resources.show', { resource: resource.id })">
                <h4 class="text-lg leading-4 text-gray-500 hover:underline">{{ resource.title }}</h4>
            </a>

            <h3 class="w-full text-2xl">{{ test.title }}</h3>
        </div>

        <div
            v-if="students.length > 0"
            class="mt-2"
            :class="hasStandard ? 'mb-12' : 'mb-20'"
        >
            <BtnSecondary @click="doExport('CSV')">Export as CSV</BtnSecondary>

            <BtnSecondary
                @click="doExport('XLSX')"
                class="ml-2"
                >Export as XLSX
            </BtnSecondary>

            <div class="mt-4 grid grid-cols-1 gap-2 sm:grid-cols-3 md:w-3/4 lg:w-1/2">
                <div>
                    <CText
                        v-model="searchStudent"
                        placeholder="Filter students..."
                    />
                </div>

                <div v-if="hasStandard">
                    <CText
                        v-model="searchStandard"
                        placeholder="Filter standards..."
                    />
                </div>

                <div v-if="hasDok">
                    <CSelect
                        v-model="searchDok"
                        placeholder="Filter DOK..."
                        :options="{ '1': 'DOK 1', '2': 'DOK 2', '3': 'DOK 3', '4': 'DOK 4' }"
                    />
                </div>
            </div>
        </div>

        <table
            v-if="students.length > 0"
            id="all-scores"
            class="text-s rotate block table-auto overflow-auto whitespace-nowrap"
        >
            <thead>
                <tr v-show="hasStandard || hasDok">
                    <th class="rotate">
                        <div>
                            <span
                                ><b>{{ standardDokLabels.join(" / ") }}</b></span
                            >
                        </div>
                    </th>

                    <th
                        v-for="question in filteredQuestions"
                        :key="question.id"
                        class="rotate"
                    >
                        <div>
                            <span>
                                <span v-if="question.standard">{{ question.standard }}</span>

                                <span
                                    v-if="question.dok"
                                    class="text-gray-600"
                                    >(DOK {{ question.dok }})</span
                                >&nbsp;
                            </span>
                        </div>
                    </th>

                    <th></th>
                </tr>

                <tr>
                    <th class="border-r-2 px-4 py-0">Student Name</th>

                    <th
                        v-for="question in filteredQuestions"
                        :key="question.id"
                        class="border-r-2 px-4 py-0"
                    >
                        {{ question.label }}
                    </th>

                    <th>Score</th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="(student, index) in filteredStudents"
                    :key="index"
                    class="even:bg-gray-200"
                >
                    <td class="border border-gray-400 hover:bg-gray-400">
                        <a
                            v-if="student.id"
                            :href="route('teacher.student.show', [student.id])"
                            class="block px-4 py-1 hover:underline"
                        >
                            {{ student.name }}
                        </a>
                    </td>

                    <td
                        v-for="question in filteredQuestions"
                        class="border border-gray-400 text-center hover:bg-gray-400"
                        :key="question.id"
                    >
                        <a
                            :href="
                                route('teacher.submission.page', [
                                    student.submissionId,
                                    student.answers[question.id].pageNum,
                                ])
                            "
                            class="px-4 py-1"
                        >
                            <FontAwesomeIcon
                                v-if="student.answers[question.id].isCorrect"
                                icon="check"
                                class="text-sm text-green-700"
                            />

                            <FontAwesomeIcon
                                v-else
                                icon="times"
                                class="text-sm text-red-700"
                            />
                        </a>
                    </td>

                    <td
                        class="border border-gray-400 px-2 py-1 text-center"
                        :style="'background-color:#' + student.colorHex"
                    >
                        {{ student.grade }}%
                    </td>
                </tr>

                <tr key="-1">
                    <td class="border border-gray-400 px-4 py-1"></td>

                    <td
                        v-for="question in filteredQuestions"
                        :key="question.id"
                        class="border border-gray-400 px-2 py-1"
                    >
                        &nbsp;
                    </td>

                    <td class="border border-gray-400 px-2 py-1"></td>
                </tr>

                <tr
                    key="-2"
                    class="bg-gray-200"
                >
                    <td class="border border-gray-400 px-4 py-1">Average</td>

                    <td
                        v-for="question in filteredQuestions"
                        :key="question.id"
                        class="border border-gray-400 px-2 py-1 text-center text-sm"
                        :style="'background-color:#' + question.colorHex"
                    >
                        {{ question.average }}%
                    </td>

                    <td class="border border-gray-400 px-2 py-1 text-center"></td>
                </tr>
            </tbody>
        </table>

        <div
            v-else
            class="text-center"
        >
            No submission yet...
        </div>
    </div>
</template>
