<script>
import { mapGetters } from "vuex";
import "~/mathlive/dist/mathlive-fonts.css";
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";
import VueMathField from "~/mathlive/dist/vue-mathlive.mjs";
import keyboardElementary from "@/gutenberg/mathlive-keyboards/elementary.js";
import keyboardMiddleHigh from "@/gutenberg/mathlive-keyboards/middleHigh.js";
import keyboardScience from "@/gutenberg/mathlive-keyboards/science.js";

export default {
    components: { QuestionBlock, VueMathField },
    mixins: [Question],

    data() {
        return {
            answer: "",
            answerOriginal: "",
            correctAnswerMathfield: null,
            mathfield: null,
        };
    },

    props: {
        calculatorlevel: {
            default: "all",
        },
        answerplaceholder: {
            default: "",
        },
    },

    computed: {
        ...mapGetters({
            _isQuestionCorrect: "isQuestionCorrect",
            _resource: "resource",
        }),

        defaultAnswer() {
            return "";
        },

        classList() {
            return (showsCorrectAnswer = false) => {
                let classList = "equation-answer-math mt-2";

                if (this._graded) {
                    if (this._isQuestionCorrect(this.clientId)) {
                        classList += " correct";
                    } else {
                        classList += " incorrect";

                        if (showsCorrectAnswer) {
                            classList += " should-be-selected";
                        }
                    }
                }

                return classList;
            };
        },

        correctAnswer() {
            if (!this._graded) {
                return "";
            }

            return _.first(this._correctAnswer(this.clientId));
        },

        shouldDisplayCorrectAnswer() {
            if (!this._graded) {
                return false;
            }

            return !this._isQuestionCorrect(this.clientId);
        },
    },

    methods: {
        updateAnswer(newInput) {
            // Exit if mathField is not found
            if (!this.$refs.mathFieldStudentInput) {
                return;
            }

            // Only save the answer if it's different from what is already there
            const text = this.$refs.mathFieldStudentInput.getValue();

            // Compare the two answers
            if (this.answer !== text) {
                // The answer has changed so we need to see if it's different or if it's empty and should be reset
                if (text === "") {
                    // It's empty, so do a reset instead of saving
                    this.resetAnswer();
                } else {
                    // save the new answer
                    // Remove space charter from clicking the space bar and trim white space
                    this.answer = text.replace(/\\:/g, "").trim();
                    this.saveAnswer();
                }
            }
        },

        resetSuccess() {},

        addCustomKeyboard(mf) {
            if (this._graded) {
                return;
            }

            let customKeyboard = null;
            if (this._resource.keyboard.toLowerCase() == "science") {
                customKeyboard = keyboardScience;
            } else if (this._resource.keyboard.toLowerCase() == "elementary") {
                customKeyboard = keyboardElementary;
            } else if (this._resource.keyboard.toLowerCase() == "middlehigh") {
                customKeyboard = keyboardMiddleHigh;
            }

            if (!customKeyboard) {
                return;
            }

            mf.addEventListener("focus", () => {
                mathVirtualKeyboard.layouts = [
                    {
                        rows: customKeyboard.rows,
                        label: customKeyboard.label,
                    },
                ];
                mathVirtualKeyboard.visible = true;
            });
        },

        updateAnswerWithPlaceholder() {
            const newAnswer = this.getPlaceholderInputs();

            // Compare the two answers
            if (this.answer !== newAnswer) {
                // The answer has changed so we need to see if it's different or if it's empty and should be reset
                if (newAnswer === "") {
                    // It's empty, so do a reset instead of saving
                    this.resetAnswer();
                } else {
                    // save the new answer
                    this.answer = newAnswer;
                    this.saveAnswer();
                }
            }
        },

        getPlaceholderInputs() {
            const mathField = this.$refs.mathFieldStudentInput;
            let value = this.answerplaceholder;

            for (const placeholderId in mathField.placeholders) {
                const userInput = mathField.getPlaceholderField(placeholderId).getValue();
                if (userInput) {
                    value = value.replace(`\\placeholder[${placeholderId}]{}`, userInput);
                }
            }
            return value;
        },

        getPlaceholderInputsFromAnswer(placeholder, answer) {
            const blank = "#0";
            placeholder = placeholder.replace(/\\placeholder\[blank\d\]{}/g, blank); // Replace placerholder with #0
            answer = answer.replace(/\\placeholder\[blank\d\]{}/g, "");

            const separators = placeholder.split(blank);
            if (separators.length == 1) {
                return [answer];
            }

            const ary = [];
            let s = 0;
            for (let i = 0; i < separators.length - 1; i++) {
                s = answer.indexOf(separators[i], s) + separators[i].length;
                const e = answer.indexOf(separators[i + 1], s);

                if (s >= 0 && e > 0) {
                    ary.push(answer.substring(s, e));
                }
            }

            return ary;
        },

        waitForElm(selector) {
            return new Promise((resolve) => {
                if (document.querySelector(selector)) {
                    return resolve(document.querySelector(selector));
                }

                const observer = new MutationObserver((mutations) => {
                    if (document.querySelector(selector)) {
                        resolve(document.querySelector(selector));
                        observer.disconnect();
                    }
                });

                observer.observe(document.body, {
                    childList: true,
                    subtree: true,
                });
            });
        },

        setupPlaceholders() {
            if (!this._graded && this.answerplaceholder) {
                const mf = document.getElementById(this.id);
                // After upgraded to MathLive 0.95.5, mf.placeholders is undefined. Not sure if this is needed anymore.
                if (mf.placeholders) {
                    const aryAnswers = this.getPlaceholderInputsFromAnswer(this.answerplaceholder, this.answerOriginal);
                    const placeholders = Object.keys(mf.placeholders).sort();

                    // Loop through this mathfield placeholders (childMf).
                    for (let i = 0; i < placeholders.length; i++) {
                        const blankId = placeholders[i];
                        const childMf = mf.getPlaceholderField(blankId);
                        childMf.value = aryAnswers[i]; // Set placeholder value if any

                        this.addCustomKeyboard(childMf); // Link custom keyboard to placeholder
                        childMf.style.minWidth = "20px"; // Change default width to make it more square
                    }
                }
            }
        },
        generateUUID() {
            // Public Domain/MIT
            let d = new Date().getTime(); // Timestamp
            let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
                let r = Math.random() * 16; // random number between 0 and 16
                if (d > 0) {
                    // Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else {
                    // Use microseconds since page-load if supported
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
            });
        },
    },

    created() {
        this.id = `equation-answer-${this.generateUUID()}`;
    },

    mounted() {
        this.answerOriginal = this.answer;

        // Need to wait until MathLive element is fully rendered to insert placeholders. Otherwise, there will be unexpected behavior.
        this.waitForElm(`#${this.id}`).then((elm) => {
            if (this.answerplaceholder && !this._graded) {
                elm.value = this.answerplaceholder;
            }

            this.setupPlaceholders();
            this.addCustomKeyboard(elm);
        });

        // Setup the mathfield element
        const mfe = document.getElementById(this.id);
        if (mfe) {
            // Placeholder MathLive element
            mfe.addEventListener("placeholder-change", (ev) => {
                this.updateAnswerWithPlaceholder();
            });
        }
    },

    watch: {
        correctAnswer: {
            handler() {
                if (this._graded) {
                    // On retrieval of correct answer, run the mathfield after content has been updated
                    this.$nextTick(() => {
                        // this.correctAnswerMathfield = MathLive.makeMathField(`${this.id}-correct-answer`, this.getOptions);
                    });
                }
            },
            immediate: true,
        },
    },
};
</script>

<template>
    <QuestionBlock
        :question-number="questionNumber"
        :block-id="clientId"
        @reset-answer="resetAnswer"
    >
        <slot
            :answer="answer"
            :graded="_graded"
            :answer-state="answerState"
        />

        <div class="@md:flex-no-wrap my-3 flex max-w-full flex-row flex-wrap gap-4">
            <div
                class="w-full"
                v-if="!shouldDisplayCorrectAnswer"
            >
                <div
                    class="w-full"
                    :class="this.classList()"
                >
                    <!-- eslint-disable-next-line -->
                    <VueMathField
                        ref="mathFieldStudentInput"
                        class="equation-answer-settings-entry w-full"
                        :id="this.id"
                        :readonly="_graded ? true : false"
                        @change="updateAnswer"
                    >
                        {{ answer }}
                    </VueMathField>
                </div>
            </div>

            <div
                class="flex flex-col"
                v-else
            >
                <span class="text-sm">Student Answer:</span>

                <div
                    :class="this.classList()"
                    :id="this.id"
                >
                    <VueMathField readonly="1">{{ answer }}</VueMathField>
                </div>
            </div>

            <div
                class="flex flex-col"
                v-if="shouldDisplayCorrectAnswer && correctAnswer"
            >
                <span class="text-sm">Correct Answer:</span>

                <div
                    :class="this.classList(true)"
                    :id="`${this.id}-correct-answer`"
                >
                    <VueMathField readonly="1">{{ correctAnswer }}</VueMathField>
                </div>
            </div>
        </div>
    </QuestionBlock>
</template>
