<script lang="ts" setup>
import {
    backgroundImageStyles,
    dimRatioToClass,
    getColorClassName,
    getGradientClass,
    getPositionClassName,
    isContentPositionCenter,
} from "@/components/TestTaking/Blocks/Core/functions";
import ImageMedia from "@/components/TestTaking/Blocks/Core/Media/ImageMedia.vue";
import VideoMedia from "@/components/TestTaking/Blocks/Core/Media/VideoMedia.vue";
import type FocalPoint from "@/types/Blocks/FocalPoint";
import { computed, type PropType } from "vue";
import { IMAGE_BACKGROUND_TYPE, VIDEO_BACKGROUND_TYPE } from "@/components/TestTaking/Blocks/Core/constants";

const props = defineProps({
    backgroundType: {
        type: String,
        default: "",
    },
    gradient: {
        type: String,
        default: "",
    },
    contentPosition: {
        type: String,
        default: "",
    },
    customGradient: {
        type: String,
        default: "",
    },
    customOverlayColor: {
        type: String,
        default: "",
    },
    dimRatio: {
        type: Number,
        default: 0,
    },
    focalPoint: {
        type: Object as PropType<FocalPoint>,
        default: null,
    },
    hasParallax: {
        type: Boolean,
        default: false,
    },
    isRepeated: {
        type: Boolean,
        default: false,
    },
    overlayColor: {
        type: String,
        default: "",
    },
    url: {
        type: String,
        default: "",
    },
    id: {},
    minHeight: {
        type: Number,
        default: 0,
    },
    minHeightUnit: {
        type: String,
        default: null,
    },
});

const overlayColorClass = getColorClassName("background-color", props.overlayColor);
const gradientClass = computed(() => (props.gradient ? getGradientClass(props.gradient) : ""));
const minHeight = computed(() => (props.minHeightUnit ? `${props.minHeight}${props.minHeightUnit}` : props.minHeight));

const isImageBackground = computed(() => IMAGE_BACKGROUND_TYPE === props.backgroundType);
const isVideoBackground = computed(() => VIDEO_BACKGROUND_TYPE === props.backgroundType);

const isImgElement = computed(() => !(props.hasParallax || props.isRepeated));

const style = {
    ...(isImageBackground.value && !isImgElement.value ? backgroundImageStyles(props.url) : {}),
    backgroundColor: !overlayColorClass ? props.customOverlayColor : undefined,
    background: props.customGradient && !props.url ? props.customGradient : undefined,
    minHeight: minHeight.value || undefined,
};

const objectPosition = computed(() =>
    props.focalPoint && isImgElement
        ? `${Math.round(props.focalPoint.x * 100)}% ${Math.round(props.focalPoint.y * 100)}%`
        : undefined,
);

const classes = computed(() => [
    dimRatioToClass(props.dimRatio),
    overlayColorClass,
    {
        "has-background-dim": props.dimRatio !== 0,
        "has-parallax": props.hasParallax,
        "is-repeated": props.isRepeated,
        "has-background-gradient": props.gradient || props.customGradient,
        [gradientClass.value]: !props.url && gradientClass.value,
        "has-custom-content-position": !isContentPositionCenter(props.contentPosition),
    },
    getPositionClassName(props.contentPosition),
]);
</script>

<template>
    <div
        class="wp-block-cover"
        :class="classes"
        :style="style"
    >
        <span
            v-if="url && (gradient || customGradient) && dimRatio !== 0"
            aria-hidden="true"
            class="wp-block-cover__gradient-background"
            :class="[gradientClass]"
            :style="customGradient ? { background: customGradient } : undefined"
        />

        <ImageMedia
            v-if="isImageBackground"
            class="wp-block-cover__image-background object-cover"
            :class="[id ? `wp-image-${id}` : null]"
            :media-url="url"
            :media-alt="''"
            :style="{ objectPosition }"
            data-object-fit="cover"
            :data-object-position="objectPosition"
        />

        <VideoMedia
            v-if="isVideoBackground"
            class="wp-block-cover__video-background intrinsic-ignore"
            autoplay
            muted
            loop
            playsinline
            :media-url="url"
            :style="{ objectPosition }"
            data-object-fit="cover"
            :data-object-position="objectPosition"
        />

        <div class="wp-block-cover__inner-container">
            <slot />
        </div>
    </div>
</template>
