<script lang="ts" setup>
import { computed, inject } from "vue";
import { useStore } from "vuex";

defineProps({
    inputName: {
        type: String,
        required: true,
    },
});

const store = useStore();

const questionClientId = inject("questionClientId");

const { answerState, getAnswer } = inject("answer");
const answer = computed(() => getAnswer());
const graded = computed(() => store.getters.graded);

const inputtedValue = inject("inputtedValue");
</script>

<template>
    <div class="wp-block-answers-short-essay short-essay">
        <textarea
            :id="`essay-${questionClientId}`"
            class="tinymce-editor"
            :name="inputName"
            v-model="answer"
            @input="inputtedValue"
            :disabled="graded"
        />
    </div>
</template>
