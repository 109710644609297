<script lang="ts" setup>
import CCheckbox from "@/components/Form/CCheckbox.vue";
import { onMounted, ref, watch } from "vue";
import CText from "@/components/Form/CText.vue";
import CSelect from "@/components/Form/CSelect.vue";
import useSearchResource from "@/composables/useSearchResource";
import Loading from "vue-loading-overlay";
import PaginationDefault from "@/components/Global/paginationDefault.vue";
import Resource from "@/types/Resource";

const props = defineProps({
    hideArchivedCheckbox: {
        type: Boolean,
        default: true,
    },
    hideSearch: {
        type: Boolean,
        default: false,
    },
    refinedResources: {
        type: Array,
        default: () => [],
    },
});

const { filters, sortTypes, performSearch, loading, hits, result } = useSearchResource();
const resetPage = ref(false);
watch(filters.value, async () => {
    if (resetPage.value) {
        filters.value.page = 1; // Reset page to 1 when filters change
    }
    await performSearch();
    resetPage.value = true;
});

onMounted(async () => {
    // If student provided a refined list of resources, use that instead of performing a search
    if (props.refinedResources.length > 0) {
        hits.value = props.refinedResources as Resource[];
    } else {
        await performSearch();
    }
    resetPage.value = true;
});

async function goToPage(page: number) {
    resetPage.value = false;
    if (page > 0) {
        filters.value.page = page;
        await performSearch();
    }
}
</script>

<template>
    <div>
        <CCheckbox
            v-if="!hideArchivedCheckbox"
            v-model="filters.includeArchived"
            :input-value="true"
            label="Include Archived"
        />

        <div
            v-if="!hideSearch"
            class="flex"
        >
            <!-- Search -->
            <div class="flex-1 pr-4">
                <div>
                    <div class="relative">
                        <CText
                            v-model="filters.search"
                            placeholder="Search Resource Title..."
                        />

                        <i
                            v-show="!filters.search"
                            class="fas fa-search absolute right-3 top-3"
                        ></i>

                        <span
                            v-show="filters.search"
                            @click="filters.search = ''"
                            class="absolute right-3 top-2 cursor-pointer text-base font-bold"
                            >X</span
                        >
                    </div>
                </div>
            </div>
            <!-- Sort by -->
            <div class="w-1/8 flex-none">
                <CSelect
                    v-model="filters.sortBy"
                    :options="sortTypes"
                    placeholder="Sort By..."
                />
            </div>
        </div>

        <!-- List items, loading, & pagination -->
        <div class="relative mt-4">
            <div
                v-show="loading"
                class="absolute flex h-full w-full items-center justify-center bg-light-background py-40 opacity-90"
            >
                <div class="flex-none">
                    <Loading
                        v-model:active="loading"
                        :can-cancel="false"
                        :is-full-page="true"
                        class="inline-block"
                    />

                    <br />Loading...
                </div>
            </div>

            <!-- List item slot -->
            <div>
                <Transition name="fade">
                    <!-- Use by sampleResourceList -->
                    <slot name="beforeResourceList"></slot>
                </Transition>

                <div
                    v-for="(item, index) in hits"
                    :key="index"
                    class="my-3 flex rounded border border-gray-300 p-2 hover:bg-gray-100"
                >
                    <slot
                        name="row"
                        :resource="item"
                    ></slot>
                </div>
            </div>

            <!-- Show pagination if needed -->
            <PaginationDefault
                :data="result"
                @go-to-page-clicked="goToPage"
                class="mt-6"
            ></PaginationDefault>
        </div>
    </div>
</template>

<style scoped>
.fade-up-down-enter-active {
    transition: all 0.3s ease;
}

.fade-up-down-leave-active {
    transition: all 0.3s ease;
}

.fade-up-down-enter {
    transform: translateY(10%);
    opacity: 0;
}

.fade-up-down-leave-to {
    transform: translateY(10%);
    opacity: 0;
}

.fade-enter-active {
    -webkit-transition: opacity 2s;
    transition: opacity 0.3s;
}

.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
