export default {
    label: "Student",
    rows: [
        [
            {label: "7", key: "7"},
            {label: "8", key: "8"},
            {label: "9", key: "9"},
            {latex: "\\div"},

            {class: "separator w5"},

            {latex: "("},
            {latex: ")"},
            {latex: "["},
            {latex: "]"},
            {latex: "\\$"},
            {latex: "¢"},
            {latex: "|"},

            {class: "separator w5"},

            {class: "small", latex: "\\frac{#0}{#0}"},
            {latex: "|\\scriptsize a\\normalsize|"},
            {latex: "x"},
            {latex: "y"},
            {latex: "e"},
            {latex: "i"},
            {latex: "f(x)"},
            {latex: "\\angle"},
        ],
        [
            {label: "4", latex: "4"},
            {label: "5", key: "5"},
            {label: "6", key: "6"},
            {latex: "\\times"},

            {class: "separator w5"},

            {latex: "<"},
            {latex: ">"},
            {latex: "\\le"},
            {latex: "\\ge"},
            {latex: "\\ne"},
            {latex: "?"},
            {latex: "!"},

            {class: "separator w5"},

            {class: "small", latex: "#0\\frac{#0}{#0}"},
            {latex: "a^2"},
            {latex: "a^b"},
            {latex: "\\mu"},
            {latex: "a_n"},
            {latex: "\\overline{AB}"},
            {latex: "\\overgroup{ABC}"},
            {class: "separator w1"},
        ],
        [
            {label: "1", key: "1"},
            {label: "2", key: "2"},
            {label: "3", key: "3"},
            {latex: "-"},

            {class: "separator w5"},

            {latex: "\\%"},
            {latex: "°"},
            {latex: "\\{"},
            {latex: "\\}"},
            // Relational operators
            {latex: "\\thicksim"},
            {latex: "\\cong"},
            // Binary operators
            {latex: "\\pm"},

            {class: "separator w5"},

            {latex: "^r"},
            {latex: "^t"},
            {latex: "^0"},
            // Greek
            {latex: "\\pi"},
            {latex: "\\varDelta"},
            {latex: "\\sigma"},
            {latex: "\\varSigma"},
            {class: "separator w1"},
        ],
        [
            {label: "0", key: "0"},
            {latex: "."},
            {latex: "="},
            {latex: "+"},

            {class: "separator w5"},

            {class: "action w50", label: "", insert: "\\:"},
            {
                class: "action",
                label: "<svg><use xlink:href='#svg-commit' /></svg>",
                command: ["performWithFeedback", "commit"]
            },
            {
                class: "action",
                label: "<svg><use xlink:href='#svg-delete-backward' /></svg>",
                command: ["performWithFeedback", "deleteBackward"]
            },

            {class: "separator w5"},

            {class: "action", latex: "\\Huge ⇠", command: ["performWithFeedback", "moveToPreviousChar"]},
            {class: "action", latex: "\\Huge ⇢", command: ["performWithFeedback", "moveToNextChar"]},
            // Binary operators
            {latex: "\\surd{}"},
            {latex: "\\sqrt[3]{}"},
            {latex: "\\sqrt[n]{}"},
            // Various
            {latex: "\\theta"},
            {latex: "\\infty"},
            {class: "separator w1"},
        ]
    ],
}
