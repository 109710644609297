export default {
    correctAnswers: {},
    graded: false,
    lastCompletedPage: 0,
    loading: false,
    navigating: false,
    page: {
        order: 1,
    },
    pages: [],
    preview: false,
    questionsSaving: {},
    questionsReseting: {},
    saving: false,
    sample: false,
    secret: "",
    sidebarOpen: false,
    submission: {},
    resource: {},
    user: {},
};
