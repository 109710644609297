<script>
import { mapGetters } from "vuex";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import QuestionNumber from "@/components/TestTaking/Blocks/QuestionNumber.vue";
import QuestionScore from "@/components/Teacher/QuestionScore.vue";

export default {
    components: { BtnPrimary, QuestionNumber, QuestionScore },
    data() {
        return {};
    },

    props: {
        blockId: {
            default: "",
            type: String,
        },

        questionNumber: {
            default: "",
            type: String,
        },
    },

    computed: {
        ...mapGetters({
            _graded: "graded",
            _isPreview: "isPreview",
            _isSample: "isSample",
            _question: "question",
            _scoreReceived: "scoreReceived",
            _submittedAnswer: "submittedAnswer",
            _testSubmitted: "testSubmitted",
        }),

        displayStandard() {
            return this._isPreview || this._isSample || this._graded;
        },

        standard() {
            const question = this._question(this.blockId);
            return question ? question.standard : "";
        },

        submittedAnswerId() {
            const submittedAnswer = this._submittedAnswer(this.blockId);

            return submittedAnswer ? submittedAnswer.id : 0;
        },
    },

    methods: {
        resetAnswer() {
            this.$emit("reset-answer", null, false);
        },
    },
};
</script>

<template>
    <div
        :id="`question-${blockId}`"
        class="question-block @container mb-100p"
    >
        <div
            class="flex"
            :class="this._graded ? 'pt-12' : ''"
        >
            <QuestionNumber
                class="question-number flex-none"
                :client-id="blockId"
            >
                <span>{{ questionNumber }}</span>
            </QuestionNumber>

            <div class="relative max-w-full flex-1">
                <slot></slot>

                <div
                    class="after-question reset-button-wrapper mt-4"
                    v-if="!_graded"
                >
                    <div>
                        <BtnPrimary
                            class="reset-button"
                            padding="py-1 px-2"
                            @click="resetAnswer"
                            :disabled="_testSubmitted"
                        >
                            Reset
                        </BtnPrimary>
                    </div>

                    <div
                        class="flex flex-1 justify-end text-sm text-gray-700"
                        v-if="displayStandard"
                    >
                        {{ standard }}
                    </div>
                </div>

                <QuestionScore
                    :score="_scoreReceived(blockId)"
                    :max-score="_question(blockId).score"
                    :answer-id="submittedAnswerId"
                    :question-id="_question(blockId).id"
                    v-else
                >
                    <div
                        class="flex flex-1 justify-end text-sm text-gray-500"
                        v-if="displayStandard"
                    >
                        {{ standard }}
                    </div>
                </QuestionScore>
            </div>
        </div>
    </div>
</template>
