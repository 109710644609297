<script>
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import ModalWindow from "@/components/Global/ModalWindow.vue";
import BtnDanger from "@/components/Global/BtnDanger.vue";

export default {
    components: { BtnDanger, BtnPrimary, ModalWindow },
    props: {
        user: null,
    },
    data() {
        return {
            processing: false,
            processSuccessfully: false,
            showDisableModal: false,
        };
    },
    mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.get("successful")) {
            const element = document.querySelector("#google-classroom");
            const topPos = element.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({
                top: topPos, // scroll so that the element is at the top of the view
                behavior: "smooth", // smooth scroll
            });

            this.processSuccessfully = true;
            setTimeout(() => {
                this.processSuccessfully = false;
                // Clear variable from URL
                history.replaceState &&
                    history.replaceState(
                        null,
                        "",
                        location.pathname + location.search.replace(/[\?&]successful=[^&]+/, "").replace(/^&/, "?"),
                    );
            }, "5000");
        }
    },
    methods: {
        enable() {
            const url = `${route("auth.google-classroom")}?redirect=${window.location.href}`;
            window.location.href = url;
        },
        disable() {
            const url = route("teacher.google-classroom.disable");
            axios
                .delete(url)
                .then((response) => {
                    const url = `${window.location.href}?successful=1`;
                    window.location.href = url;
                })
                .catch((errors) => {
                    console.error(errors);
                })
                .finally(() => {
                    this.processing = false;
                    this.showDisableModal = false;
                });
        },
    },
};
</script>

<template>
    <div>
        <div v-if="user.google_classroom_enabled">
            <p
                v-if="processSuccessfully"
                class="mb-4 rounded border border-green-400 bg-green-200 p-2 text-green-800"
            >
                <b>Success!</b> Your CourseWave account was successfully linked to Google Classroom.
            </p>

            <p>You have <b>Successfully Linked</b> CourseWave to Google Classroom.</p>

            <p class="text-center">
                <BtnPrimary
                    type="button"
                    @click="showDisableModal = true"
                    class="mx-auto mt-16 w-full rounded-[0.25rem] border-gray-500 bg-gray-500 text-lg xl:w-auto xl:px-12"
                >
                    Disable Google Classroom
                </BtnPrimary>
            </p>
        </div>

        <div v-else>
            <p
                v-if="processSuccessfully"
                class="mb-4 rounded border border-yellow-300 bg-yellow-100 p-2"
            >
                Your CourseWave account was successfully <b>unlinked</b> from Google Classroom.
            </p>

            You have <b>not linked</b>
            CourseWave to Google Classroom.
            <p class="mt-6">
                Google Classroom is a service frequently used to manage class rosters as well as centralize where
                assignments are shared with students.
            </p>

            <p class="text-center">
                <BtnPrimary
                    type="button"
                    @click="enable"
                    class="mx-auto mt-8 w-full rounded-[0.25rem] text-lg xl:w-auto xl:px-12"
                >
                    Link Google Classroom
                </BtnPrimary>
            </p>
        </div>

        <!-- Modal: Disable confirmation-->
        <ModalWindow
            v-model:show="showDisableModal"
            header-classes="modal-header px-5 pt-5 text-center"
            footer-classes="modal-footer border0-t px-5 pb-5 text-center"
        >
            <template #header>
                <h5 class="text-2xl uppercase">Disable Google Classroom</h5>
            </template>

            <div class="pb-4 text-center">Are you sure you want to disable Google Classroom?</div>

            <template #footer>
                <BtnPrimary
                    padding="px-5 py-2"
                    @click="disable"
                    class="w-auto"
                    :class="{ 'opacity-25': processing }"
                    :disabled="processing"
                >
                    Yes, Disable
                </BtnPrimary>

                <BtnDanger
                    @click="showDisableModal = false"
                    class="ml-2"
                >
                    Cancel
                </BtnDanger>
            </template>
        </ModalWindow>
    </div>
</template>

<style scoped></style>
