<script setup>
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";
import { onBeforeMount, provide, ref } from "vue";
import useQuestionBasics from "@/components/TestTaking/Blocks/Composables/useQuestionBasics";

const props = defineProps({
    clientID: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
    gridPerRow: {
        type: Number,
        default: 1,
    },
});

const {
    answer,
    clientId,
    correctAnswer,
    graded,
    question,
    questionNumber,
    resetAnswer,
    saveAnswer,
    scoreRecieved,
    submittedAnswer,
    submittedAnswerValue,
    valueIsCorrect,
    answerState,
} = useQuestionBasics(props, props.type === "checkbox" ? [] : null);

function selectAnswer(event) {
    const { checked } = event.target;
    const { value } = event.target;

    if (props.type === "checkbox") {
        if (checked) {
            answer.value.push(value);
        } else {
            const index = answer.value.indexOf(value);

            if (index > -1) {
                answer.value.splice(index, 1);
            }
        }
    } else {
        answer.value = value;
    }

    saveAnswer();
}

provide("answer", {
    answer,
    selectAnswer,
    answerState,
});
</script>

<template>
    <QuestionBlock
        class="multiple-choice"
        :class="`multiple-choice-grid-${gridPerRow}`"
        :question-number="questionNumber"
        :block-id="clientId"
        @reset-answer="resetAnswer"
    >
        <slot
            :selected-value="selectAnswer"
            :label-click="() => {}"
            :answer="answer"
            :graded="graded"
            :answer-state="answerState"
        />
    </QuestionBlock>
</template>

<style scoped>
:deep(.wp-block-group.answers) {
    align-items: stretch;
}
</style>
