<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
    fixedHeight: {
        type: Boolean,
        default: false,
    },

    height: {
        type: Number,
        default: 100,
    },

    isChildBlock: {
        type: Boolean,
        default: false,
    },
});

const maxHeight = computed(() => {
    if (props.fixedHeight) {
        return `${props.height}px`;
    }

    return "calc(100vh - (9.5rem + 200px))";
});
</script>

<template>
    <div
        class="wp-block-design-scrolling-container scrolling-container overflow-y-auto"
        :class="isChildBlock ? 'child-block max-w-[99%]' : ''"
        :style="{
            maxHeight,
        }"
    >
        <slot />
    </div>
</template>
