import find from "lodash/find";

export default {
    hasPageBeenCompleted: (state, getters) => getters.pageNumber <= state.lastCompletedPage,
    hasUnansweredQuestions: (state, getters) => getters.unansweredQuestions > 0,
    graded: (state) => state.graded,
    isLastContentPage: (state, getters) => getters.pageNumber === getters.numberOfPages,
    isLastPage: (state, getters) =>
        getters.pageNumber === getters.numberOfPages + (getters.isPreview || getters.graded ? 0 : 1),
    isPreview: (state) => state.preview,
    isSample: (state) => state.sample,
    isSidebarOpen: (state) => state.sidebarOpen,
    loading: (state) => state.loading,
    navigating: (state) => state.navigating,
    numberOfPages: (state) => _.size(_.filter(state.pages, (page) => !_.get(page, "isReview", false))),
    page: (state) => state.page,
    pages: (state) => state.pages,
    pageNumber: (state) => state.page && state.page.order,
    questionsSaving: (state) => state.questionsSaving,
    saving: (state) => state.saving,
    submission: (state) => state.submission,
    submittedAnswers: (state) => state.submission && state.submission.answers,
    testId: (state, getters) => getters.page && getters.page.test_id,
    testSubmitted: (state, getters) =>
        getters.submission.submitted_at !== null && getters.submission.submitted_at !== undefined,

    resource: (state) => state.resource,

    correctAnswer: (state, getters) => (clientId) => state.correctAnswers[clientId],

    getNextPage: (state, getters) => (step) => {
        if (step === 0) {
            return getters.pageNumber;
        }

        const direction = step > 0 ? "asc" : "desc";
        let count = Math.abs(step);
        const nextPage = _.find(_.orderBy(getters.pages, ["order"], [direction]), (page) => {
            let isNextPage = true;

            if (getters.isSample && !page.is_sample) {
                isNextPage = false;
            }

            if (step > 0) {
                isNextPage &= page.order > getters.pageNumber;
            }

            if (step < 0) {
                isNextPage &= page.order < getters.pageNumber;
            }

            if (isNextPage) {
                count--;
            }

            return isNextPage && count === 0;
        });

        return nextPage && nextPage.order;
    },

    gradedAnswers: (state, getters) => (clientId) => {
        const correctAnswer = getters.correctAnswer(clientId);
        const answer = getters.submittedAnswerValue(clientId);
        const question = getters.question(clientId);
        const answers = {
            correct: [],
            incorrect: [],
        };

        if (correctAnswer !== null) {
            if (Array.isArray(correctAnswer)) {
                _.forEach(correctAnswer, (answerValue) => {
                    const answerState = answer.indexOf(answerValue) !== -1 ? "correct" : "incorrect";

                    answers[answerState].push(answerValue);
                });

                _.forEach(answer, (answerValue) => {
                    const answerState = correctAnswer.indexOf(answerValue) !== -1 ? "correct" : "incorrect";

                    if (answers[answerState].indexOf(answerValue) === -1) {
                        answers[answerState].push(answerValue);
                    }
                });
            } else {
                answers[correctAnswer == answer ? "correct" : "incorrect"].push(answer);
            }
        }

        return {
            correct: answers.correct.length,
            incorrect: answers.incorrect.length,
        };
    },

    isQuestionIncorrect: (state, getters) => (clientId) => {
        const submittedAnswer = getters.submittedAnswer(clientId);

        return !submittedAnswer || (submittedAnswer && submittedAnswer.score === 0);
    },

    isQuestionCorrect: (state, getters) => (clientId) => {
        const submittedAnswer = getters.submittedAnswer(clientId);

        return submittedAnswer && submittedAnswer.score === getters.question(clientId).score;
    },

    isQuestionPartiallyCorrect: (state, getters) => (clientId) => {
        const submittedAnswer = getters.submittedAnswer(clientId);

        return submittedAnswer && submittedAnswer.score > 0 && submittedAnswer.score < getters.question(clientId).score;
    },

    numberCorrect: (state, getters) => (clientId) => getters.gradedAnswers(clientId).correct,

    numberIncorrect: (state, getters) => (clientId) => getters.gradedAnswers(clientId).incorrect,

    question: (state, getters) => (clientId) => getters.questions[clientId],

    questions: (state) =>
        state.pages.reduce((carry, page) => {
            page.questions.forEach((question) => {
                carry[question.clientId] = question;
            });

            return carry;
        }, {}),

    scoreReceived: (state, getters) => (clientId) => {
        const submittedAnswer = getters.submittedAnswer(clientId);

        if (getters.submission.id === undefined) {
            return undefined;
        }

        if (!submittedAnswer) {
            return 0;
        }

        return submittedAnswer && submittedAnswer.score;
    },

    submittedAnswer: (state, getters) => (clientId) => {
        const question = getters.question(clientId);

        if (!question) {
            console.trace();
            console.log(clientId, getters.questions);
            return;
        }

        return find(state.submission.answers, { question_id: question.id });
    },

    submittedAnswerValue: (state, getters) => (clientId) => _.get(getters.submittedAnswer(clientId), "answer"),

    submittedAnswerIndex: (state, getters) => (clientId) => {
        const question = getters.question(clientId);

        return _.findIndex(state.submission.answers, { question_id: question.id });
    },

    teacherInputRequired: (state, getters) => (clientId) => {
        if (!getters.graded) {
            return false;
        }

        return getters.scoreReceived(clientId) === null;
    },

    teacherInputProvided: (state, getters) => (clientId) => {
        if (!getters.graded) {
            return false;
        }

        const submittedAnswer = getters.submittedAnswer(clientId);

        return submittedAnswer && submittedAnswer.teacher_feedback_provided;
    },

    totalCorrectAnswers: (state, getters) => (clientId) => {
        const correctAnswer = getters.correctAnswer(clientId);

        if (Array.isArray(correctAnswer)) {
            return correctAnswer.length;
        }
        if (correctAnswer === null) {
            return -1;
        }

        return 1;
    },

    unansweredQuestions: (state, getters) => {
        const numberOfQuestions = Object.keys(getters.questions).length;
        const numberOfAnsweredQuestions =
            (state.submission && state.submission.answers && state.submission.answers.length) || 0;

        return numberOfQuestions - numberOfAnsweredQuestions;
    },
};
