<script setup>
import useButtonType from "@/composables/useButtonType";

const props = defineProps({
    href: {
        type: String,
        default: null,
    },
});

const { buttonType } = useButtonType();
</script>

<template>
    <Component
        :is="buttonType"
        v-bind="props"
        class="flex content-center items-center justify-center rounded-full border-2 border-primary-lighter bg-transparent px-3 py-1 text-center text-xs font-bold text-primary-lighter hover:border-primary hover:bg-primary-lighter hover:text-white focus:outline-none"
    >
        <span
            class="h-auto w-4"
            v-if="$slots.icon"
        >
            <slot name="icon" />
        </span>

        <slot />
    </Component>
</template>

<style scoped></style>
