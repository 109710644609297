<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";

export default {
    components: { BtnPrimary, FontAwesomeIcon },
    data() {
        return {
            processing: false,
            errorMsg: "",
        };
    },

    computed: {
        ...mapGetters({
            _hasUnansweredQuestions: "hasUnansweredQuestions",
            _isSidebarOpen: "isSidebarOpen",
            _submission: "submission",
            _testId: "testId",
            _testSubmitted: "testSubmitted",
            _unansweredQuestions: "unansweredQuestions",
        }),

        classNames() {
            return {
                "text-primary-darker": !this.hasUnansweredQuestions,
                "text-warning": this.hasUnansweredQuestions,
            };
        },

        hasUnansweredQuestions() {
            return this._hasUnansweredQuestions;
        },

        heading() {
            return this.hasUnansweredQuestions
                ? `${this._unansweredQuestions} Unanswered questions`
                : "All questions answered";
        },

        iconName() {
            return this.hasUnansweredQuestions ? "exclamation-circle" : "check-circle";
        },
    },

    methods: {
        ...mapActions({
            _fetchSubmission: "createSubmission", // createSubmission creates or fetches submission
        }),
        ...mapMutations(["toggleSidebar", "updatePages", "setPreview", "updateSubmission"]),

        submitTest() {
            let submission;
            this.processing = true;

            this.errorMsg = "";
            if (!this._testSubmitted) {
                submission = axios.post(route("test.submission.submit", { test: this._testId }), {
                    submission_id: this._submission.id,
                });
            } else {
                submission = new Promise(() => {
                    setTimeout(() => {
                        Promise.resolve();
                    }, 100);
                });
            }

            submission
                .then((response) => {
                    this.updateSubmission(response.data.submission); // Refresh submission model
                })
                .catch(({ response }) => {
                    console.log(response);
                    if (response?.data?.message) {
                        this.errorMsg = response.data.message;
                    }
                })
                .finally(() => {
                    this.processing = false;
                });
        },
    },

    mounted() {
        this._fetchSubmission(); // Refresh submission model
        if (this.hasUnansweredQuestions && !this._isSidebarOpen) {
            this.toggleSidebar();
        }
    },
};
</script>

<template>
    <div class="mx-auto flex w-full max-w-2xl flex-col items-start justify-center">
        <div class="flex h-40 w-full items-center justify-center rounded-lg border-2 border-primary-lighter text-2xl">
            {{ heading }}

            <FontAwesomeIcon
                :icon="iconName"
                size="lg"
                class="ml-2"
                :class="classNames"
            ></FontAwesomeIcon>
        </div>

        <div v-if="!_testSubmitted">
            <div
                class="w-full"
                v-if="!hasUnansweredQuestions"
            >
                <p class="mt-3">
                    Before you submit your test, use the question list in the upper left corner to review your answers
                    to ensure they are correct.
                </p>

                <p class="mt-3">
                    Once you complete your review, click the Submit final answers button below to submit your test.
                </p>
            </div>

            <div
                class="w-full"
                v-else
            >
                <p class="mt-3">
                    Use the question list in the upper left corner to review all questions and provide answers for any
                    unanswered questions.
                </p>

                <p class="mt-3">
                    Once you complete all answers, click the Submit final answers button below to submit your test.
                </p>

                <p class="mt-3">Any questions you leave unanswered will be marked incorrect.</p>
            </div>

            <div class="mt-10 flex w-full justify-center">
                <BtnPrimary
                    @click="submitTest"
                    :disabled="processing"
                >
                    Submit final answers
                </BtnPrimary>
            </div>

            <div
                v-if="errorMsg"
                v-html="errorMsg"
                class="mt-4 text-center text-red-700"
            ></div>
        </div>

        <div
            v-else
            class="mt-10 w-full text-center text-xl"
        >
            Your test has been submitted! Thank you.
            <div class="mt-8">
                <BtnPrimary
                    class=""
                    :href="route('home')"
                >
                    Return to Test List
                    <FontAwesomeIcon
                        icon="chevron-right"
                        class="ml-2"
                    />
                </BtnPrimary>
            </div>

            <div class="mt-2 w-full text-center">
                <a
                    :href="route('student-logout-get')"
                    class="text-lg text-red-500 hover:text-red-700"
                    >or log out now</a
                >
            </div>
        </div>
    </div>
</template>
