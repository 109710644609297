// This is a shared TinyMCE import/config file which is used by both the test creators and test taking sides.
// This needs to be dynamically imported to help prevent issues with the imports being bundled into different chunks
// and TinyMCE not being available when the test taking side is loaded.

/* Import TinyMCE */
// https://www.tiny.cloud/docs/tinymce/6/webpack-es6-npm/

import tinymce from "tinymce";

/* Default icons are required. After that, import custom icons if applicable */
import "tinymce/icons/default";

/* Required TinyMCE components */
import "tinymce/themes/silver";
import "tinymce/models/dom";

/* Import a skin (can be a custom skin instead of the default) */
import "tinymce/skins/ui/oxide/skin.css?inline";
// alternative import style?
// import "tinymce/skins/ui/oxide/skin.css";

/* Import plugins */
import "tinymce/plugins/advlist";
import "tinymce/plugins/code";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/emoticons/js/emojis";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/table";
import "tinymce/plugins/charmap";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/wordcount";

/* Import premium plugins */
/* NOTE: Download separately and add these to /src/plugins */
/* import './plugins/checklist/plugin'; */
/* import './plugins/powerpaste/plugin'; */
/* import './plugins/powerpaste/js/wordimport'; */
/* content UI CSS is required */
import contentUiSkinCss from "tinymce/skins/ui/oxide/content.css?inline";
// import "tinymce/skins/ui/oxide/skin.css";


/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
import contentCss from "tinymce/skins/content/default/content.css?inline";

/* Initialize TinyMCE */
export async function render(options) {
    tinymce.init({
        plugins: "advlist code emoticons link lists table",
        toolbar: "bold italic | bullist numlist | link emoticons",
        skin: false,
        content_css: false,
        content_style: `${contentUiSkinCss.toString()}\n${contentCss.toString()}`,
        ...options,
    });
}

export default render;
