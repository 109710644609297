export default {
    label: "Student",
    rows: [
        [
            {label: "7", key: "7"},
            // Will display the label using the system font. To display
            // with the TeX font, use:
            // { class: "tex", label: "7", key: "7" },
            // or
            // { latex: "7"},
            {label: "8", key: "8"},
            {label: "9", key: "9"},
            {latex: "\\div"},

            {class: "separator w5"},

            {latex: "("},
            {latex: ")"},
            {latex: "["},
            {latex: "]"},
            {latex: "\\$"},
            {latex: "¢"},

            {class: "separator w5"},
            {class: "small", latex: "\\frac{#0}{#0}"},
        ],
        [
            {label: "4", latex: "4"},
            {label: "5", key: "5"},
            {label: "6", key: "6"},
            {latex: "\\times"},

            {class: "separator w5"},

            {latex: "<"},
            {latex: ">"},
            {latex: "\\le"},
            {latex: "\\ge"},
            {latex: "\\ne"},
            {latex: "?"},

            {class: "separator w5"},
            {class: "small", latex: "#0\\frac{#0}{#0}"},
        ],
        [
            {label: "1", key: "1"},
            {label: "2", key: "2"},
            {label: "3", key: "3"},
            {latex: "-"},

            {class: "separator w5"},

            {latex: "\\%"},
            {latex: "°"},
            {latex: ";"},
            {latex: ","},
            {class: "action", latex: "\\Huge ⇠", command: ["performWithFeedback", "moveToPreviousChar"]},
            {class: "action", latex: "\\Huge ⇢", command: ["performWithFeedback", "moveToNextChar"]},

            {class: "separator w5"},
            {class: "separator w1"},
        ],
        [
            {label: "0", key: "0"},
            {latex: "."},
            {latex: "="},
            {latex: "+"},

            {class: "separator w5"},

            {class: "action w50", label: "", insert: "\\:"},
            {
                class: "action",
                label: "<svg><use xlink:href='#svg-commit' /></svg>",
                command: ["performWithFeedback", "commit"]
            },

            {class: "separator w5"},
            {
                class: "action",
                label: "<svg><use xlink:href='#svg-delete-backward' /></svg>",
                command: ["performWithFeedback", "deleteBackward"]
            },
        ]
    ],
}
