<script lang="ts" setup>
import BtnPill from "@/components/Global/BtnPill.vue";
import Swal from "sweetalert2";
import { computed, PropType, ref } from "vue";
import Resource from "@/types/Resource";
import Test from "@/types/Test";
import User from "@/types/User";
import axios from "axios";
import { FontAwesomeIcon } from "~/@fortawesome/vue-fontawesome";
import { router } from "@inertiajs/vue3";
import Paginator from "@/types/Paginator";
import Submission from "@/types/Submission";
import PaginationDefault from "../Global/paginationDefault.vue";

const props = defineProps({
    resource: {
        type: Object as PropType<Resource>,
        default: null,
    },
    test: {
        type: Object as PropType<Test>,
        default: null,
    },
    student: {
        type: Object as PropType<User>,
        default: null,
    },
    showExport: {
        type: Boolean,
        default: true,
    },
    showEmailStudents: {
        type: Boolean,
        default: true,
    },
    showPagination: {
        type: Boolean,
        default: false,
    },

    submissionsData: {
        type: Object as PropType<Paginator<Submission>>,
        default: () => ({}),
    },
});

// Data
const submissions = computed(() => props.submissionsData?.data ?? null);
const includeArchived = ref(false);
const paginationData = computed(() => props.submissionsData ?? null);
const loading = ref(false);

const searchParams = new URLSearchParams(window.location.search);
const page = ref(searchParams.get("page") ?? 1);
const sort = ref(searchParams.get("sort") ?? "");
const asc = ref(searchParams.get("asc") ?? 1);

// Computed
const showStudentName = computed(() => !props.student);
const showResourceName = computed(() => !props.resource && !props.test);
const showTestName = computed(() => !props.test);
const showClass = computed(() => {
    const code = submissions.value.find((submission) => {
        if (submission.class_name) {
            return true;
        }
        return false;
    });
    return !!code;
});
const hasUngraded = computed(() => {
    for (let i = 0; i < submissions.value?.length; i++) {
        if (submissions.value[i].ungraded) {
            return true;
        }
    }
    return false;
});
const hasResourceCodeExpiringSoon = computed(() => {
    for (let i = 0; i < submissions.value.length; i++) {
        if (submissions.value[i].resource_code.days_left <= 30) {
            return true;
        }
    }
    return false;
});

// Functions
function exportLink(formatType) {
    const params = { format: formatType };
    let name = "teacher.export";

    if (props.test) {
        params.test = props.test.id;
        params.resource = props.test.resource_id;
        name = "teacher.test.export";
    } else if (props.resource) {
        params.resource = props.resource.id;
        name = "teacher.resource.export";
    }

    return route(name, params);
}
function calTotalPoints(submission) {
    let totalPts = 0;
    if (submission.total - submission.ungraded_points > 0) {
        totalPts = submission.total - submission.ungraded_points; // Total graded points
    }
    return totalPts;
}
function calScorePercent(submission) {
    const totalPts = calTotalPoints(submission);
    return Math.round((submission.score / totalPts) * 100);
}
function confirmEmail(submission) {
    Swal.fire({
        title: "Are you sure?",
        html: "Test score will be emailed to this student.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
    }).then((result) => {
        if (result.value) {
            axios
                .post(route("submission.emailScore", [submission.id]))
                .then(() => {})
                .catch((errors) => {
                    console.error(errors);
                });
        }
    });
}
function confirmEmails() {
    Swal.fire({
        title: "Are you sure?",
        html: "Test scores will be sent to students that haven't yet received their scores.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
    }).then((result) => {
        if (result.value) {
            axios
                .post(route("test.emailScores", [props.test.id]))
                .then(() => {})
                .catch((errors) => {
                    console.error(errors);
                });
        }
    });
}
function confirmDelete(submission) {
    if (!submission.resource_code.expired) {
        Swal.fire({
            title: "Are you sure?",
            html: "Reset will delete all answers for this submission!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                axios
                    .delete(route("submission.undoSubmit", [submission]))
                    .then(() => {
                        window.location.reload();
                    })
                    .catch((errors) => {
                        console.error(errors);
                    });
            }
        });
    } else {
        Swal.fire({
            title: "Resource code expired!",
            text: "Please purchase a new resource code.",
            icon: "error",
        });
    }
}
function expireAlert(submission: Submission) {
    let title = `Resource access expiring`;
    let msg = `${submission.resource_code.resource.title} is expiring in <b>${submission.resource_code.days_left}
        days</b> for ${submission.resource_code.student_name}.`;
    if (submission.resource_code.days_left <= 0) {
        title = `Resource access expired`;
        msg = `${submission.resource_code.resource.title} has expired for ${submission.resource_code.student_name}.`;
    }
    Swal.fire({
        title,
        html: msg,
        icon: "info",
        showCancelButton: false,
        confirmButtonText: "OK!",
    });
}

async function fetchData() {
    let paramsStr = `?page=${page.value}`;
    if (sort.value) {
        paramsStr += `&sort=${sort.value}&asc=${asc.value}`;
    }

    loading.value = true;
    router.get(
        `${window.location.pathname}${paramsStr}`,
        {},
        {
            preserveState: true,
            only: ["submissions"],
            onFinish: () => {
                loading.value = false;
            },
        },
    );
}

function goToPage(newPage = 1) {
    if (newPage > 0) {
        page.value = newPage;
        fetchData();
    }
}

function sortBy(val: string) {
    page.value = 1;
    sort.value = val;
    asc.value = asc.value === 1 ? 0 : 1;
    fetchData();
}

// Balloon tooltip is interfering with Playwright from clicking. Disable this in testing environment.
const balloonAttrs = () => (import.meta.env.VITE_APP_ENV === "testing" ? {} : { "data-balloon-pos": "up" });
const getDataTestId = (submission: Submission) => {
    const studentName = submission.resource_code?.student_name
        ? submission.resource_code?.student_name.replace(/\s+/g, "-")
        : "";
    const testTitle = submission.test?.title ? submission.test?.title.replace(/\s+/g, "-") : "";
    return `${studentName}-${testTitle}`;
};
</script>

<template>
    <div class="w-full">
        <div class="flex">
            <h2
                id="submissions-header"
                class="flex-none text-xl"
            >
                <slot name="title"></slot>
            </h2>

            <!-- Export links -->
            <div
                v-if="submissions?.length > 0"
                class="flex flex-1 flex-row-reverse"
            >
                <BtnPill
                    v-if="showEmailStudents"
                    @click="confirmEmails()"
                    class="ml-2"
                    >Email Scores
                </BtnPill>

                <a
                    v-if="showExport"
                    :href="exportLink('xlsx')"
                    target="_blank"
                    aria-label="Open in a new tab"
                    class="ml-2"
                >
                    <BtnPill>Export as XLSX</BtnPill>
                </a>

                <a
                    v-if="showExport"
                    :href="exportLink('csv')"
                    target="_blank"
                    aria-label="Open in a new tab"
                >
                    <BtnPill>Export as CSV</BtnPill>
                </a>
            </div>
        </div>

        <!-- List submissions -->
        <div class="relative">
            <div
                v-if="loading"
                class="absolute z-50 h-full w-full bg-gray-100/75 py-10 text-center text-2xl"
            >
                <FontAwesomeIcon
                    icon="spinner"
                    class="animate-spin"
                />
                Loading...
            </div>

            <table
                v-if="submissions && submissions.length > 0"
                class="mt-2 w-full table-auto overflow-hidden"
            >
                <thead>
                    <tr class="bg-gray-200">
                        <th
                            v-if="showStudentName"
                            class="border border-gray-400 px-4 py-2 text-left"
                        >
                            Student Name
                        </th>

                        <th
                            @click="sortBy('class')"
                            class="cursor-pointer border border-gray-400 px-4 py-2 text-left hover:bg-gray-300"
                        >
                            Class
                        </th>

                        <th
                            v-if="showResourceName"
                            class="border border-gray-400 px-4 py-2 text-left"
                        >
                            Resource Name
                        </th>

                        <th
                            v-if="showTestName"
                            class="border border-gray-400 px-4 py-2 text-left"
                        >
                            Test Name
                        </th>

                        <th
                            @click="sortBy('sumbitted_at')"
                            class="cursor-pointer border border-gray-400 px-4 py-2 text-left hover:bg-gray-300"
                        >
                            Submitted
                        </th>

                        <th class="border border-gray-400 px-4 py-2 text-center">
                            Ungraded <br />
                            Questions
                        </th>

                        <th class="border border-gray-400 px-4 py-2">Score</th>

                        <th class="w-12 border border-gray-400 px-4 py-2">%</th>

                        <th
                            v-if="hasResourceCodeExpiringSoon"
                            class="border border-gray-400 px-4 py-2"
                        ></th>

                        <th class="border border-gray-400 px-4 py-2"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="submission in submissions"
                        :key="submission.id"
                        class="even:bg-gray-100"
                    >
                        <td
                            v-if="showStudentName"
                            class="border border-gray-400 px-4 py-2 hover:bg-gray-200"
                        >
                            <a
                                v-if="submission.resource_code?.student_id"
                                class="block hover:underline"
                                :href="route('teacher.student.show', { student: submission.resource_code.student_id })"
                            >
                                {{ submission.resource_code.student_name }}
                            </a>
                        </td>

                        <td class="border border-gray-400 px-4 py-2 hover:bg-gray-200">
                            {{ submission.class_name }}
                        </td>

                        <td
                            v-if="showResourceName"
                            class="border border-gray-400 px-4 py-2 hover:bg-gray-200"
                        >
                            <a
                                v-if="submission.resource_code?.resource"
                                class="block hover:underline"
                                :href="route('resources.show', { resource: submission.resource_code.resource_id })"
                            >
                                {{ submission.resource_code.resource.title }}
                            </a>
                        </td>

                        <td
                            v-if="showTestName"
                            class="border border-gray-400 px-4 py-2 hover:bg-gray-200"
                        >
                            <a
                                v-if="submission.resource_code?.resource"
                                class="block hover:underline"
                                :href="
                                    route('resources.tests.show', {
                                        resource: submission.resource_code.resource.id,
                                        test: submission.test_id,
                                    })
                                "
                            >
                                {{ submission.test ? submission.test.title : "Test" }}
                            </a>
                        </td>

                        <td class="border border-gray-400 px-4 py-2">
                            <span
                                v-cloak
                                v-if="submission.submitted_at"
                            >
                                {{ new Date(submission.submitted_at).toLocaleDateString() }}
                                {{ new Date(submission.submitted_at).toLocaleTimeString() }}
                            </span>
                        </td>

                        <td class="border border-gray-400 px-4 py-2 text-center">
                            {{ submission.ungraded }}
                        </td>

                        <td class="border border-gray-400 px-4 py-2">
                            <span class="flex w-full whitespace-nowrap">
                                <!-- Graded points -->
                                <span class="flex w-full justify-end"
                                    >{{ submission.score ? submission.score : 0 }}
                                    {{ submission.ungraded ? "*" : "" }}</span
                                >

                                <span class="flex w-full justify-center">/</span>
                                <!-- Total graded points -->
                                <span class="flex w-full">{{ calTotalPoints(submission) }}</span>
                            </span>
                        </td>

                        <td
                            :data-testid="`${getDataTestId(submission)}-grade`"
                            class="border border-gray-400 px-4 py-2"
                        >
                            {{ calScorePercent(submission) }}%
                        </td>

                        <td
                            v-if="hasResourceCodeExpiringSoon"
                            class="w-20 border border-gray-400 px-4 py-2"
                        >
                            <span
                                v-if="submission.resource_code.days_left <= 30"
                                @click="expireAlert(submission)"
                                :aria-label="
                                    submission.resource_code.days_left > 0
                                        ? `Expires in ${submission.resource_code.days_left} days`
                                        : `Expired`
                                "
                                data-balloon-pos="up"
                                class="ml-2 text-red-500 hover:text-gray-600"
                            >
                                <i class="fas fa-clock"></i>
                            </span>
                        </td>

                        <td class="w-28 border border-gray-400 px-4 py-2 text-right">
                            <a
                                :data-testid="`${getDataTestId(submission)}-submission`"
                                :href="route('teacher.submissions.show', { submission: submission.id })"
                                aria-label="View submission"
                                v-bind="balloonAttrs()"
                                class="hover:text-gray-600"
                            >
                                <FontAwesomeIcon icon="eye" />
                            </a>

                            <span
                                @click="confirmEmail(submission)"
                                aria-label="Email score to student"
                                v-bind="balloonAttrs()"
                                class="ml-2 hover:text-gray-600"
                            >
                                <FontAwesomeIcon icon="envelope" />
                            </span>

                            <span
                                :id="`reset-submission-${submission.id}`"
                                @click="confirmDelete(submission)"
                                aria-label="Reset submission"
                                v-bind="balloonAttrs()"
                                class="ml-2 hover:text-gray-600"
                            >
                                <FontAwesomeIcon icon="redo-alt" />
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div v-else-if="!loading">You have no submissions at this time.</div>
        </div>

        <small
            v-if="hasUngraded"
            class="float-right clear-both text-gray-600"
        >
            * = Score adjusted for ungraded questions
        </small>

        <PaginationDefault
            v-if="showPagination && paginationData && paginationData.links && paginationData.links.length > 3"
            :data="paginationData"
            @go-to-page-clicked="goToPage"
            class="float-right clear-both"
        ></PaginationDefault>
    </div>
</template>
