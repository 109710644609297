import { Ref, ref } from "vue";
import { computed } from "~/vue";
import Paginator from "@/types/Paginator";
import Resource from "@/types/Resource";
import algoliasearch from "algoliasearch";
import debounce from "lodash/debounce";
import PaginatorAlgolia from "@/types/PaginatorAlgolia";
import { SearchResponse } from "instantsearch.js";
import { router } from "@inertiajs/vue3";

export default function useSearchResource() {
    const initialLoad = ref(true);
    const searchParams = new URLSearchParams(window.location.search);

    const algoliaPrefix = import.meta.env.VITE_ALGOLIA_PREFIX.toString() as string;
    type TypeFilter = Record<string, number | string | boolean>;
    const filters: Ref<TypeFilter> = ref({
        search: searchParams.get("search") ?? "",
        page: searchParams.get("page") ?? 1,
        limit: searchParams.get("limit") ?? 10,
        sortBy: searchParams.get("sortBy") ?? "",
        includeArchived: searchParams.get("includeArchived") ?? false,
    });
    const doAlgoliaSearch = computed(
        () => filters.value.search || filters.value.sortBy || filters.value.includeArchived,
    );

    const result = ref({} as Paginator<Resource> | PaginatorAlgolia<Resource> | SearchResponse<Resource>);
    const hits = ref([] as Resource[]);
    const loading = ref(false);
    const error = ref("");

    const sortTypes = ref({
        [`${algoliaPrefix}resources_title_asc`]: "A - Z",
        [`${algoliaPrefix}resources_title_desc`]: "Z - A",
        [`${algoliaPrefix}resources_newest`]: "Newest",
        [`${algoliaPrefix}resources_oldest`]: "Oldest",
    });

    function updateRouterPath() {
        // Don't update the route query on the initial load.
        if (initialLoad.value) {
            initialLoad.value = false;
            return;
        }

        const params: TypeFilter = {};

        for (let i = 0; i < Object.entries(filters.value).length; i += 1) {
            const [key, value] = Object.entries(filters.value)[i];
            if (value) {
                params[key] = value;
            }
        }

        router.visit(window.location.pathname + window.location.hash, {
            data: params,
            preserveState: true,
            preserveScroll: true,
            only: ["includeArchived"], // This supposed to be the only prop that is changing, but it's not working... will need to investigate further.
        });
    }
    async function performBackendSearch() {
        const params = [];

        for (let i = 0; i < Object.entries(filters.value).length; i += 1) {
            const [key, value] = Object.entries(filters.value)[i];
            if (value) {
                params.push(`${key}=${value}`);
            }
        }

        const response = await fetch(`/api/fetch-resources?${params.join("&")}`);
        if (response.status === 200) {
            result.value = (await response.json()) as Paginator<Resource>;
        }
    }

    async function performAlgoliaSearch() {
        const indexName = (filters.value.sortBy ? filters.value.sortBy : `${algoliaPrefix}resources`).toString();
        const client = algoliasearch(
            `${import.meta.env.VITE_ALGOLIA_APP_ID}`,
            `${import.meta.env.VITE_ALGOLIA_SEARCH_KEY}`,
        );
        const index = client.initIndex(indexName);

        const algoliaFilters = [filters.value.includeArchived ? "(archived:true OR archived:false)" : "archived:false"];
        await index
            .search(<string>filters.value.search, {
                hitsPerPage: parseInt(<string>filters.value.limit, 10),
                page: filters.value.page ? parseInt(<string>filters.value.page, 10) - 1 : 0,
                filters: algoliaFilters.join(" AND "),
            })
            .then((response) => {
                if (response.hits.length > 0) {
                    result.value = response as SearchResponse<Resource>;
                }
            });
    }

    const performSearch = debounce(async () => {
        try {
            loading.value = true;
            if (doAlgoliaSearch.value) {
                await performAlgoliaSearch();
            } else {
                await performBackendSearch();
            }

            if (result.value) {
                if (doAlgoliaSearch.value) {
                    hits.value = (result.value as PaginatorAlgolia<Resource>).hits;
                } else {
                    hits.value = (result.value as Paginator<Resource>).data;
                }
            }
        } catch (e: any) {
            error.value = e.message;
        } finally {
            loading.value = false;
            updateRouterPath();
        }
    }, 500);

    return {
        filters,
        sortTypes,
        doAlgoliaSearch,
        loading,
        hits,
        result,

        error,
        performSearch,
    };
}
