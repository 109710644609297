<script lang="ts" setup>
import { DEFAULT_MEDIA_SIZE_SLUG, DEFAULT_MEDIA_WIDTH } from "@/components/TestTaking/Blocks/Core/constants";
import ImageMedia from "@/components/TestTaking/Blocks/Core/Media/ImageMedia.vue";
import VideoMedia from "@/components/TestTaking/Blocks/Core/Media/VideoMedia.vue";
import type FocalPoint from "@/types/Blocks/FocalPoint";
import { computed, type PropType } from "vue";

const props = defineProps({
    isStackedOnMobile: {
        type: Boolean,
        default: false,
    },

    mediaAlt: {
        type: String,
        default: "",
    },

    mediaId: {
        type: [String, Number],
        default: "",
    },

    mediaPosition: {
        type: String,
        default: "",
    },

    mediaSizeSlug: {
        type: String,
        default: "",
    },

    mediaType: {
        type: String,
        default: "",
    },

    mediaUrl: {
        type: String,
        default: "",
    },

    mediaWidth: {
        type: Number,
        default: 100,
    },

    verticalAlignment: {
        type: String,
        default: "",
    },

    imageFill: {
        type: Boolean,
        default: false,
    },

    focalPoint: {
        type: Object as PropType<FocalPoint>,
        default: null,
    },

    linkClass: {
        type: String,
        default: "",
    },

    href: {
        type: String,
        default: "",
    },

    linkTarget: {
        type: String,
        default: "",
    },

    rel: {
        type: String,
        default: "",
    },
});

const classNames = computed(() => ({
    "has-media-on-the-right": props.mediaPosition === "right",
    "is-stacked-on-mobile": props.isStackedOnMobile,
    [`is-vertically-aligned-${props.verticalAlignment}`]: props.verticalAlignment,
    "is-image-fill": props.imageFill,
}));

const mediaSizeSlug = computed(() => props.mediaSizeSlug || DEFAULT_MEDIA_SIZE_SLUG);

const mediaBlock = computed(() => {
    if (props.mediaType === "video") {
        return VideoMedia;
    }

    return ImageMedia;
});

const backgroundStyles = computed(() => {
    if (!props.imageFill || !props.mediaUrl) {
        return {};
    }

    return {
        backgroundImage: `url(${props.mediaUrl})`,
        backgroundPosition: props.focalPoint ? `${props.focalPoint.x * 100}% ${props.focalPoint.y * 100}%` : `50% 50%`,
    };
});

const wrapperStyles = computed(() => {
    if (props.mediaWidth === DEFAULT_MEDIA_WIDTH) {
        return {};
    }

    return {
        gridTemplateColumns:
            props.mediaPosition === "right" ? `auto ${props.mediaWidth}%` : `${props.mediaWidth}% auto`,
    };
});
</script>

<template>
    <div
        :class="classNames"
        :style="wrapperStyles"
    >
        <figure
            class="wp-block-media-text__media"
            :style="backgroundStyles"
        >
            <Component
                :is="mediaBlock"
                :media-alt="mediaAlt"
                :media-id="mediaId"
                :media-size-slug="mediaSizeSlug"
                :media-url="mediaUrl"
                :link-class="linkClass"
                :href="href"
                :link-target="linkTarget"
                :rel="rel"
            />
        </figure>

        <div class="wp-block-media-text__content">
            <slot />
        </div>
    </div>
</template>
