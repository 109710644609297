<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
    clientId: {
        type: String,
        required: true,
    },
});

const store = useStore();

const graded = computed(() => store.getters.graded);
const isQuestionCorrect = computed(() => store.getters.isQuestionCorrect(props.clientId));
const isQuestionIncorrect = computed(() => store.getters.isQuestionIncorrect(props.clientId));
const numberCorrect = computed(() => store.getters.numberCorrect(props.clientId));
const numberIncorrect = computed(() => store.getters.numberIncorrect(props.clientId));
const teacherInputProvided = computed(() => store.getters.teacherInputProvided(props.clientId));
const teacherInputRequired = computed(() => store.getters.teacherInputRequired(props.clientId));
</script>

<template>
    <div class="relative flex-col">
        <div
            class="absolute"
            style="top: -36px; width: 400px"
            v-if="graded"
        >
            <template v-if="teacherInputProvided">
                <span class="fa-stack fa-lg fa-xs -mt-4 mr-2">
                    <i class="fas fa-circle fa-stack-2x text-primary-darker" />

                    <i
                        class="fas fa-thumbs-up fa-inverse fa-stack-1x"
                        data-fa-transform="shrink-7 up-1"
                    />
                </span>

                <span class="font-light italic">Teacher input provided</span>
            </template>

            <template v-else-if="teacherInputRequired">
                <i class="fas fa-exclamation-circle fa-lg mr-2 text-warning" />

                <span>Teacher input required</span>
            </template>

            <span
                class="correct"
                v-else-if="isQuestionCorrect"
            >
                Correct
            </span>

            <span
                class="incorrect"
                v-else-if="isQuestionIncorrect"
            >
                Incorrect
            </span>

            <template v-else>
                <span class="correct"> {{ numberCorrect }} Correct</span>

                and

                <span class="incorrect"> {{ numberIncorrect }} Incorrect </span>
            </template>
        </div>

        <div class="mr-2 whitespace-nowrap font-bold">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss">
span {
    &.fa-2xs {
        font-size: calc(21.3333px / 4);
    }

    &.correct {
        @apply text-correct;
    }

    &.incorrect {
        @apply text-incorrect;
    }
}
</style>
