<script>
import { mapActions, mapGetters } from "vuex";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import ModalWindow from "@/components/Global/ModalWindow.vue";
import BtnDanger from "@/components/Global/BtnDanger.vue";

export default {
    components: { BtnDanger, ModalWindow },
    data() {
        return {
            adjustedScore: "",
            initialScore: "",
            showModal: false,
        };
    },

    props: {
        score: {
            type: Number,
        },

        maxScore: {
            type: Number,
            default: 1,
        },

        answerId: {
            type: Number,
        },
        questionId: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        ...mapGetters({
            _submission: "submission",
        }),

        scoreChanged() {
            return this.initialScore !== this.score && this.initialScore !== this.adjustedScore;
        },
    },

    methods: {
        ...mapActions({
            _changeScore: "changeScore",
        }),

        changeScore() {
            this._changeScore({
                questionId: this.questionId,
                answerId: this.answerId,
                newScore: this.adjustedScore,
            });
        },
    },

    watch: {
        score: {
            handler(newValue, oldValue) {
                if (newValue !== undefined && oldValue === undefined) {
                    this.adjustedScore = this.score ?? "";
                    this.initialScore = this.score ?? "";
                }
            },
            immediate: true,
        },
    },
};
</script>

<template>
    <div class="after-question">
        <div class="score-wrapper group">
            <select
                v-model="adjustedScore"
                :class="scoreChanged ? 'bg-blue-200' : 'bg-gray-200'"
                @change="changeScore"
                v-cloak
                class="block w-full appearance-none rounded border border-gray-400 px-4 py-2 pr-8 leading-tight shadow focus:shadow-outline focus:outline-none group-hover:border-gray-500"
            >
                <option
                    disabled
                    selected
                    value
                >
                    Select grade
                </option>

                <option :value="0">0 points</option>

                <option
                    v-for="n in maxScore"
                    :value="n"
                    :key="n"
                >
                    {{ n }} point{{ n === 1 ? "" : "s" }}
                </option>
            </select>

            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center border-l border-gray-400 px-2 text-gray-700 group-hover:border-gray-500"
            >
                <svg
                    class="h-4 w-4 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
            </div>
        </div>

        <slot></slot>

        <ModalWindow
            v-model:show="showModal"
            header-classes="modal-header px-5 pt-5 text-center"
            footer-classes="modal-footer border0-t px-5 pb-5 text-center"
        >
            <template #header>
                <h5 class="text-2xl uppercase">Invalid Action</h5>
            </template>

            <p>No answer submitted for this question. Score cannot be change.</p>

            <template #footer>
                <div class="mx-auto md:w-1/2">
                    <BtnDanger @click="showModal = false"> Ok </BtnDanger>
                </div>
            </template>
        </ModalWindow>
    </div>
</template>

<style lang="scss">
.score-wrapper {
    @apply relative inline-block;

    width: 16rem !important;
}
</style>
