<script setup lang="ts">
const props = defineProps({
    href: {
        type: String,
        default: "",
    },
    target: {
        type: String,
        default: "_self",
    },
    class: {
        type: String,
        default: "",
    },
    ariaLabel: {
        type: String,
        default: "",
    },
});
</script>

<template>
    <a
        :href="href"
        :target="target"
        :aria-label="!ariaLabel && target.toLowerCase() == '_blank' ? 'Open in a new tab' : ariaLabel"
        class="text-primary-lighter hover:underline"
        :class="props.class"
    >
        <slot />
    </a>
</template>
