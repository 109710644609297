<script>
import wNumb from "wnumb";
import debounce from "lodash/debounce";
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";
import $ from "jquery";
import SliderHelper from "@/components/TestTaking/SliderHelper";

export default {
    components: { QuestionBlock },
    mixins: [Question],

    data() {
        return {
            answer: [],
            changingSegment: false,
            correctAnswerSlider: null,
            leftSide: 700,
            clicked: false,
            pointClicked: false,
            slider: null,
        };
    },

    props: {
        type: {},
        min: {},
        max: {},
        minorStep: {},
        majorStep: {},
        step: {},
        typeDefault: {
            default: "line",
        },
    },

    computed: {
        activeSegment() {
            return this.answer?.[this.changingSegment] ?? {};
        },

        defaultAnswer() {
            return [];
        },

        isCurrentType() {
            return this.activeSegment?.type;
        },

        disableEndingRay() {
            if (this.isCurrentType === "ending_ray") {
                return true;
            }

            return (
                (this.answer ?? []).findIndex((answer, index) => {
                    if (answer.type === "ending_ray") {
                        return true;
                    }

                    return this.changingSegment !== index && (answer.start || answer.end) > this.activeSegment.start;
                }) > -1
            );
        },

        disableStartingRay() {
            if (this.isCurrentType === "starting_ray") {
                return true;
            }

            return (
                (this.answer ?? []).findIndex((answer, index) => {
                    if (answer.type === "starting_ray") {
                        return true;
                    }

                    return this.changingSegment !== index && (answer.start || answer.end) < this.activeSegment.end;
                }) > -1
            );
        },

        typeButtonColors() {
            return (disabled) =>
                disabled
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900";
        },

        settings() {
            return (initial = false, useCorrectAnswers = false) => {
                const answers = [...(useCorrectAnswers ? this._correctAnswer(this.clientId) : this.answer)];

                const min = this.parseNumber(this.min);
                const max = this.parseNumber(this.max);
                const majorStep = this.parseNumber(this.majorStep);
                const minorStep = this.parseNumber(this.minorStep);
                const step = this.parseNumber(this.step);

                if (initial && answers.length === 0) {
                    answers.push({
                        id: 0,
                        type: "point",
                        start: min,
                        startType: "open",
                        end: null,
                        endType: "open",
                    });
                }

                const values = answers
                    .concat(
                        initial
                            ? [
                                  {
                                      id: 0,
                                      type: "point",
                                      start: min,
                                      startType: "open",
                                      end: null,
                                      endType: "open",
                                  },
                              ]
                            : [],
                    )
                    .sort((a, b) => {
                        const aStart = a.type === "starting_ray" ? this.parseNumber(a.end) : this.parseNumber(a.start);
                        const bStart = b.type === "starting_ray" ? this.parseNumber(b.end) : this.parseNumber(b.start);

                        if (aStart === bStart) {
                            return 0;
                        }

                        return aStart < bStart ? -1 : 1;
                    })
                    .reduce(
                        (carry, answer) => {
                            if (answer.type === "starting_ray") {
                                carry.starts.push(this.parseNumber(answer.end) || min);
                                carry.handleAttributes.push({
                                    "data-answer": `${answer.id}-end`,
                                });
                                carry.connects.push(true, false);
                            } else if (answer.type === "ending_ray") {
                                carry.starts.push(this.parseNumber(answer.start) || max);

                                if (carry.connects.length === 0) {
                                    carry.connects.push(false);
                                }
                                carry.handleAttributes.push({
                                    "data-answer": `${answer.id}-start`,
                                });
                                carry.connects.push(true);
                            } else if (answer.type === "segment") {
                                carry.starts.push(this.parseNumber(answer.start), this.parseNumber(answer.end));

                                if (carry.connects.length === 0) {
                                    carry.connects.push(false);
                                }
                                carry.handleAttributes.push(
                                    {
                                        "data-answer": `${answer.id}-start`,
                                    },
                                    {
                                        "data-answer": `${answer.id}-end`,
                                    },
                                );
                                carry.connects.push(true, false);
                            } else if (answer.type === "point") {
                                carry.starts.push(this.parseNumber(answer.start));

                                if (carry.connects.length === 0) {
                                    carry.connects.push(false);
                                }

                                carry.handleAttributes.push({
                                    "data-answer": `${answer.id}-start`,
                                });
                                carry.connects.push(false);
                            }

                            return carry;
                        },
                        { connects: [], starts: [], handleAttributes: [] },
                    );

                const { starts, connects, handleAttributes } = values;

                return {
                    start: starts,
                    connect: connects,
                    handleAttributes,
                    documentElement: this.getSliderElement(useCorrectAnswers),
                    step,
                    pips: {
                        mode: "steps",
                        filter: (value, type) => {
                            if (majorStep < 1) {
                                if (
                                    Number((value - min) * 10 ** (this.stepPower + 1)).toFixed(0) %
                                        Number(majorStep * 10 ** (this.stepPower + 1)).toFixed(0) ===
                                    0
                                ) {
                                    return 1;
                                }
                            } else if ((value - min) % majorStep === 0) {
                                return 1;
                            }

                            if (minorStep < 1) {
                                if (
                                    Number((value - min) * 10 ** (this.stepPower + 1)).toFixed(0) %
                                        Number(minorStep * 10 ** (this.stepPower + 1)).toFixed(0) ===
                                    0
                                ) {
                                    return 0;
                                }
                            } else if ((value - min) % minorStep === 0) {
                                return 0;
                            }

                            return -1;
                        },
                        format: wNumb({
                            decimals: this.stepPower,
                        }),
                    },
                    format: wNumb({
                        decimals: this.stepPower,
                    }),
                    range: {
                        min: [parseFloat(min)],
                        max: [parseFloat(max)],
                    },
                };
            };
        },

        stepPower() {
            let power = 0;

            if (this.step < 1) {
                while ((this.step * 10 ** power) % 1 !== 0) {
                    power += 1;
                }
            }

            return power;
        },
    },

    methods: {
        addAnswerPart(location) {
            const uniqueId = Math.max(...this.answer.map((obj) => parseInt(obj.id)), 0) + 1;
            const newAnswerPart = {
                id: uniqueId,
                type: this.typeDefault === "line" ? "segment" : "point",
                start: location,
                startType: "open",
                end: null,
                endType: "open",
            };

            if (this.typeDefault === "line") {
                const endValue = this.parseNumber(location) + this.parseNumber(this.step);

                newAnswerPart.end = Number(endValue).toFixed(this.stepPower);
            }

            this.answer.push(newAnswerPart);

            this.updateAnswer();
        },

        changeType(type) {
            const currentType = this.answer[this.changingSegment].type;
            let answer = {
                ...this.answer[this.changingSegment],
                type,
            };

            // when changing from a point to a starting ray, the expected behavior is that the starting ray will start at the point..
            if (type === "starting_ray" && currentType === "point") {
                answer.end = answer.start;
            }

            if (answer.end === null) {
                answer.end = this.parseNumber(answer.start) + this.parseNumber(this.step);
            }

            if (answer.start === null) {
                answer.start = this.parseNumber(answer.end) - this.parseNumber(this.step);
            }

            if (type === "starting_ray") {
                answer.start = null;
            } else if (type === "ending_ray" || type === "point") {
                answer.end = null;
            }

            Object.keys(answer).forEach((key) => {
                this.answer[this.changingSegment][key] = answer[key];
            });

            this.destroyAndRebuildSlider();

            this.updateAnswer();
        },

        cloneRebuildSlider() {
            const element = this.getSliderElement().cloneNode();
            element.id = `correct-${element.id}`;
            element.classList.add("correct-answers");
            this.getSliderElement().insertAdjacentElement("afterend", element);
            this.destroyAndRebuildSlider(true, true);
            this.destroyAndRebuildSlider(true);
        },

        compareConnects() {
            const numberLine = this.getSliderElement();
            const correctNumberLine = this.getSliderElement(true);

            const studentConnects = numberLine.getElementsByClassName("noUi-connect");
            const correctConnects = correctNumberLine.getElementsByClassName("noUi-connect");

            _.forEach(studentConnects, (studentConnect) => {
                const found = _.find(correctConnects, (correctConnect) => studentConnect.isEqualNode(correctConnect));

                if (found !== undefined) {
                    studentConnect.classList.add("correct");
                    studentConnect.classList.remove("incorrect");
                } else {
                    studentConnect.classList.add("incorrect");
                    studentConnect.classList.remove("correct");
                }
            });
        },

        compareValues(value1, value2, key) {
            if (typeof value1 === "object" && value1 !== null) {
                let isEqual = true;

                _.forIn(value1, (value, key) => {
                    if (["end", "start"].indexOf(key) > -1) {
                        isEqual &= this.parseNumber(value1[key]) === this.parseNumber(value2[key]);
                    } else {
                        isEqual &= value1[key] === value2[key];
                    }
                });

                return isEqual;
            }

            if (["end", "start"].indexOf(key) > -1) {
                return this.parseNumber(value1) === this.parseNumber(value2);
            }

            return value1 === value2;
        },

        createSlider(initial = false, useCorrectAnswers = false) {
            const answers = useCorrectAnswers ? this._correctAnswer(this.clientId) : this.answer;
            const settings = this.settings(initial, useCorrectAnswers);

            this[useCorrectAnswers ? "correctAnswerSlider" : "slider"] = window.noUiSlider.create(
                this.getSliderElement(useCorrectAnswers),
                settings,
            );

            const slider = this[useCorrectAnswers ? "correctAnswerSlider" : "slider"];

            if (initial) {
                const numberLine = this.getSliderElement(useCorrectAnswers);

                if ((this.submittedAnswer || []).length === 0) {
                    numberLine.querySelectorAll(".noUi-handle").forEach((element) => {
                        element.classList.add("initial");
                    });
                }
            }

            this.$nextTick(() => {
                (answers || []).forEach((answer, index) => {
                    let correctClass = "";

                    if (this._graded && !useCorrectAnswers) {
                        correctClass = this.setClassesForCorrectness(answer);
                    }

                    if (answer.start !== null && ["segment", "ending_ray", "point"].indexOf(answer.type) > -1) {
                        const start = Math.round(answer.start);
                        const handle = slider.target.querySelector(`.noUi-handle[aria-valuetext^="${start}"]`);

                        if (handle) {
                            this.setHandleClasses(handle, answer.startType, correctClass);
                            handle.setAttribute("data-answer", `${answer.id}-start`);

                            if (answer.type === "point") {
                                handle.classList.add(`is-point`);
                            }
                        }
                    }

                    if (answer.end !== null && (answer.type === "segment" || answer.type === "starting_ray")) {
                        const end = Math.round(answer.end);
                        const handle = slider.target.querySelector(`.noUi-handle[aria-valuetext^="${answer.end}"]`);

                        if (handle) {
                            this.setHandleClasses(handle, answer.endType, correctClass);
                            handle.setAttribute("data-answer", `${answer.id}-end`);
                        }
                    }
                });
            });

            if (!this._graded) {
                this.$nextTick(() => {
                    slider.on("change", (values, handle, unencoded, tap, positions, noUiSlider) => {
                        const element = slider.target.querySelector(`[data-handle="${handle}"]`);

                        if (!initial) {
                            this.updateAnswerFromElement(element, unencoded[handle]);
                        }

                        initial = false; // Initial has passed. Set it to false.
                    });
                });
            } else if (!useCorrectAnswers) {
                this.$nextTick(() => {
                    this.compareConnects();
                });
            }
        },

        deleteSegment() {
            const index = this.answer.findIndex((answer) => parseInt(answer.id) === parseInt(this.changingSegment));
            this.answer.splice(index, 1);

            if (this.answer.length > 0) {
                this.destroyAndRebuildSlider();

                this.updateAnswer();
            } else {
                this.resetAnswer();
            }
        },

        destroyAndRebuildSlider(initial = false, useCorrectAnswers = false) {
            if (this[useCorrectAnswers ? "correctAnswerSlider" : "slider"] !== null) {
                this[useCorrectAnswers ? "correctAnswerSlider" : "slider"].destroy();
            }

            const answers = useCorrectAnswers ? this._correctAnswer(this.clientId) : this.answer;

            const element = this.getSliderElement(useCorrectAnswers);

            if (this._graded) {
                element.setAttribute("disabled", this._graded ? "disabled" : false);
            }

            const beforeClass = ["before"];
            const afterClass = ["after"];

            const { correctStartingRay, correctEndingRay } = _.reduce(
                this._correctAnswer(this.clientId),
                (carry, correctAnswer) => {
                    if (correctAnswer.type === "starting_ray") {
                        carry.correctStartingRay = correctAnswer;
                    } else if (correctAnswer.type === "ending_ray") {
                        carry.correctEndingRay = correctAnswer;
                    }

                    return carry;
                },
                { correctStartingRay: null, correctEndingRay: null },
            );

            const { hasStartingRay, hasEndingRay, isStartingRayCorrect, isEndingRayCorrect } = _.reduce(
                answers,
                (carry, answer) => {
                    if (answer.type === "starting_ray") {
                        carry.hasStartingRay = true;

                        if (this._graded && !useCorrectAnswers && correctStartingRay) {
                            carry.isStartingRayCorrect = _.isEqualWith(answer, correctStartingRay, this.compareValues);
                        }
                    } else if (answer.type === "ending_ray") {
                        carry.hasEndingRay = true;

                        if (this._graded && !useCorrectAnswers && correctStartingRay) {
                            carry.isEndingRayCorrect = _.isEqualWith(answer, correctEndingRay, this.compareValues);
                        }
                    }

                    return carry;
                },
                { hasStartingRay: null, hasEndingRay: null, isEndingRayCorrect: false, isStartingRayCorrect: false },
            );

            if (hasStartingRay) {
                beforeClass.push("has-ray");
            }

            if (hasEndingRay) {
                afterClass.push("has-ray");
            }

            if (this._graded && !useCorrectAnswers) {
                beforeClass.push(isStartingRayCorrect ? "correct" : "incorrect");
                afterClass.push(isEndingRayCorrect ? "correct" : "incorrect");
            }

            if (!useCorrectAnswers && this._graded) {
                element.classList.add("graded");
            }

            let childElement = document.createElement("div");
            childElement.classList.add(...beforeClass);
            element.appendChild(childElement);

            childElement = document.createElement("div");
            childElement.classList.add(...afterClass);
            element.appendChild(childElement);

            this.createSlider(initial, useCorrectAnswers);
        },

        getLocation(location) {
            const settings = this.settings();

            let value = SliderHelper(this.getSliderElement(), location, settings);

            value = (value / 100) * (settings.range.max[0] - settings.range.min[0]);

            if (this.typeDefault === "line") {
                value = Math.floor(value);
            } else {
                value = Math.round(value);
            }

            value = value + settings.range.min[0];

            if (this.step < 1) {
                value -= Number(value).toFixed(0) % Number(this.step * 10 ** this.stepPower).toFixed(0);

                return Number(value / 10 ** this.stepPower).toFixed(this.stepPower);
            }

            value -= value % this.step;

            return value;
        },

        getSliderElement(useCorrectAnswers = false) {
            return document.getElementById(`${useCorrectAnswers ? "correct-" : ""}number-line-${this.clientId}`);
        },

        locationIsHighlighted(location) {
            return (this.answer || []).reduce((carry, answer, index) => {
                let isBetween = false;

                if (answer.type === "starting_ray") {
                    isBetween = isBetween || location <= answer.end;
                } else if (answer.type === "ending_ray") {
                    isBetween = isBetween || location >= answer.start;
                } else if (answer.type === "point") {
                    isBetween = isBetween || location == answer.start;
                } else {
                    isBetween = isBetween || (location >= answer.start && location <= answer.end);
                }

                return isBetween ? answer.id : carry;
            }, null);
        },

        parseNumber(value) {
            if (typeof value === "string") {
                return parseFloat(value);
            }

            return value;
        },

        resetSuccess() {
            this.destroyAndRebuildSlider(true);
        },

        selectedValue() {
            //
        },

        setClassesForCorrectness(answer) {
            const correctAnswer = _.find(this._correctAnswer(this.clientId), (correctAnswer) =>
                _.isEqualWith(answer, correctAnswer, this.compareValues),
            );

            return correctAnswer !== undefined ? "correct" : "incorrect";
        },

        setHandleClasses(target, value, extraClasses) {
            if (target && value === "open") {
                target.classList.remove("closed");
            } else if (target) {
                target.classList.add("closed");
            }

            if (target && extraClasses) {
                target.classList.add(extraClasses);
            }
        },

        toggleClosedState(target) {
            if (!target) {
                return;
            }

            let value;
            if (target.classList.contains("closed")) {
                value = "open";
            } else {
                value = "closed";
            }

            this.setHandleClasses(target, value);

            this.updateAnswerFromElement(target, value, "Type");

            this.updateAnswer();
        },

        updateAnswerFromElement(element, value, propertySuffix = null) {
            if (element.dataset.answer) {
                let [id, property] = element.dataset.answer.split("-");

                if (propertySuffix !== null) {
                    property += propertySuffix;
                }

                const index = this.answer.findIndex((answer) => parseInt(answer.id) === parseInt(id));

                if (this.answer?.[index]) {
                    // const answer = { ...(this.answer?.[index] ?? {}) };

                    this.answer[index][property] = value;

                    // this.answer[index].splice(index, 1, answer);
                }

                this.updateAnswer();
            }
        },

        updateAnswer: debounce(function () {
            if (this._graded) {
                return;
            }

            this.saveAnswer();
        }, 200),
    },

    mounted() {
        if (!this._graded) {
            this.destroyAndRebuildSlider(true);

            const numberLine = document.getElementById(`number-line-${this.clientId}`);

            numberLine.addEventListener(
                "mousedown",
                (event) => {
                    if (event.target.classList.contains("noUi-touch-area")) {
                        // Do nothing
                    } else if (
                        event.target.classList.contains("noUi-base") ||
                        event.target.classList.contains("noUi-connect") ||
                        event.target.classList.contains("noUi-connects")
                    ) {
                        this.clicked = true;
                        event.stopPropagation();
                    }
                },
                { capture: true },
            );

            numberLine.addEventListener(
                "mousemove",
                () => {
                    if (this.clicked) {
                        this.clicked = false;
                    }
                },
                { capture: true },
            );

            numberLine.addEventListener(
                "click",
                (event) => {
                    if (
                        event.target.classList.contains("is-point") ||
                        event.target.parentNode.classList.contains("is-point")
                    ) {
                        let element;

                        if (event.target.classList.contains("is-point")) {
                            element = event.target;
                        } else if (event.target.parentNode.classList.contains("is-point")) {
                            element = event.target.parentNode;
                        }

                        if (element) {
                            const [id, property] = element.dataset.answer.split("-");

                            if (this.changingSegment) {
                                this.changingSegment = false;
                                this.leftSide = 0;
                            } else {
                                this.changingSegment = this.answer.findIndex((answer) => {
                                    return parseInt(answer.id) === parseInt(id);
                                });
                                this.leftSide = event.x;
                            }

                            event.stopPropagation();
                        }
                    } else if (this.clicked) {
                        this.clicked = false;

                        if (!Array.isArray(this.answer)) {
                            this.answer = [];
                        }

                        const location = this.getLocation(event.x);
                        const segment = this.locationIsHighlighted(location);
                        const segmentIndex = this.answer.findIndex(
                            (answer) => parseInt(answer.id) === parseInt(segment),
                        );

                        if (segment !== null) {
                            event.preventDefault();
                            event.stopPropagation();
                            if (segmentIndex !== this.changingSegment) {
                                // x position within the question block
                                const x = event.pageX - $(`#question-${this.clientId}`).offset().left;

                                this.changingSegment = segmentIndex;
                                this.leftSide = x;
                            } else {
                                this.changingSegment = false;
                                this.leftSide = 0;
                            }
                        } else {
                            this.addAnswerPart(location);

                            this.destroyAndRebuildSlider();
                        }
                    }
                },
                { capture: true },
            );

            numberLine.addEventListener(
                "dblclick",
                (event) => {
                    let index = 1;
                    let element = event.target;

                    while (!element.classList.contains("noUi-handle") && event.composedPath()?.length > index) {
                        element = event.composedPath()[index++];
                    }

                    if (element.classList.contains("noUi-handle")) {
                        this.toggleClosedState(element);
                    }
                },
                { capture: true },
            );

            document.addEventListener("click", (event) => {
                this.changingSegment = false;
            });
        } else {
            let unwatch;
            // Add dynamic watch
            unwatch = this.$watch(
                () => this._correctAnswer(this.clientId),
                () => {
                    this.cloneRebuildSlider();
                    unwatch();
                },
            );

            // If correct answers are already loaded in Test.vue, then create slider here. Since watch will not be trigger again.
            if (this._correctAnswer(this.clientId)) {
                this.cloneRebuildSlider();
            }
        }
    },
};
</script>

<template>
    <QuestionBlock
        :question-number="questionNumber"
        :block-id="clientId"
        @reset-answer="resetAnswer"
        class="number-line relative"
    >
        <slot
            :selected-value="selectedValue"
            :label-click="labelClick"
            :answer="answer"
            :graded="_graded"
            :answer-state="answerState"
        />

        <div
            :id="`number-line-${clientId}`"
            class="mx-6 my-8 h-4 border-0 bg-transparent shadow-none"
        />

        <div
            class="absolute -mt-4 w-64 origin-top-right rounded-md shadow-lg"
            :style="{ left: `calc(${leftSide}px - 8rem)`, 'z-index': 20 }"
            v-show="changingSegment !== false"
        >
            <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
                <div class="flex flex-wrap justify-around py-1">
                    <button
                        @click="changeType('starting_ray')"
                        :disabled="disableStartingRay"
                        :class="typeButtonColors(disableStartingRay)"
                        class="m-1 h-8 w-1/5 flex-1 rounded-lg border px-4 py-2 text-sm leading-4 focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500"
                        role="menuitem"
                    >
                        <span class="arrows fa-stack fa-lg">
                            <i
                                class="fas fa-long-arrow-alt-left"
                                data-fa-transform="grow-6"
                            ></i>
                        </span>
                    </button>

                    <button
                        @click="changeType('segment')"
                        :disabled="isCurrentType === 'segment'"
                        class="m-1 h-8 w-1/5 flex-1 rounded-lg border px-4 py-2 text-sm leading-4 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500"
                        role="menuitem"
                    >
                        <span class="arrows fa-stack fa-lg">
                            <i
                                class="fas fa-arrows-alt-h"
                                data-fa-transform="grow-6"
                            ></i>
                        </span>
                    </button>

                    <button
                        @click="changeType('point')"
                        :disabled="isCurrentType === 'point'"
                        class="m-1 h-8 w-1/5 flex-1 rounded-lg border px-4 py-2 text-sm leading-4 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500"
                        role="menuitem"
                    >
                        <span class="leading-4">
                            <i class="far fa-circle"></i>
                        </span>
                    </button>

                    <button
                        @click="changeType('ending_ray')"
                        :disabled="disableEndingRay"
                        :class="typeButtonColors(disableEndingRay)"
                        class="m-1 h-8 w-1/5 flex-1 rounded-lg border px-4 py-2 text-sm leading-4 focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500"
                        role="menuitem"
                    >
                        <span class="arrows fa-stack fa-lg">
                            <i
                                class="fas fa-long-arrow-alt-right"
                                data-fa-transform="grow-6"
                            ></i>
                        </span>
                    </button>

                    <button
                        @click="deleteSegment()"
                        class="m-1 h-8 w-1/4 flex-1 rounded-lg border bg-red-500 px-4 py-2 text-sm leading-4 text-white hover:bg-red-800 focus:bg-red-800 focus:outline-none"
                        role="menuitem"
                    >
                        Delete Segment
                    </button>
                </div>
            </div>
        </div>
    </QuestionBlock>
</template>

<style>
.arrows {
    width: 1rem;
    line-height: 1rem;
}
</style>
